import * as React from 'react';
import { interpret, State } from 'xstate';
import machine, { AutomataService, AutomataEvent, AutomataContext } from '../../../../../Machines/users/search';

import Search from './Search';
import Users from './Users';

type Props_ = {};
type State_ = {
  service: AutomataService;
  current: State<AutomataContext, AutomataEvent>;
};

export default class extends React.Component<Props_, State_> {
  state = {
    service: interpret(machine, { devTools: true }).onTransition((current) => this.setState({ current })),
    current: machine.initialState,
  };

  componentDidMount(): void {
    this.state.service.start();
  }

  componentWillUnmount(): void {
    this.state.service.stop();
  }

  render = () => (
    <div className="border-l border-r border-grey flex-none w-64 pb-6 hidden md:block application-offset">
      <div className="mb-2 mt-3 px-3">
        <Search service={this.state.service} current={this.state.current} />
      </div>
      <div className="text-gray-600 my-3 px-4 flex justify-center">
        <h1 className="font-semibold text-2xl truncate">Users</h1>
      </div>

      <div className="mb-8">
        <hr />
        <div className="m-2">
          <Users current={this.state.current} />
        </div>
      </div>
    </div>
  );
}
