import * as React from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

import AddUser from './AddUser';
import { CurrentState } from '../../../../../../../Machines/users/search';
import { State as StoreState } from '../../../../../../../Data/@records';
import { UserRecord } from '../../../../../../../Data/users/@records/User';
import { BranchRecord } from '../../../../../../../Data/organisations/@records/Branch';

type Props = {
  current: CurrentState;
  branch: BranchRecord;
};

export default ({ current, branch }: Props) => {
  const users = useSelector<StoreState, List<UserRecord>>((state) =>
    state.users.users
      .filter(
        (u) =>
          u.details.first_name.toLowerCase().includes(current.context.term.toLowerCase()) ||
          u.details.last_name?.toLowerCase().includes(current.context.term.toLowerCase()) ||
          u.email.toLowerCase().includes(current.context.term.toLowerCase()),
      )
      .toList(),
  );

  return (
    <div className="mb-2">
      {users.map((user) => (
        <AddUser branch={branch} user={user} />
      ))}
    </div>
  );
};
