import { Lens } from 'monocle-ts';
import { Machine, MachineConfig, Interpreter, assign, State } from 'xstate';

import Async from '../../Async';
import { AutomataStates } from '../organisations/update';

// States

export enum States {
  CHANGE_PASSWORD_VIEW = 'CHANGE_PASSWORD_VIEW',
  CHANGE_PASSWORD_VALIDATE = 'CHANGE_PASSWORD_VALIDATE',
  CHANGE_PASSWORD_SUBMIT = 'CHANGE_PASSWORD_SUBMIT',
  CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
}

export type AutomataStateSchema = {
  states: {
    [States.CHANGE_PASSWORD_VIEW]: {};
    [States.CHANGE_PASSWORD_VALIDATE]: {};
    [States.CHANGE_PASSWORD_SUBMIT]: {};
    [States.CHANGE_PASSWORD_ERROR]: {};
    [States.CHANGE_PASSWORD_SUCCESS]: {};
  };
};

// Events

export enum Events {
  INPUT_FOCUS = 'INPUT_FOCUS',
  ENTER_DATA_1 = 'ENTER_DATA_1',
  ENTER_DATA_2 = 'ENTER_DATA_2',
  SUBMIT_DATA = 'SUBMIT_DATA',
}

export type SubmitData = {
  type: Events.SUBMIT_DATA;
};

export type InputFocus = {
  type: Events.INPUT_FOCUS;
};

export type EnterData1 = {
  type: Events.ENTER_DATA_1;
  value: string;
};

export type EnterData2 = {
  type: Events.ENTER_DATA_2;
  value: string;
};

export type AutomataEvent = SubmitData | InputFocus | EnterData1 | EnterData2;

// Context

export type AutomataContext = {
  password1: string;
  password2: string;
};

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

// Services

export const submitPasswordChange = (c: AutomataContext, e: SubmitData) => Async.authentication.change_password(c);

// Actions

export const enterPassword1 = assign((c: AutomataContext, e: EnterData1) => Lens.fromProp<AutomataContext>()('password1').set(e.value)(c));

export const enterPassword2 = assign((c: AutomataContext, e: EnterData2) => Lens.fromProp<AutomataContext>()('password2').set(e.value)(c));

// Guards

export const validatePasswordLength = (c: AutomataContext, e: EnterData1) => c.password1.length >= 8;

export const validatePasswordEqual = (c: AutomataContext, e: EnterData1) => c.password1 === c.password2;

export const validatePassword = (c: AutomataContext, e: EnterData1) => validatePasswordLength(c, e) && validatePasswordEqual(c, e);

// Config

export const config: MachineConfig<AutomataContext, AutomataStateSchema, AutomataEvent> = {
  id: 'Account:ChangePassword:Machine',
  initial: States.CHANGE_PASSWORD_VIEW,
  context: {
    password1: '',
    password2: '',
  },
  states: {
    [States.CHANGE_PASSWORD_VIEW]: {
      on: {
        [Events.ENTER_DATA_1]: {
          actions: ['enterPassword1'],
        },
        [Events.ENTER_DATA_2]: {
          actions: ['enterPassword2'],
        },
        [Events.SUBMIT_DATA]: {
          target: States.CHANGE_PASSWORD_SUBMIT,
        },
      },
    },
    [States.CHANGE_PASSWORD_VALIDATE]: {
      on: {
        '': [
          {
            target: States.CHANGE_PASSWORD_SUBMIT,
            cond: 'validatePassword',
          },
          {
            target: States.CHANGE_PASSWORD_ERROR,
          },
        ],
      },
    },
    [States.CHANGE_PASSWORD_SUBMIT]: {
      invoke: {
        src: 'submitPasswordChange',
        onDone: {
          target: States.CHANGE_PASSWORD_SUCCESS,
        },
        onError: {
          target: States.CHANGE_PASSWORD_ERROR,
        },
      },
    },
    [States.CHANGE_PASSWORD_ERROR]: {
      on: {
        [Events.INPUT_FOCUS]: {
          target: States.CHANGE_PASSWORD_VIEW,
        },
      },
    },
    [States.CHANGE_PASSWORD_SUCCESS]: {
      on: {
        [Events.INPUT_FOCUS]: {
          target: States.CHANGE_PASSWORD_VIEW,
        },
      },
    },
  },
};

const options: any = {
  services: {
    submitPasswordChange,
  },
  actions: {
    enterPassword1,
    enterPassword2,
  },
  guards: {
    validatePassword,
  },
};

export default Machine<AutomataContext, AutomataStateSchema, AutomataEvent>(config, options);
