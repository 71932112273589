import * as React from 'react';

type Props = {
  className?: string;
};

export const CaretDownIcon = ({ className }: Props) => (
  <svg width="6" height="3" viewBox="0 0 6 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.83062 0.675037L3.62163 2.88403C3.467 3.03866 3.21668 3.03866 3.06246 2.88403L0.853459 0.675037C0.604324 0.425902 0.780695 0 1.13304 0L5.55104 0C5.90339 0 6.07976 0.425902 5.83062 0.675037Z"
      fill="#1D1D1F"
    />
  </svg>
);

export default CaretDownIcon;
