import * as React from 'react';
import History from '../../../../../../../history';
import Data from '../../../../../../../Data';

import { Send, Events } from '../../../../../../../Machines/organisations/update';
import { BranchResponse, UserResponse } from '../../../../../../../Async/Organisations/response_models';

type Props = {
  user: UserResponse;
  send: Send;
  branch: BranchResponse;
};

export const RemoveUserFromBranch = (branch: BranchResponse, user: UserResponse, send: Send) => (e: React.MouseEvent<HTMLButtonElement>) =>
  send({
    type: Events.REMOVE_USER_FROM_BRANCH,
    branchId: branch.identifier,
    userId: user.identifier,
  });

export default ({ send, user, branch }: Props) => (
  <tr>
    <td />
    <td>
      {user.details.first_name} {user.details.last_name}
    </td>
    <td>
      <button
        onClick={() => History.push(Data.paths.admin.users.DETAIL(user.identifier))}
        className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-gray-400 hover:bg-gray-200 transition-250"
      >
        Profile
      </button>

      <button
        onClick={RemoveUserFromBranch(branch, user, send)}
        className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-red-200 hover:bg-red-500 transition-250 ml-5"
      >
        Remove
      </button>
    </td>
  </tr>
);
