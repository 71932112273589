import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { TransactSessionListResponse } from './response_models';

export const sessions = (identifier: string): Promise<AxiosResponse<TransactSessionListResponse>> =>
  axios.get(Endpoints.transact.LIST_SESSIONS(identifier), Data.async.authorisationHeaders());

export default async (identifier: string) => await sessions(identifier).then(getDataFromAxios.get).then(tp.decode(TransactSessionListResponse));
