import React from 'react';

import { Props } from './@types';
import { Events } from '../../../../Machines/authentication/reset_password';

export const returnToLoginPage = (send: Props['send']) => (e: React.MouseEvent<HTMLSpanElement>) => send({ type: Events.TO_LOGIN_PAGE });

export default ({ current, send }: Props) => (
  <div>
    <div className="p-2">
      <div className="text-gray-700 mt-2 text-lg">
        Password reset request raised. If there is a user with this email address in the system you will receive instructions shortly.
      </div>
    </div>

    <div className="flex mt-10 justify-center">
      <span onClick={returnToLoginPage(send)} className="bg-liven-base text-white text-lg py-2 px-12 rounded-full shadow cursor-pointer">
        Return to Login Page
      </span>
    </div>
  </div>
);
