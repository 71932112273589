import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { TransactActivatedBranchResponse } from './response_models';

export const transact_actived_branches = (pageSize: number, currentPage: number): Promise<AxiosResponse<TransactActivatedBranchResponse>> =>
  axios.get(Endpoints.transact.TRANSACT_ACTIVATED_BRANCHES(pageSize, currentPage), Data.async.authorisationHeaders());

export default async (pageSize: number, currentPage: number) =>
  await transact_actived_branches(pageSize, currentPage).then(getDataFromAxios.get).then(tp.decode(TransactActivatedBranchResponse));
