import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Paths from '../../Data/paths';
import Logo from '../Logo';
import LoginLogout from './LoginLogout';

export default () => {
  const [headerIsOpen, updateHeader] = useState(false);
  return (
    <header>
      <nav className="bg-liven-base flex md:items-baseline flex-col md:flex-row items-start h-full justify-center" style={{ minHeight: '4rem' }}>
        <Logo fillColour={'#ffffff'} className={'h-8 w-8 hidden md:block m-1 self-center'} />

        <div className="logo-items flex justify-around items-center text-white" style={{ minHeight: '4rem' }}>
          <div className="burger-icon block md:hidden px-1" onClick={() => updateHeader(!headerIsOpen)}>
            <button className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-white hover:border-white">
              <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <Link to={Paths.HOME} className="font-semibold text-xl tracking-tight p-1 whitespace-no-wrap">
            Liven Merchant
          </Link>
        </div>

        <div
          className={
            'md:flex items-baseline justify-between text-white w-full p-2 flex-col bg-black md:flex-row md:bg-liven-base ' +
            (headerIsOpen ? 'flex' : 'hidden')
          }
        >
          <div className="text-left flex justify-between items-baseline flex-col w-full md:flex-row md:w-auto">
            <Link to={Paths.documentation.BASE} className="text-liven-pastel text-sm hover:text-white py-3 px-1 bg-inherit">
              Docs
            </Link>
            <Link to={Paths.BLOG} className="text-liven-pastel text-sm hover:text-white py-3 px-1 bg-inherit">
              Blog
            </Link>
          </div>

          <div className="header-right flex items-start w-full bg-inherit md:w-auto">
            <LoginLogout />
          </div>
        </div>
      </nav>
    </header>
  );
};
