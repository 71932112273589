import Async from '../../Async';
import Data from '../../Data';
import { Machine, MachineConfig, Interpreter, DoneInvokeEvent, assign, State, Actor } from 'xstate';
import { UserListResponse } from '../../Async/Users/response_models';
import { Lens } from 'monocle-ts';

// States
export enum States {
  SEARCH_USERS_SCREEN = 'SEARCH_USERS_SCREEN',
  SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST',
  SEARCH_USERS_ERROR = 'SEARCH_USERS_ERROR',
}

export type AutomataStates = {
  states: {
    [States.SEARCH_USERS_SCREEN]: {};
    [States.SEARCH_USERS_REQUEST]: {};
    [States.SEARCH_USERS_ERROR]: {};
  };
};

// Events
export enum Events {
  ENTER_DATA = 'ENTER_DATA',
  KEY_PRESSED = 'KEY_PRESSED',
  DATA_SUBMITTED = 'DATA_SUBMITTED',
}

export type EnterData = {
  type: Events.ENTER_DATA;
  term: string;
};
export type KeyPressed = {
  type: Events.KEY_PRESSED;
  key: string;
};
export type DataSubmitted = {
  type: Events.DATA_SUBMITTED;
};
export type AutomataEvent = EnterData | KeyPressed | DataSubmitted;

// Context
export type AutomataContext = {
  term: string;
};

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Machine = MachineConfig<AutomataContext, AutomataStates, AutomataEvent>;
export type Send = AutomataService['send'];

// Services
export const searchUsers = (c: AutomataContext, e: AutomataEvent) => Async.users.search(c.term);

// Actions
export const sendUsersToStore = (c: AutomataContext, e: DoneInvokeEvent<UserListResponse>) =>
  Data.store.dispatch(Data.creators.users.saveUsersToStore(e.data.record_list));

export const assignSearchTermToContext = assign(
  (c: AutomataContext, e: EnterData): AutomataContext => Lens.fromProp<AutomataContext>()('term').set(e.term)(c),
);

// Guards
export const enterKeyPressed = (c: AutomataContext, e: KeyPressed): boolean => e.key === 'Enter';

// Machine
export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'Users:Search:Machine',
  initial: States.SEARCH_USERS_SCREEN,
  context: {
    term: '',
  },
  states: {
    [States.SEARCH_USERS_SCREEN]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['assignSearchTermToContext'],
        },
        [Events.KEY_PRESSED]: [
          {
            target: States.SEARCH_USERS_REQUEST,
            cond: 'enterKeyPressed',
          },
        ],
        [Events.DATA_SUBMITTED]: {
          target: States.SEARCH_USERS_REQUEST,
        },
      },
    },
    [States.SEARCH_USERS_REQUEST]: {
      invoke: {
        src: 'searchUsers',
        onDone: {
          target: States.SEARCH_USERS_SCREEN,
          actions: ['sendUsersToStore'],
        },
        onError: {
          target: States.SEARCH_USERS_ERROR,
        },
      },
    },
    [States.SEARCH_USERS_ERROR]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['assignSearchTermToContext'],
        },
        [Events.KEY_PRESSED]: [
          {
            target: States.SEARCH_USERS_REQUEST,
            cond: 'enterKeyPressed',
          },
        ],
        [Events.DATA_SUBMITTED]: {
          target: States.SEARCH_USERS_REQUEST,
        },
      },
    },
  },
};

const options: any = {
  services: {
    searchUsers,
  },
  actions: {
    sendUsersToStore,
    assignSearchTermToContext,
  },
  guards: {
    enterKeyPressed,
  },
};

export default Machine<AutomataContext, AutomataStates, AutomataEvent>(config, options);
