import React from 'react';
import { Props } from '../';
import Form from './Form';

export default (props: Props) => {
  return (
    <div>
      <div>
        <Form identifier={props.identifier} />
      </div>
    </div>
  );
};
