import * as t from 'io-ts';

export const Roles = t.keyof({
  ADMINISTRATOR: null,
  STAFF: null,
  MERCHANT: null,
});

export const Scopes = t.keyof({
  DASHBOARD: null,
  TRANSACT: null,
});

export const AuthorisationResponse = t.exact(
  t.type({
    role: Roles,
    scopes: t.array(Scopes),
  }),
);

export const LoginResponse = t.exact(
  t.type({
    valid: t.literal(true),
    token: t.string,
  }),
);

export type Roles = t.TypeOf<typeof Roles>;
export type AuthorisationResponse = t.TypeOf<typeof AuthorisationResponse>;
export type LoginResponse = t.TypeOf<typeof LoginResponse>;
