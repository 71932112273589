import { Record, RecordOf } from 'immutable';
import { BranchId } from '../../organisations/@records/Branch';

type Profile = {
  first_name: string;
  last_name: string | null;
};
const defaultValues: Profile = {
  first_name: '',
  last_name: '',
};

export type ProfileRecord = RecordOf<Profile>;
export const ProfileRecord: Record.Factory<Profile> = Record(defaultValues);
