import * as React from 'react';
import Async from '../../Async';
import Data from '../../Data';
import { Machine, MachineConfig, Interpreter, DoneInvokeEvent, assign } from 'xstate';
import { UserListResponse } from '../../Async/Users/response_models';
import { Lens } from 'monocle-ts';
import { OrganisationSearchResponse } from '../../Async/Organisations/response_models';

// States
export enum States {
  SEARCH_ORGANISATIONS_SCREEN = 'SEARCH_ORGANISATIONS_SCREEN',
  SEARCH_ORGANISATIONS_REQUEST = 'SEARCH_ORGANISATIONS_REQUEST',
  SEARCH_ORGANISATIONS_ERROR = 'SEARCH_ORGANISATIONS_ERROR',
}

export type AutomataStates = {
  states: {
    [States.SEARCH_ORGANISATIONS_SCREEN]: {};
    [States.SEARCH_ORGANISATIONS_REQUEST]: {};
    [States.SEARCH_ORGANISATIONS_ERROR]: {};
  };
};

// Events
export enum Events {
  ENTER_DATA = 'ENTER_DATA',
  KEY_PRESSED = 'KEY_PRESSED',
}

export type EnterData = {
  type: Events.ENTER_DATA;
  term: string;
};
export type KeyPressed = {
  type: Events.KEY_PRESSED;
  key: string;
};
export type AutomataEvent = EnterData | KeyPressed;

// Context
export type AutomataContext = {
  term: string;
};

// Services
export const searchOrganisations = (c: AutomataContext, e: AutomataEvent) => Async.organisations.search(c.term);

// Actions
export const sendOrganisationsToStore = (c: AutomataContext, e: DoneInvokeEvent<OrganisationSearchResponse>) =>
  Data.store.dispatch(Data.creators.organisation.saveOrganisationsToStore(e.data.record_list));

export const assignSearchTermToContext = assign(
  (c: AutomataContext, e: EnterData): AutomataContext => Lens.fromProp<AutomataContext>()('term').set(e.term)(c),
);

// Guards
export const enterKeyPressed = (c: AutomataContext, e: KeyPressed): boolean => e.key === 'Enter';

// Machine
export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'Organisations:Search:Machine',
  initial: States.SEARCH_ORGANISATIONS_REQUEST,
  context: {
    term: '',
  },
  states: {
    [States.SEARCH_ORGANISATIONS_SCREEN]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['assignSearchTermToContext'],
        },
        [Events.KEY_PRESSED]: [
          {
            target: States.SEARCH_ORGANISATIONS_REQUEST,
            cond: 'enterKeyPressed',
          },
        ],
      },
    },
    [States.SEARCH_ORGANISATIONS_REQUEST]: {
      invoke: {
        src: 'searchOrganisations',
        onDone: {
          target: States.SEARCH_ORGANISATIONS_SCREEN,
          actions: ['sendOrganisationsToStore'],
        },
        onError: {
          target: States.SEARCH_ORGANISATIONS_ERROR,
        },
      },
    },
    [States.SEARCH_ORGANISATIONS_ERROR]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['assignSearchTermToContext'],
        },
        [Events.KEY_PRESSED]: [
          {
            target: States.SEARCH_ORGANISATIONS_REQUEST,
            cond: 'enterKeyPressed',
          },
        ],
      },
    },
  },
};

const options: any = {
  services: {
    searchOrganisations,
  },
  actions: {
    sendOrganisationsToStore,
    assignSearchTermToContext,
  },
  guards: {
    enterKeyPressed,
  },
};

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export default Machine<AutomataContext, AutomataStates, AutomataEvent>(config, options);
