import { Record, RecordOf } from 'immutable';
import { Authentication } from './Authentication';

export type Scopes = 'DASHBOARD' | 'TRANSACT';

export type TState = {
  credentials: Authentication;
  scopes: Array<Scopes>;
};

const defaultValues: TState = {
  credentials: Authentication(),
  scopes: [],
};

export type State = RecordOf<TState>;
export const State: Record.Factory<TState> = Record(defaultValues);
