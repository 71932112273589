import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { ListResponse } from '../@types';
import { BranchResponse } from '../Organisations/response_models';

export const SearchBranchListResponse = ListResponse(BranchResponse);

export type SearchBranchListResponse = t.TypeOf<typeof SearchBranchListResponse>;

export const searchBranchByName = (pageSize: number, name: string): Promise<AxiosResponse<SearchBranchListResponse>> =>
  axios.post(Endpoints.organisations.branches.SEARCH_BY_NAME(pageSize), { name }, Data.async.authorisationHeaders());

export default async (pageSize: number, name: string) =>
  await searchBranchByName(20, name).then(getDataFromAxios.get).then(tp.decode(SearchBranchListResponse));
