import axios, { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { NoContentResponse } from '../responses';

type ChangePasswordRequest = {
  password1: string;
  password2: string;
};

export const changePassword = (request: ChangePasswordRequest): Promise<AxiosResponse<NoContentResponse>> =>
  axios.put(Endpoints.users.CHANGE_PASSWORD(), { ...request }, Data.async.authorisationHeaders());

export default async (request: ChangePasswordRequest) => await changePassword(request);
