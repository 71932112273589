import { Record, RecordOf } from 'immutable';
import { State as AuthenticationState } from '../authentication/@records';
import { State as UserState } from '../users/@records';
import { State as OrganisationState } from '../organisations/@records';
import { State as LookState } from '../looks/@records';

type TState = {
  authentication: AuthenticationState;
  users: UserState;
  organisations: OrganisationState;
  looks: LookState;
};

export type State = RecordOf<TState>;
export const State: Record.Factory<TState> = Record({
  authentication: AuthenticationState(),
  users: UserState(),
  organisations: OrganisationState(),
  looks: LookState(),
});
