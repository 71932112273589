import * as React from 'react';

type Props = {
  className?: string;
};

export const PieChartIcon = ({ className }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M13 2C12.7348 2 12.4804 2.10536 12.2929 2.29289C12.1054 2.48043 12 2.73478 12 3V11C12 11.2652 12.1054 11.5196 12.2929 11.7071C12.4804 11.8946 12.7348 12 13 12H21C21.2652 12 21.5196 11.8946 21.7071 11.7071C21.8946 11.5196 22 11.2652 22 11C22 8.61305 21.0518 6.32387 19.364 4.63604C17.6761 2.94821 15.3869 2 13 2ZM14 10V4.07C15.4962 4.28561 16.8824 4.97978 17.9513 6.04868C19.0202 7.11759 19.7144 8.50379 19.93 10H14Z" />
    <path d="M20.8189 14.06C20.6948 14.0156 20.5632 13.9963 20.4316 14.0029C20.3 14.0096 20.171 14.0422 20.0521 14.0989C19.9331 14.1556 19.8265 14.2352 19.7385 14.3332C19.6504 14.4312 19.5826 14.5457 19.5389 14.67C19.0882 15.9439 18.3222 17.0829 17.3123 17.9808C16.3025 18.8786 15.0816 19.506 13.7637 19.8045C12.4458 20.1029 11.0738 20.0626 9.77562 19.6874C8.47747 19.3123 7.29551 18.6144 6.34001 17.6589C5.38451 16.7034 4.68661 15.5214 4.31142 14.2232C3.93623 12.9251 3.89598 11.5531 4.19442 10.2352C4.49286 8.91724 5.12026 7.6964 6.0181 6.68652C6.91594 5.67665 8.05495 4.91065 9.32887 4.46C9.5795 4.37248 9.78509 4.18898 9.90043 3.94987C10.0158 3.71076 10.0314 3.43563 9.94387 3.185C9.85635 2.93437 9.67285 2.72877 9.43374 2.61344C9.19463 2.4981 8.9195 2.48248 8.66887 2.57C7.07591 3.13278 5.6515 4.08996 4.52858 5.35222C3.40567 6.61448 2.62085 8.14065 2.24739 9.78831C1.87393 11.436 1.92399 13.1514 2.39291 14.7744C2.86183 16.3975 3.73431 17.8753 4.92893 19.0699C6.12355 20.2646 7.60136 21.137 9.22443 21.606C10.8475 22.0749 12.5629 22.1249 14.2106 21.7515C15.8582 21.378 17.3844 20.5932 18.6466 19.4703C19.9089 18.3474 20.8661 16.923 21.4289 15.33C21.5154 15.0807 21.4998 14.8072 21.3855 14.5693C21.2712 14.3314 21.0676 14.1484 20.8189 14.06Z" />
  </svg>
);

export default PieChartIcon;
