import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { DetailOrganisationResponse } from './response_models';

export const CreateOrganisationRequest = t.type({
  name: t.string,
  branches: t.array(t.string),
});

export type CreateOrganisationRequest = t.TypeOf<typeof CreateOrganisationRequest>;

export const createOrganisationAndBranches = (request: CreateOrganisationRequest): Promise<AxiosResponse<DetailOrganisationResponse>> =>
  axios.post(Endpoints.organisations.CREATE(), request, Data.async.authorisationHeaders());

export default async (request: CreateOrganisationRequest) =>
  await createOrganisationAndBranches(request).then(getDataFromAxios.get).then(tp.decode(DetailOrganisationResponse));
