import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { AutomataService, Events } from '../../../../Machines/authentication/login';
import Data from '../../../../Data';
import { validateEmail } from '../../../utils';

import { EyeIcon } from '../../../../Static/liven/Icons/EyeIcon';
import { TickIcon } from '../../../../Static/liven/Icons/TickIcon';

type Props = {
  service: AutomataService;
  isDisabled: boolean;
};

export const setDataOnMachineContext = (service: AutomataService, field: 'email' | 'password') => (event: React.ChangeEvent<HTMLInputElement>) =>
  service.send({
    type: Events.ENTER_DATA,
    field: field,
    value: event.target.value,
  });

export default ({ service, isDisabled }: Props) => {
  const [isVisible, updateIsVisible] = React.useState(false);

  const fieldWrapper = classNames('py-6 space-y-10');

  const fieldSubWrapper = classNames('relative border-b border-gray-200 focus-within:border-liven-red-200 flex');

  const userInputClass = classNames('block w-full appearance-none focus:outline-none bg-transparent py-2 text-md font-light');

  const userFlotingLabelClass = classNames('absolute top-0 duration-300 origin-0 -z-1 text-gray-500');

  const visibleIconClass = classNames('flex-shrink-0 self-center opacity-20 cursor-pointer');

  const tickIconClass = classNames({
    'flex-shrink-0 self-center opacity-0': true,
    'opacity-100': validateEmail(service.machine.context?.login.email || ''),
  });

  const submitBtnClass = classNames(
    'bg-liven-red hover:bg-liven-red-100 active:bg-liven-red-200 focus:outline-none font-sans text-xs text-white tracking-widest py-2 px-20 rounded-full font-semibold w-full mt-10',
  );

  const linkTextClass = classNames('text-xs tracking-wider font-bold text-liven-red-200 hover:opacity-75');

  const forgetPasswordText = classNames({
    [linkTextClass]: true,
    'inline-block m-auto': true,
  });

  const joinNowText = classNames({
    [linkTextClass]: true,
    'ml-2': true,
  });

  const haveAccountClass = classNames('text-center mt-auto text-xs text-black-500');

  return (
    <form onSubmit={() => service.send(Events.SUBMIT_LOGIN_DATA)}>
      {/* Email Field */}
      <div className={fieldWrapper}>
        <div className={fieldSubWrapper}>
          <input
            type="text"
            name="email"
            placeholder="Enter Your Email"
            value={service.machine.context?.login.email}
            onChange={setDataOnMachineContext(service, 'email')}
            className={userInputClass}
            disabled={isDisabled}
          />
          <span className={tickIconClass}>
            <TickIcon />
          </span>
          <label htmlFor="email" className={userFlotingLabelClass}>
            Email
          </label>
        </div>
      </div>

      {/* Password Field */}
      <div className={fieldWrapper}>
        <div className={fieldSubWrapper}>
          <input
            type={!isVisible ? 'password' : 'text'}
            name="password"
            placeholder="Enter Password"
            onChange={setDataOnMachineContext(service, 'password')}
            disabled={isDisabled}
            className={userInputClass}
          />
          <span className={visibleIconClass} onClick={() => updateIsVisible(!isVisible)}>
            <EyeIcon type={!isVisible ? 'visible' : 'visible-off'} />
          </span>
          <label htmlFor="password" className={userFlotingLabelClass}>
            Password
          </label>
        </div>
      </div>

      {/* Submit Button & Forgot Password */}
      <button className={submitBtnClass} type="submit" onClick={(e) => service.send(Events.SUBMIT_LOGIN_DATA)}>
        SIGN IN
      </button>
      <div className="text-center mt-6">
        <Link to={Data.paths.authentication.RESET_PASSWORD} className={forgetPasswordText}>
          Forgot Password?
        </Link>
        <p className={haveAccountClass}>
          Don’t have an account?
          <a href="#" className={joinNowText}>
            Join now
          </a>
        </p>
      </div>
    </form>
  );
};
