import * as React from 'react';

import { useService } from '@xstate/react/lib';
import { AutomataService, States } from '../../../../../Machines/users/createUser';
import Form from './Form';
import Error from './Error';

type Props = {
  service: AutomataService;
};
export default ({ service }: Props) => {
  const [current] = useService(service);

  return (
    <div className="p-5">
      {current.matches(States.CREATE_USER_SCREEN) && <Form service={service} isDisabled={false} />}
      {current.matches(States.CREATE_USER_REQUEST) && <Form service={service} isDisabled={true} />}
      {current.matches(States.CREATE_USER_SUCCESS) && <div>SUCCESS</div>}
      {current.matches(States.CREATE_USER_ERROR) && <Error service={service} />}
    </div>
  );
};
