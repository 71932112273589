import * as React from 'react';

type Props = {
  className?: string;
};

export const TimesIcon = ({ className }: Props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.99007 0.990669C1.79114 0.990721 1.59674 1.0501 1.43173 1.16121C1.26672 1.27233 1.1386 1.43013 1.06374 1.61444C0.988886 1.79875 0.970697 2.0012 1.0115 2.1959C1.05231 2.39061 1.15025 2.56872 1.29281 2.70747L6.58578 8.00043L1.29281 13.2934C1.19684 13.3855 1.12021 13.4959 1.06743 13.618C1.01464 13.7402 0.986757 13.8716 0.985403 14.0046C0.98405 14.1377 1.00926 14.2697 1.05954 14.3928C1.10983 14.516 1.1842 14.6279 1.27827 14.722C1.37235 14.8161 1.48426 14.8904 1.60743 14.9407C1.73061 14.991 1.86259 15.0162 1.99563 15.0149C2.12867 15.0135 2.2601 14.9856 2.38223 14.9328C2.50436 14.8801 2.61473 14.8034 2.70687 14.7075L7.99984 9.4145L13.2928 14.7075C13.385 14.8034 13.4953 14.8801 13.6175 14.9328C13.7396 14.9856 13.871 15.0135 14.0041 15.0149C14.1371 15.0162 14.2691 14.991 14.3922 14.9407C14.5154 14.8904 14.6273 14.8161 14.7214 14.722C14.8155 14.6279 14.8899 14.516 14.9401 14.3928C14.9904 14.2697 15.0156 14.1377 15.0143 14.0047C15.0129 13.8716 14.985 13.7402 14.9323 13.618C14.8795 13.4959 14.8028 13.3855 14.7069 13.2934L9.4139 8.00043L14.7069 2.70747C14.8514 2.567 14.9501 2.38616 14.99 2.18864C15.03 1.99112 15.0093 1.78614 14.9308 1.60055C14.8523 1.41497 14.7195 1.25744 14.5499 1.14861C14.3803 1.03978 14.1817 0.984729 13.9803 0.990669C13.7205 0.99841 13.4739 1.10699 13.2928 1.2934L7.99984 6.58637L2.70687 1.2934C2.61368 1.19761 2.50224 1.12146 2.37912 1.06947C2.25601 1.01747 2.12372 0.990677 1.99007 0.990669Z"
      fill="#1D1D1F"
    />
  </svg>
);

export default TimesIcon;
