import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { ListSettingResponse } from './response_models';

export const loadSettingsList = (pageSize: number, currentPage: number): Promise<AxiosResponse<ListSettingResponse>> =>
  axios.get(Endpoints.admin.settings.LIST(pageSize, currentPage), Data.async.authorisationHeaders());

export default async (pageSize: number, currentPage: number) =>
  await loadSettingsList(pageSize, currentPage).then(getDataFromAxios.get).then(tp.decode(ListSettingResponse));
