import React from 'react';
import machine from '../../../../Machines/authentication/logout';
import { useMachine } from '@xstate/react/lib';

export default () => {
  const [current, send] = useMachine(machine, { devTools: true });

  {
    console.log(current.value);
  }
  return <div />;
};
