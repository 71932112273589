import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { ListResponse } from '../@types';

export const Branch = t.type({
  identifier: t.string,
  name: t.string,
});

export const OrganisationResponse = t.exact(
  t.type({
    identifier: t.string,
    name: t.string,
    branches: t.array(Branch),
  }),
);

export const ListOrganisationResponse = ListResponse(OrganisationResponse);

export type OrganisationResponse = t.TypeOf<typeof OrganisationResponse>;
export type ListOrganisationResponse = t.TypeOf<typeof ListOrganisationResponse>;

export const loadOrganisationList = (pageSize: number, currentPage: number): Promise<AxiosResponse<ListOrganisationResponse>> =>
  axios.get(Endpoints.organisations.LIST(pageSize, currentPage), Data.async.authorisationHeaders());

export default async (pageSize: number, currentPage: number) =>
  await loadOrganisationList(pageSize, currentPage).then(getDataFromAxios.get).then(tp.decode(ListOrganisationResponse));
