import { Map, merge } from 'immutable';
import { AnyAction } from 'redux';

import actions from './actions';
import { State } from './@records';
import { OrganisationRecord } from './@records/Organisation';
import { OrganisationId } from './@types';
import { SaveOrganisationsToStore, SaveOrganisationToStore } from './creators';
import { BranchRecord } from './@records/Branch';

export const saveOrganisationToStore = (state: State, action: SaveOrganisationToStore): State => {
  console.log('SaveOrganisationToStore');

  const data = OrganisationRecord({
    identifier: action.organisation.identifier,
    name: action.organisation.name,
    branches: action.organisation.branches
      .map((branch) => BranchRecord(branch))
      .sort((b1, b2) => (b1.name.toLowerCase() < b2.name.toLowerCase() ? -1 : 1)),
  });

  console.log('SaveOrganisationToStore', action);
  return state.setIn(['organisations', data.identifier], data);
};

export const saveOrganisationsToStore = (state: State, action: SaveOrganisationsToStore): State => {
  const data = action.organisations.reduce(
    (acc, organisation) =>
      acc.set(
        organisation.identifier,
        OrganisationRecord({
          identifier: organisation.identifier,
          name: organisation.name,
          branches: organisation.branches
            .map((branch) => BranchRecord(branch))
            .sort((b1, b2) => (b1.name.toLowerCase() < b2.name.toLowerCase() ? -1 : 1)),
        }),
      ),
    Map<OrganisationId, OrganisationRecord>(),
  );

  const records = merge(state.organisations, data).sort((o1, o2) => (o1.name.toLowerCase() < o2.name.toLowerCase() ? -1 : 1));

  return state.set('organisations', records);
};

export default (state: State = State(), action: AnyAction): State => {
  switch (action.type) {
    case actions.SAVE_ORGANISATION_DATA_TO_STORE:
      return saveOrganisationToStore(state, action as SaveOrganisationToStore);

    case actions.SAVE_ORGANISATIONS_DATA_TO_STORE:
      return saveOrganisationsToStore(state, action as SaveOrganisationsToStore);

    default:
      return state;
  }
};
