import * as x from 'xstate';
import * as O from 'fp-ts/lib/Option';
import * as brands from '../../brands';
import { Lens } from 'monocle-ts';
import { pipe } from 'fp-ts/lib/pipeable';
import Async from '../../Async';
import { DoneInvokeEvent } from 'xstate';
import { CreateNewSessionRespone } from '../../Async/Transact/response_models';

// States

export enum States {
  ENTRY = 'ENTRY',
  ADD_TRANSACT_SESSION_REQUEST = 'CREATE_NEW_TRANSACT_SESSION',
  ADD_TRANSACT_SESSION_SUCCESS = 'CREATE_NEW_TRANSACT_SESSION_SUCCESS',
  ADD_TRANSACT_SESSION_ERROR = 'CREATE_NEW_TRANSACT_SESSION_ERROR',
}

export type AutomataStates = {
  states: {
    [States.ENTRY]: {};
    [States.ADD_TRANSACT_SESSION_REQUEST]: {};
    [States.ADD_TRANSACT_SESSION_SUCCESS]: {};
    [States.ADD_TRANSACT_SESSION_ERROR]: {};
  };
};

// Context

export type AutomataContext = {
  method: 'PIN' | 'SMS';
  data: {
    identifier: brands.BranchIdentifier;
    name: string;
    contactMethod: 'SMS' | 'PIN';
    contactValue: string;
  };
  result: O.Option<CreateNewSessionRespone>;
};

// Events

export enum Events {
  RESTART = 'RESTART_FLOW',
  CREATE_SESSION = 'CREATE_SESSION',
  UPDATE_CONTEXT_ITEM = 'UPDATE_CONTEXT_ITEM',
  TOGGLE_METHOD = 'TOGGLE_METHOD',
}

export type CreateSession = {
  type: Events.CREATE_SESSION;
};

export type UpdateContextItem = {
  type: Events.UPDATE_CONTEXT_ITEM;
  field: keyof Omit<AutomataContext['data'], 'identifier'>;
  value: any;
};

export type ToggleMethod = {
  type: Events.TOGGLE_METHOD;
  method: 'PIN' | 'SMS';
};

export type Restart = {
  type: Events.RESTART;
};

export type AutomataEvent = Restart | CreateSession | UpdateContextItem | ToggleMethod;

// Services
const contextLens = Lens.fromPath<AutomataContext>();

export const createSession = (c: AutomataContext, e: AutomataEvent) => {
  const data = {
    name: c.data.name as string,
    contactMethod: c.data.contactMethod as 'SMS' | 'PIN',
    contactValue: c.data.contactValue as string,
  };
  return Async.transact.create(c.data.identifier, data);
};

// Actions

export const setMethod = x.assign((c: AutomataContext, e: ToggleMethod) =>
  pipe(c, contextLens(['method']).set(e.method), contextLens(['data', 'contactMethod']).set(e.method)),
);

export const assignDataToContext = x.assign((c: AutomataContext, e: UpdateContextItem) => pipe(c, contextLens(['data', e.field]).set(e.value)));

export const assignPinToContext = x.assign((c: AutomataContext, e: AutomataEvent) => pipe(c, contextLens(['data', 'contactMethod']).set('PIN')));

export const assignMobileToContext = x.assign((c: AutomataContext, e: AutomataEvent) => pipe(c, contextLens(['data', 'contactMethod']).set('SMS')));

export const assignResponseToContext = x.assign((c: AutomataContext, e: DoneInvokeEvent<CreateNewSessionRespone>) =>
  pipe(c, contextLens(['result']).set(O.some(e.data))),
);

export const config = (identifier: brands.BranchIdentifier): x.MachineConfig<AutomataContext, AutomataStates, AutomataEvent> => ({
  id: 'Machine:Transact:Sessions:Add',
  initial: States.ENTRY,
  context: {
    method: 'PIN',
    data: {
      identifier: identifier,
      name: '',
      contactMethod: 'PIN',
      contactValue: '',
    },
    result: O.none,
  },
  states: {
    [States.ENTRY]: {
      entry: [assignPinToContext],
      on: {
        [Events.UPDATE_CONTEXT_ITEM]: {
          actions: [assignDataToContext],
        },
        [Events.CREATE_SESSION]: {
          target: States.ADD_TRANSACT_SESSION_REQUEST,
        },
        [Events.TOGGLE_METHOD]: {
          actions: ['setMethod'],
        },
      },
    },
    [States.ADD_TRANSACT_SESSION_REQUEST]: {
      invoke: {
        src: 'createSession',
        onDone: {
          target: States.ADD_TRANSACT_SESSION_SUCCESS,
          actions: ['assignResponseToContext'],
        },
        onError: {
          target: States.ADD_TRANSACT_SESSION_ERROR,
        },
      },
    },
    [States.ADD_TRANSACT_SESSION_SUCCESS]: {
      on: {
        [Events.RESTART]: {
          target: States.ENTRY,
        },
      },
    },
    [States.ADD_TRANSACT_SESSION_ERROR]: {
      on: {
        [Events.RESTART]: {
          target: States.ENTRY,
        },
      },
    },
  },
});

export const options: any = {
  services: {
    createSession,
  },
  actions: {
    setMethod,
    assignDataToContext,
    assignPinToContext,
    assignMobileToContext,
    assignResponseToContext,
  },
};

export type Service = x.Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = x.State<AutomataContext, AutomataEvent>;
export type Machine = x.MachineConfig<AutomataContext, AutomataStates, AutomataEvent>;
export type Send = Service['send'];
export const machine = (identifier: brands.BranchIdentifier) => x.Machine(config(identifier), options);

export default machine;
