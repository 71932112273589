import * as React from 'react';

import { CurrentState, Send } from '../../../../../../Machines/organisations/update';
import Branch from './Branch';

type Props = {
  current: CurrentState;
  send: Send;
};

export default ({ current, send }: Props) => (
  <>
    {current.context.data.branches.map((b) => (
      <Branch current={current} send={send} branch={b} />
    ))}
  </>
);
