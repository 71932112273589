import actions from './actions';
import { SaveLoginDataToStore } from './@types';
import { AuthorisationResponse } from '../../Async/Authentication/response_models';
import { Scopes } from './@records';

export const saveLoginDataToStore = (token: string, scopes: Array<Scopes>): SaveLoginDataToStore => ({
  type: actions.SAVE_LOGIN_DATA_TO_STORE,
  token: token,
  scopes: scopes,
});

export const saveScopeDataToStore = (response: AuthorisationResponse) => ({
  type: actions.SAVE_SCOPE_DATA_TO_STORE,
  scopes: response.scopes,
  role: response.role,
});

export const successfullyLoggedOut = () => ({
  type: actions.SUCCESSFULLY_LOGGED_OUT,
});

export default {
  saveLoginDataToStore,
  saveScopeDataToStore,
  successfullyLoggedOut,
};
