import * as React from 'react';
import classnames from 'classnames';
import { useMachine } from '@xstate/react/lib';

import machine, { Send, Events, CurrentState, States } from '../../../../../../../Machines/organisations/add_look_to_branch';
import { LookRecord } from '../../../../../../../Data/looks/@records/Look';
import { BranchRecord } from '../../../../../../../Data/organisations/@records/Branch';

type Props = {
  look: LookRecord;
  branch: BranchRecord;
};

const SubmitData = (send: Send) => (e: React.MouseEvent) => send(Events.SUBMIT_DATA);

const cssClasses = (current: CurrentState): string =>
  classnames([current.matches(States.ADD_LOOK_TO_BRANCH_SUCCESS) && ['bg-green-100'], ['cursor-pointer', 'hover:bg-gray-200', 'transition-250']]);

export default ({ look, branch }: Props) => {
  const [current, send] = useMachine(machine(branch, look), {
    devTools: true,
  });

  return (
    <div className={cssClasses(current)} onClick={SubmitData(send)}>
      {look.name}
    </div>
  );
};
