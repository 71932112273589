import * as React from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

import AddLook from './AddLook';
import { State as StoreState } from '../../../../../../../Data/@records';
import { LookRecord } from '../../../../../../../Data/looks/@records/Look';
import { BranchRecord } from '../../../../../../../Data/organisations/@records/Branch';
import { CurrentState } from '../../../../../../../Machines/looks/search';

type Props = {
  branch: BranchRecord;
  current: CurrentState;
};

export default ({ current, branch }: Props) => {
  const term = current.context.term.toLowerCase();

  const looks = useSelector<StoreState, List<LookRecord>>((state) =>
    state.looks.looks
      .toList()
      .filter((l) => l.name.toLowerCase().includes(term) || l.description.toLowerCase().includes(term) || l.link.toLowerCase().includes(term))
      .sort((u1, u2) => (u1.name < u2.name ? -1 : 1)),
  );

  return (
    <div className="mb-2">
      {looks.map((look) => (
        <AddLook branch={branch} look={look} />
      ))}
    </div>
  );
};
