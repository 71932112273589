import React from 'react';
import { Props } from './@types';
import Data from '../../../../Data';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { validateEmail } from '../../../utils';

import { TickIcon } from '../../../../Static/liven/Icons/TickIcon';
import { Events } from '../../../../Machines/authentication/reset_password';

export const submitResetPassword = (send: Props['send']) => (event: React.MouseEvent<HTMLButtonElement>) => send(Events.SUBMIT_RESET_PASSWORD);

export const setDataOnMachineContext = (current: Props['current'], send: Props['send']) => (event: React.ChangeEvent<HTMLInputElement>) =>
  send({
    type: Events.ENTER_DATA,
    value: event.target.value,
  });

export default ({ current, send, isDisabled }: Props & { isDisabled: boolean }) => {
  const fieldWrapper = classNames('py-6 space-y-10');

  const fieldSubWrapper = classNames('relative border-b border-gray-200 focus-within:border-liven-red-200 flex');

  const userInputClass = classNames('block w-full appearance-none focus:outline-none bg-transparent py-2 text-md font-light');

  const userFlotingLabelClass = classNames('absolute top-0 duration-300 origin-0 -z-1 text-gray-500');

  const tickIconClass = classNames({
    'flex-shrink-0 self-center opacity-0': true,
    'opacity-100': validateEmail(current.context.email || ''),
  });

  const submitBtnClass = classNames(
    'bg-liven-red hover:bg-liven-red-100 active:bg-liven-red-200 focus:outline-none font-sans text-xs text-white tracking-widest py-2 px-20 rounded-full font-semibold w-full mt-10',
  );

  const linkTextClass = classNames('text-xs tracking-wider font-bold text-liven-red-200 hover:opacity-75');

  const forgetPasswordText = classNames({
    [linkTextClass]: true,
    'inline-block m-auto': true,
  });

  return (
    <>
      <div className={fieldWrapper}>
        <div className={fieldSubWrapper}>
          {/* Email Field */}
          <input
            type="email"
            name="email"
            placeholder="Enter Your Email"
            value={current.context.email}
            onChange={setDataOnMachineContext(current, send)}
            className={userInputClass}
            disabled={isDisabled}
          />
          <span className={tickIconClass}>
            <TickIcon />
          </span>
          <label htmlFor="email" className={userFlotingLabelClass}>
            Email
          </label>
        </div>
      </div>

      {/* Reset Button & Return Login Pages */}
      <button className={submitBtnClass} type="submit" onClick={submitResetPassword(send)}>
        RESET
      </button>
      <div className="text-center mt-6">
        <Link to={Data.paths.authentication.LOGIN} className={forgetPasswordText}>
          Return to Login
        </Link>
      </div>
    </>
  );
};
