import * as React from 'react';
import classnames from 'classnames';

import { BranchResponse } from '../../../../../../Async/Organisations/response_models';
import { AutomataService, Events } from '../../../../../../Machines/users/listBranches';

const userAssignedToBranch = (branch: BranchResponse, service: AutomataService): boolean =>
  service.state.context.data.record_list.map((r) => r.identifier).includes(branch.identifier);

const cssClasses = (branch: BranchResponse, service: AutomataService): string =>
  classnames([
    'py-1 border-t border-gray-400',
    userAssignedToBranch(branch, service) && ['bg-green-100'],
    !userAssignedToBranch(branch, service) && ['cursor-pointer', 'hover:bg-gray-200', 'transition-250'],
  ]);

const assignUserToBranch = (branch: BranchResponse, service: AutomataService) => (e: React.MouseEvent<HTMLDivElement>) =>
  !userAssignedToBranch(branch, service) &&
  service.send({
    type: Events.ASSIGN_USER_TO_BRANCH,
    branchId: branch.identifier,
  });

type Props = {
  branch: BranchResponse;
  service: AutomataService;
};

export default ({ branch, service }: Props) => (
  <div onClick={assignUserToBranch(branch, service)} className={cssClasses(branch, service)}>
    <div className="text-xs">{branch.organisation.name}</div>
    <div>{branch.name}</div>
  </div>
);
