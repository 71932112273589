import * as React from 'react';
import classNames from 'classnames';

import { AutomataService, Events } from '../../../../../../Machines/looks/listLooksForUser';
import history from '../../../../../../history';
import Data from '../../../../../../Data';
import { BranchWithLooksDetails } from '../../../../../../Async/Looks/get_for_user';

export type Indexable<A> = {
  [index: number]: A;
  length: number;
};

export const safePop: <A>(a: Indexable<A>, n: number, fallback: A) => A = (a, n, fallback) => (a.length > n ? a[n] : fallback);

export const getInitials = (orgName: string) => {
  const names: Array<string> = orgName.split(' ');
  return safePop(safePop(names, 0, ''), 0, '') + safePop(safePop(names, 1, ''), 0, '');
};

const ActivateBranch = (service: AutomataService, branch: BranchWithLooksDetails, onClick: () => void) => (e: React.MouseEvent<HTMLElement>) => {
  service.send({
    type: Events.ACTIVATE_BRANCH,
    branchIdentifier: branch.identifier,
  });
  onClick();
  history.push(Data.paths.merchant.BASE);
};

export type OrgCardProps = {
  isCollapsed: boolean;
  orgName: string;
  orgAddress?: string;
  orgThumbnailUrl?: string;
  service: AutomataService;
  branch: BranchWithLooksDetails;
  isSelected: boolean;
  onClick: () => void;
};

export const OrgCard = ({
  isCollapsed = false,
  orgName,
  orgAddress,
  orgThumbnailUrl = 'https://www.force/break/stuff.yew',
  service,
  branch,
  isSelected,
  onClick = () => {},
}: OrgCardProps) => {
  const wrapperClass = classNames({
    'w-full px-4 py-2 my-4 rounded-large font-liven-primary': true,
    'bg-gray-300': isSelected && !isCollapsed,
    'cursor-pointer': !isSelected,
  });
  const containerClass = classNames({
    'flex items-center min-h-12': true,
    'flex-col font-bold': isCollapsed,
  });
  const thumbnailClass = classNames({
    'w-10 h-10 p-px border border-gray-500 border-solid rounded-full \
        min-h-10 min-w-10 font-normal text-center bg-gray-400 leading-9 uppercase':
      true,
    'p-0 font-bold border-2 border-gray-700': isSelected,
    'mb-2': isCollapsed,
  });
  const cardDetailsClass = classNames({
    'flex flex-col overflow-hidden whitespace-no-wrap': true,
    'ml-4': !isCollapsed,
    'm-0 font-bold': isCollapsed,
  });
  const orgNameClass = classNames({
    'text-xs ': true,
    'text-gray-600': !isSelected,
    'font-bold text-black': isSelected,
    'text-xs text-center whitespace-normal': isCollapsed,
  });
  const orgAddressClass = classNames({
    'text-xs text-gray-600': true,
    hidden: isCollapsed,
  });

  return (
    <div className={wrapperClass} onClick={ActivateBranch(service, branch, onClick)}>
      <div className={containerClass}>
        {/* Initials */}
        <object className={thumbnailClass} type="image/png" data={orgThumbnailUrl}>
          {getInitials(orgName)}
        </object>

        {/* Card Details  */}
        <div className={cardDetailsClass}>
          <div className={orgNameClass}>{orgName}</div>
          <div className={orgAddressClass}>{orgAddress}</div>
        </div>
      </div>
    </div>
  );
};

export default OrgCard;
