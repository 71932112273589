import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  label: string;
  path: string;
};

export default (props: Props) => (
  <Link to={props.path}>
    <div className="cursor-pointer mb-3">
      <div className="w-full flex items-center justify-center">
        <div className="border-gray-400 border h-12 w-12 flex items-center justify-center text-gray-600 text-2xl font-semibold rounded-lg mb-1 overflow-hidden">
          {props.label.charAt(0)}
        </div>
      </div>
      <div className="text-center text-gray-600 text-xs">{props.label}</div>
    </div>
  </Link>
);
