import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import { matchPath } from 'react-router';
import { pipe } from 'fp-ts/lib/pipeable';
import history from '../../history';

// Extracts the identifier from the browsers current location

export const Match = t.exact(
  t.type({
    isExact: t.boolean,
    params: t.type({
      identifier: UUID,
    }),
    path: t.string,
    url: t.string,
  }),
);

export type Match = t.TypeOf<typeof Match>;

export const extractIdentifier = (path: string) =>
  matchPath(history.location.pathname, {
    path: path,
    exact: true,
    strict: false,
  });

export default (path: string) => pipe(extractIdentifier(path), Match.decode);
