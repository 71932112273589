import * as React from 'react';

import machine, { States } from '../../../../../Machines/looks/create';
import { useMachine } from '@xstate/react';

import Sending from './Sending';
import Error from './Error';
import Main from './Main';

export default () => {
  const [current, send] = useMachine(machine, { devTools: true });

  return (
    <div className="p-5">
      {current.matches(States.CREATE_LOOK_SCREEN) && <Main send={send} current={current} />}
      {current.matches(States.CREATE_LOOK_REQUEST) && <Sending current={current} send={send} />}
      {current.matches(States.CREATE_LOOK_ERROR) && <Error current={current} send={send} />}
    </div>
  );
};
