import React from 'react';
import classNames from 'classnames';
import { useMachine } from '@xstate/react/lib';

import machine, { Events, CurrentState, States } from '../../../../../Machines/authentication/change_password';

const formFieldClassNames = (state: CurrentState) =>
  classNames(
    'py-2 px-5 rounded bg-gray-200 text-gray-600 text-sm w-1/2 border border-gray-200',
    state.value === States.CHANGE_PASSWORD_ERROR && 'border-red-600',
    state.value === States.CHANGE_PASSWORD_SUCCESS && 'border-green-600',
  );

export default () => {
  const [current, send] = useMachine(machine, { devTools: true });

  return (
    <div className="bg-white rounded-lg px-5 py-8 mt-8 w-2/3">
      <div>Change Password</div>

      <div>
        <div className="mt-4">
          <input
            type="password"
            value={current.context.password1}
            onFocus={(e) => send(Events.INPUT_FOCUS)}
            onChange={(e) => send({ type: Events.ENTER_DATA_1, value: e.target.value })}
            className={formFieldClassNames(current)}
            placeholder="New Password"
          />
        </div>

        <div className="mt-4">
          <input
            type="password"
            value={current.context.password2}
            onFocus={(e) => send(Events.INPUT_FOCUS)}
            onChange={(e) => send({ type: Events.ENTER_DATA_2, value: e.target.value })}
            className={formFieldClassNames(current)}
            placeholder="Repeat Password"
          />
        </div>
      </div>

      <div className="mt-8">
        <button onClick={(e) => send({ type: Events.SUBMIT_DATA })} className="bg-liven-base py-1 px-6 rounded-full text-sm text-white">
          Submit
        </button>
      </div>
    </div>
  );
};
