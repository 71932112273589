import * as React from 'react';

import { CurrentState, Send } from '../../../../../Machines/looks/create';
import Form from './Form';

type Props = {
  send: Send;
  current: CurrentState;
};

export default ({ send, current }: Props) => (
  <div>
    <Form send={send} current={current} isDisabled={false} />
  </div>
);
