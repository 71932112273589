import { Map, merge } from 'immutable';
import { AnyAction } from 'redux';

import actions from './actions';

import { State } from './@records';
import { LookUUID } from './@types';
import { SaveLookToStore, SaveLooksToStore } from './creators';
import { LookRecord } from './@records/Look';

export const saveLookToStoreToStore = (state: State, action: SaveLookToStore): State => {
  const data = state.setIn(['looks', action.look.identifier], LookRecord({ ...action.look }));

  const sortedRecords = data.looks.sort((l1, l2) => (l1.name.toLowerCase() < l2.name.toLowerCase() ? -1 : 1));

  return data.set('looks', sortedRecords);
};

export const saveLooksToStoreToStore = (state: State, action: SaveLooksToStore): State => {
  const data = action.looks.reduce((acc, look) => acc.set(look.identifier, LookRecord({ ...look })), Map<LookUUID, LookRecord>());

  const sortedRecords = merge(state.looks, data).sort((l1, l2) => (l1.name.toLowerCase() < l2.name.toLowerCase() ? -1 : 1));

  return state.set('looks', sortedRecords);
};

export default (state: State = State(), action: AnyAction): State => {
  switch (action.type) {
    case actions.SAVE_LOOK_DATA_TO_STORE:
      return saveLookToStoreToStore(state, action as SaveLookToStore);

    case actions.SAVE_LOOKS_DATA_TO_STORE:
      return saveLooksToStoreToStore(state, action as SaveLooksToStore);

    default:
      return state;
  }
};
