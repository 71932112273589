import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { DetailedLookResponse } from './response_models';

export const CreateLookRequest = t.exact(
  t.type({
    name: t.string,
    description: t.string,
    link: t.string,
  }),
);

export type CreateLookRequest = t.TypeOf<typeof CreateLookRequest>;

export const createLook = (request: CreateLookRequest): Promise<AxiosResponse<DetailedLookResponse>> =>
  axios.post(Endpoints.looks.CREATE(), request, Data.async.authorisationHeaders());

export default async (request: CreateLookRequest) => await createLook(request).then(getDataFromAxios.get).then(tp.decode(DetailedLookResponse));
