import * as React from 'react';
import { Send, States, CurrentState, Events } from '../../../../../../../Machines/organisations/update';
import { BranchResponse, LookResponse } from '../../../../../../../Async/Organisations/response_models';

import Look from './Look';
import Empty from './Empty';

type Props = {
  current: CurrentState;
  looks: Array<LookResponse>;
  send: Send;
  branch: BranchResponse;
};

export default ({ looks, send, branch, current }: Props) => (
  <>{branch.looks.length === 0 ? <Empty /> : looks.map((l) => <Look send={send} look={l} key={l.identifier} branch={branch} />)}</>
);
