import * as React from 'react';
import { State } from 'xstate';
import { AutomataContext, AutomataEvent } from '../../../../../../Machines/users/search';

import User from './User';
import { useAppSelector } from '../../../../../../hooks';

type Props = {
  current: State<AutomataContext, AutomataEvent>;
};
export default ({ current }: Props) => {
  const users = useAppSelector((state) =>
    state.users.users.filter(
      (u) =>
        u.details.first_name.toLowerCase().includes(current.context.term.toLowerCase()) ||
        u.details.last_name?.toLowerCase().includes(current.context.term.toLowerCase()) ||
        u.email.toLowerCase().includes(current.context.term.toLowerCase()),
    ),
  ).toList();

  return (
    <div className="mb-2">
      {users.map((u) => (
        <User user={u} />
      ))}
    </div>
  );
};
