import * as React from 'react';
import classNames from 'classnames';

export type SimpleNavItemProps = {
  label: string;
  isActive: boolean;
  onClick: (...props: any) => void;
};

export const SimpleNavItem = ({ label, isActive, onClick }: SimpleNavItemProps) => {
  const itemClass = classNames({
    'pb-4 mr-12 font-bold tracking-widest uppercase border-b-4 border-solid': true,
    'text-gray-500 border-transparent cursor-pointer': !isActive,
    'text-black border-black cursor-default': isActive,
  });
  return (
    <div className={itemClass} onClick={onClick}>
      {label}
    </div>
  );
};
