import * as React from 'react';
import * as R from 'react-redux';
import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

import classNames from 'classnames';
import FeatureSidebarItem from './FeatureSidebarItem';
import Data from '../../../../../Data';
import { Link } from 'react-router-dom';
import { pipe } from 'fp-ts/lib/pipeable';

import AlignLeftIcon from '../../../../../Static/liven/Icons/AlignLeftIcon';
import BarsIcon from '../../../../../Static/liven/Icons/BarsIcon';
import PieChartIcon from '../../../../../Static/liven/Icons/PieChartIcon';
import { State } from '../../../../../Data/@records';

export type FeatureSidebarProps = {
  userInitials: string;
  fullName: string;
};

const alignIconClass = classNames('cursor-pointer sm:flex md:hidden p-4');
const LivenLogoClass = classNames('mt-4 md:mt-10 mb-10 md:mb-12');
const barIconClass = classNames('absolute left-0 mt-6 pl-4 cursor-pointer md:hidden');
const navItemColClass = classNames('flex flex-col-reverse md:flex-col w-full md:flex-grow pl-3 md:pl-0');
const navItemFeatureClass = classNames('w-full flex flex-col flex-grow justify-center');
const userInitialsColClass = classNames(
  'flex items-center md:justify-center border-b md:border-b-0 pb-8 md:pb-0 mb-5 md:mb-20 md:mt-12 font-semibold',
);
const userInitialsClass = classNames(
  'p-2 text-xs font-bold text-gray-600 uppercase bg-gray-100 border border-gray-200 opacity-75 border-solid rounded-lg w-auto',
);
const userLabelClass = classNames('ml-4 text-black-300 text-sm block md:hidden');
const wrapperSidebarClass = (isActiveMenu: boolean) =>
  classNames({
    'flex flex-col items-center w-3/5 h-full overflow-hidden bg-white font-liven-primary fixed top-0 transition-all duration-500 ease-in-out z-50':
      true,
    'md:w-32 md:h-auto md:relative': true,
    '-ml-72 md:ml-0': !isActiveMenu,
  });
const sidebarLayerClass = (isActiveMenu: boolean) =>
  classNames({
    'bg-black-300 opacity-50 fixed top-0 h-full w-full sm:flex md:hidden transition-all duration-500 ease-in-out z-50': true,
    'hidden md:flex': !isActiveMenu,
  });

const DashboardFeature = () => (
  <FeatureSidebarItem title="Dashboard" to={Data.paths.merchant.BASE}>
    <PieChartIcon className="w-6 fill-current" />
  </FeatureSidebarItem>
);

const TransactFeature = () => (
  <FeatureSidebarItem title="Team" to={Data.paths.merchant.TRANSACT}>
    <PieChartIcon className="w-6 fill-current" />
  </FeatureSidebarItem>
);

export const FeatureSidebar = ({ userInitials, fullName }: FeatureSidebarProps) => {
  const [isActiveMenu, updateIsActiveMenu] = React.useState(false);

  const states = R.useSelector((state: State) => state.authentication.scopes);

  const Dashboard = () =>
    pipe(
      states,
      O.fromPredicate((v) => v.includes('DASHBOARD')),
      O.fold(F.constant(<></>), F.constant(<DashboardFeature />)),
    );

  const Transact = () =>
    pipe(
      states,
      O.fromPredicate((v) => v.includes('TRANSACT')),
      O.fold(F.constant(<></>), F.constant(<TransactFeature />)),
    );

  return (
    <div className="flex">
      <div className={sidebarLayerClass(isActiveMenu)} onClick={() => updateIsActiveMenu(!isActiveMenu)} />
      <div className={alignIconClass} onClick={() => updateIsActiveMenu(!isActiveMenu)}>
        <AlignLeftIcon />
      </div>
      <div className={wrapperSidebarClass(isActiveMenu)}>
        {/* Bar Icon */}
        <div className={barIconClass} onClick={() => updateIsActiveMenu(!isActiveMenu)}>
          <BarsIcon />
        </div>

        {/* Liven Logo */}
        <img
          src="https://livenpay-public.s3-ap-southeast-2.amazonaws.com/liven-branding/white_liven_trio.svg"
          alt="liven-logo"
          className={LivenLogoClass}
        />

        {/* Sidebar & Account */}
        <div className={navItemColClass}>
          <div className={navItemFeatureClass}>
            <Dashboard />
            <Transact />
            {/* <FeatureSidebarItem className="md:hidden" title="Logout" to={Data.paths.authentication.LOGOUT}>
              <LogoutIcon />
            </FeatureSidebarItem> */}
          </div>

          <Link to={Data.paths.merchant.ACCOUNT} className={userInitialsColClass}>
            <div className={userInitialsClass}>{userInitials}</div>
            <div className={userLabelClass}>{fullName}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeatureSidebar;

// TODO: add the users initials in the profile.
// TODO: setup a machine for the feature sidebar.
