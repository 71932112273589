import * as t from 'io-ts';

export const BranchIdentifier = t.brand(
  t.string,
  (n): n is t.Branded<string, { readonly BranchIdentifier: unique symbol }> => n.length >= 0,
  'BranchIdentifier',
);
export type BranchIdentifier = t.TypeOf<typeof BranchIdentifier>;

export const TransactSessionIdentifier = t.brand(
  t.string,
  (n): n is t.Branded<string, { readonly TransactSessionIdentifier: unique symbol }> => n.length >= 0,
  'TransactSessionIdentifier',
);
export type TransactSessionIdentifier = t.TypeOf<typeof TransactSessionIdentifier>;

export const TransactSessionCode = t.brand(
  t.number,
  (n): n is t.Branded<number, { readonly TransactSessionCode: unique symbol }> => n >= 100000 && n <= 999999,
  'TransactSessionCode',
);
export type TransactSessionCode = t.TypeOf<typeof TransactSessionCode>;

export default {
  BranchIdentifier,
  TransactSessionIdentifier,
  TransactSessionCode,
};
