import * as React from 'react';
import classNames from 'classnames';

import { Send, CurrentState, Events } from '../../../../../Machines/looks/listLooksForUser';
import Spinner from '../../Spinner';

type Props = {
  current: CurrentState;
  send: Send;
};

function handleLookIframeLoad(e: any, send: Send) {
  if (e.target.src.startsWith('https://livenpoc.au.looker.com')) {
    send(Events.LOOK_LOADED);
  }
}

export default ({ current, send }: Props) => {
  const branch = current.context.looks.find((b) => b.identifier === current?.context.activeBranch);
  const look = branch ? branch.looks.find((l) => l.identifier === current.context.activeLook) : null;
  const spinnerClass = classNames({
    'w-32 h-32 absolute': true,
    hidden: !current.context.lookIsLoading,
  });
  return (
    <div className="flex flex-col flex-grow mt-5" style={{ height: '1500px' }}>
      <div className="text-xl text-center text-gray-600">{look?.name}</div>
      <div className="mt-3 text-sm text-center text-gray-600">{look?.description}</div>
      <div className="relative flex-grow">
        <iframe onLoad={(e) => handleLookIframeLoad(e, send)} className="w-full h-full mt-3" src={current?.context.oneTimeToken} />
        <div className={spinnerClass} style={{ left: '40%', top: '40%' }}>
          <Spinner />
        </div>
      </div>
    </div>
  );
};
