import * as React from 'react';

import { AutomataContext, Events, CurrentState, Send } from '../../../../../Machines/looks/update';

export const sendUserData = (send: Send, field: keyof AutomataContext['data']) => (e: React.ChangeEvent<HTMLInputElement>) =>
  send({
    type: Events.ENTER_DATA,
    field: field,
    value: e.target.value,
  });

export const submitData = (send: Send) => (e: React.MouseEvent<HTMLButtonElement>) => send(Events.SUBMIT_DATA);

export const inputFocused = (send: Send) => (e: React.FocusEvent<HTMLInputElement>) => send(Events.INPUT_FOCUSED);

type Props = {
  isDisabled: boolean;
  current: CurrentState;
  send: Send;
};

export default ({ isDisabled, current, send }: Props) => (
  <div className="shadow-md rounded my-6 w-5/6 mx-auto py-4 px-6">
    <input
      value={current.context.data.name}
      onChange={sendUserData(send, 'name')}
      onFocus={inputFocused(send)}
      autoFocus={true}
      className="text-input mb-4"
      type="email"
      placeholder="Email"
      disabled={isDisabled}
    />

    <input
      value={current.context.data.description}
      onChange={sendUserData(send, 'description')}
      onFocus={inputFocused(send)}
      className="text-input mb-4"
      type="text"
      placeholder="First Name"
      disabled={isDisabled}
    />

    <input
      value={current.context.data.link}
      onChange={sendUserData(send, 'link')}
      onFocus={inputFocused(send)}
      className="text-input mb-4"
      type="text"
      placeholder="Last Name"
      disabled={isDisabled}
    />

    <div className="flex justify-start">
      <button disabled={isDisabled} onClick={submitData(send)} className="btn mb-4" type="button">
        Update Look
      </button>
    </div>
  </div>
);
