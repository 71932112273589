import list from './list';
import detail from './detail';
import search from './search';
import create from './create';
import update from './update';
import add_user_to_branch from './add_user_to_branch';
import add_look_to_branch from './add_look_to_branch';
import remove_look_from_branch from './remove_look_from_branch';
import remove_user_from_branch from './remove_user_from_branch';
import update_branch from './update_branch';
import create_branch from './create_branch';

export default {
  list,
  detail,
  search,
  create,
  update,
  add_user_to_branch,
  add_look_to_branch,
  remove_look_from_branch,
  remove_user_from_branch,
  update_branch,
  create_branch,
};
