import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { TokenResponse } from './response_models';

export const GetLookTokenRequest = t.exact(
  t.type({
    branchId: t.string,
    lookId: t.string,
  }),
);

export type GetLookTokenRequest = t.TypeOf<typeof GetLookTokenRequest>;

export const getLookAccessToken = (request: GetLookTokenRequest): Promise<AxiosResponse<TokenResponse>> =>
  axios.post(Endpoints.looks.GET_TOKEN(), request, Data.async.authorisationHeaders());

export default async (lookId: string, branchId: string) =>
  await getLookAccessToken({ lookId, branchId }).then(getDataFromAxios.get).then(tp.decode(TokenResponse));
