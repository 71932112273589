import { Record, RecordOf, OrderedMap } from 'immutable';
import { LookUUID } from '../@types';
import { LookRecord } from './Look';

export type State_ = {
  looks: OrderedMap<LookUUID, LookRecord>;
};

const defaultValues: State_ = {
  looks: OrderedMap<LookUUID, LookRecord>(),
};

export type State = RecordOf<State_>;
export const State: Record.Factory<State_> = Record(defaultValues);
