import * as React from 'react';

type Props = {
  label: string;
  value: string;
};
export default ({ label, value }: Props) => (
  <div className="py-5 mb-2 border text-gray-600 block transition-250 flex justify-between">
    <div className="w-2/6 flex items-center justify-start px-1 sm:px-3 md:px-4 lg:px4">{label}</div>

    <div className="w-4/6">
      <input type="text" className="text-input" value={value} />
    </div>

    <div className="w-1/6 items-center flex justify-around">
      <button className="btn-inline">UPDATE</button>
    </div>
  </div>
);
