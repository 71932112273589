import { Machine, Interpreter, MachineConfig, assign, DoneInvokeEvent, State } from 'xstate';
import { Lens } from 'monocle-ts';

import Data from '../../Data';
import Async from '../../Async';
import History from '../../history';

import { CreateLookRequest } from '../../Async/Looks/create';
import { DetailedLookResponse } from '../../Async/Looks/response_models';

// States

export enum States {
  CREATE_LOOK_SCREEN = 'CREATE_LOOK_SCREEN',
  CREATE_LOOK_REQUEST = 'CREATE_LOOK_REQUEST',
  CREATE_LOOK_SUCCESS = 'CREATE_LOOK_SUCCESS',
  CREATE_LOOK_ERROR = 'CREATE_LOOK_ERROR',
}

export type AutomataStates = {
  states: {
    [States.CREATE_LOOK_SCREEN]: {};
    [States.CREATE_LOOK_REQUEST]: {};
    [States.CREATE_LOOK_SUCCESS]: {};
    [States.CREATE_LOOK_ERROR]: {};
  };
};

// Context

export type AutomataContext = {
  formData: CreateLookRequest;
};

// Events

export enum Events {
  SUBMIT_DATA = 'SUBMIT_DATA',
  ENTER_DATA = 'ENTER_DATA',
}

export type SubmitData = {
  type: Events.SUBMIT_DATA;
};

export type EnterData = {
  type: Events.ENTER_DATA;
  field: keyof AutomataContext['formData'];
  value: string;
};

export type AutomataEvent = SubmitData | EnterData;

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

// Services

export const create = (c: AutomataContext, e: AutomataEvent) => Async.looks.create(c.formData);

// Actions

export const AssignDataToContext = assign((c: AutomataContext, e: EnterData) =>
  Lens.fromPath<AutomataContext>()(['formData', e.field]).set(e.value)(c),
);

export const AddLookToStore = (c: AutomataContext, e: DoneInvokeEvent<DetailedLookResponse>) =>
  Data.store.dispatch(Data.creators.looks.saveLookToStore(e.data.record));

export const RedirectToDetailPage = (c: AutomataContext, e: DoneInvokeEvent<DetailedLookResponse>) =>
  History.push(Data.paths.admin.looks.TO_DETAIL(e.data.identifier));

// Config

export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'Machine:Looks:Create',
  initial: States.CREATE_LOOK_SCREEN,
  context: {
    formData: {
      name: '',
      description: '',
      link: '',
    },
  },
  states: {
    [States.CREATE_LOOK_SCREEN]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['AssignDataToContext'],
        },
        [Events.SUBMIT_DATA]: {
          target: States.CREATE_LOOK_REQUEST,
        },
      },
    },
    [States.CREATE_LOOK_REQUEST]: {
      invoke: {
        src: 'create',
        onDone: {
          target: States.CREATE_LOOK_SUCCESS,
          actions: ['AddLookToStore', 'RedirectToDetailPage'],
        },
        onError: {
          target: States.CREATE_LOOK_ERROR,
        },
      },
    },
    [States.CREATE_LOOK_SUCCESS]: {
      type: 'final',
    },
    [States.CREATE_LOOK_ERROR]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['AssignDataToContext'],
        },
        [Events.SUBMIT_DATA]: {
          target: States.CREATE_LOOK_REQUEST,
        },
      },
    },
  },
};

export const options: any = {
  services: {
    create,
  },
  actions: {
    AssignDataToContext,
    AddLookToStore,
    RedirectToDetailPage,
  },
};

export default Machine(config, options);
