import * as React from 'react';
import { Link } from 'react-router-dom';

import Look from './Look';
import Data from '../../../../../Data';
import SimpleNavBar from './SimpleNavBar';

import { LookDetails } from '../../../../../Async/Looks/get_for_user';
import { Events, Send, CurrentState } from '../../../../../Machines/looks/listLooksForUser';
import { SimpleNavItemProps } from './SimpleNavBar/SimpleNavItem';

const LoadLookToken = (send: Send, look: LookDetails) => (e: React.MouseEvent<HTMLElement>) =>
  send({
    type: Events.FETCH_LOOK_TOKEN,
    lookIdentifier: look.identifier,
  });

type Props = {
  current: CurrentState;
  send: Send;
};

export default ({ current, send }: Props) => {
  const activeLook = current.context.activeLook;
  const activeBranch = current.context.looks.filter((b) => b.identifier === current.context.activeBranch).pop();
  const navItems = activeBranch?.looks.map(
    (l): SimpleNavItemProps => ({
      label: l.name,
      isActive: l.identifier === activeLook,
      onClick: LoadLookToken(send, l),
    }),
  );

  return (
    <div>
      <div className={'md:mb-10 md:pb-4'}>
        <Link to={Data.paths.merchant.BASE} className={'text-2xl font-bold mb-8 md:mb-0 md:mt-14'}>
          Dashboard
        </Link>
      </div>
      <SimpleNavBar navItems={navItems || []} />
      {activeBranch && activeLook && <Look current={current} send={send} />}
    </div>
  );
};
