import * as t from 'io-ts';
import * as brands from '../../brands';
import { ListResponse } from '../generics';

export const TransactUser = t.exact(
  t.type({
    id: brands.TransactSessionIdentifier,
    identifier: t.string,
  }),
);
export type TransactUser = t.TypeOf<typeof TransactUser>;

export const TransactSessionListResponse = t.exact(
  t.type({
    count: t.number,
    records: t.array(TransactUser),
  }),
);
export type TransactSessionListResponse = t.TypeOf<typeof TransactSessionListResponse>;

export const CreateNewSessionRespone = t.exact(
  t.type({
    code: brands.TransactSessionCode,
    contact_method: t.string,
    sent: t.boolean,
  }),
);
export type CreateNewSessionRespone = t.TypeOf<typeof CreateNewSessionRespone>;

export const TransactActivatedBranch = t.exact(
  t.type({
    name: t.string,
    identifier: brands.BranchIdentifier,
  }),
);
export type TransactActivatedBranch = t.TypeOf<typeof TransactActivatedBranch>;

export const TransactActivatedBranchResponse = ListResponse(TransactActivatedBranch);
export type TransactActivatedBranchResponse = t.TypeOf<typeof TransactActivatedBranchResponse>;
