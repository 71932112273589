import Async from '../../Async';
import Data from '../../Data';
import { Machine, MachineConfig, Interpreter, DoneInvokeEvent } from 'xstate';
import { UserListResponse } from '../../Async/Users/response_models';

export enum States {
  LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST',
  LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS',
  LOAD_USERS_ERROR = 'LOAD_USERS_ERROR',
}

export enum Events {
  LOAD_USER_LIST = 'LOAD_USER_LIST',
}

export type AutomataStates = {
  states: {
    [States.LOAD_USERS_REQUEST]: {};
    [States.LOAD_USERS_SUCCESS]: {};
    [States.LOAD_USERS_ERROR]: {};
  };
};

export type LoadUserData = { type: Events.LOAD_USER_LIST };
export type AutomataEvent = LoadUserData;

export type AutomataContext = {};

export const loadUserList = (c: AutomataContext, e: AutomataEvent) =>
  // Temp fix is setting it to 300, gotta make this dynamic or something
  Async.users.list(300, 0);

export const sendUserToStore = (c: AutomataContext, e: DoneInvokeEvent<UserListResponse>) =>
  Data.store.dispatch(Data.creators.users.saveUsersToStore(e.data.record_list));

export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'LoadUserList:Machine',
  initial: States.LOAD_USERS_REQUEST,
  context: {},
  states: {
    [States.LOAD_USERS_REQUEST]: {
      invoke: {
        src: 'loadUserList',
        onDone: {
          target: States.LOAD_USERS_SUCCESS,
          actions: ['sendUserToStore'],
        },
        onError: {
          target: States.LOAD_USERS_ERROR,
        },
      },
    },
    [States.LOAD_USERS_SUCCESS]: {},
    [States.LOAD_USERS_ERROR]: {
      on: {
        [Events.LOAD_USER_LIST]: {
          target: States.LOAD_USERS_REQUEST,
        },
      },
    },
  },
};

const options: any = {
  services: {
    loadUserList,
  },
  actions: {
    sendUserToStore,
  },
};

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export default Machine<AutomataContext, AutomataStates, AutomataEvent>(config, options);
