import * as React from 'react';

type Props = {
  className?: string;
};
export const TickIcon = ({ className }: Props) => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7806 0.560692C12.5631 0.568192 12.3634 0.680692 12.2483 0.860692L6.08493 10.0707L2.09207 6.70069C1.92058 6.52694 1.66335 6.46194 1.4266 6.53444C1.19112 6.60569 1.01579 6.80069 0.974841 7.03819C0.933888 7.27694 1.03243 7.51694 1.23207 7.66069L5.79826 11.5207C5.9416 11.6394 6.131 11.6919 6.31657 11.6657C6.50341 11.6382 6.6685 11.5344 6.77088 11.3807L13.354 1.56069C13.4948 1.35944 13.5063 1.09944 13.3847 0.886942C13.2631 0.675692 13.0289 0.549442 12.7806 0.560692Z"
      fill="#3EC07A"
    />
  </svg>
);

export default TickIcon;
