import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import store from './Data/store';
import { State } from './Data/@records';

type Dispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<Dispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export default {
  useAppDispatch,
  useAppSelector,
};
