import * as React from 'react';
import { Send, Events, CurrentState, States } from '../../../../../../../Machines/organisations/update';
import { UserResponse, BranchResponse } from '../../../../../../../Async/Organisations/response_models';

import User from './User';
import Empty from './Empty';

export const OpenAddUserModal = (send: Send) => (e: React.MouseEvent<HTMLButtonElement>) => send(Events.ADD_USER_TO_BRANCH);

type Props = {
  users: Array<UserResponse>;
  send: Send;
  branch: BranchResponse;
};

export default ({ users, send, branch }: Props) => (
  <>{branch.users.length === 0 ? <Empty /> : users.map((u) => <User send={send} user={u} key={u.identifier} branch={branch} />)}</>
);
