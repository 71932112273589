import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Data from '../../../Data';

import Header from '../../Header';
import Footer from '../../Footer';

import Looks from './Looks';
import Users from './Users';
import Settings from './Settings';
import Sections from './Sections';
import Organisations from './Organisations';

export default () => (
  <Route path={Data.paths.admin.BASE}>
    <Header />
    <div className="font-sans antialiased flex">
      <Switch>
        <Route path={Data.paths.admin.users.BASE}>
          <Sections />
          <Users />
        </Route>

        <Route path={Data.paths.admin.SETTINGS}>
          <Sections />
          <Settings />
        </Route>

        <Route path={Data.paths.admin.looks.BASE}>
          <Sections />
          <Looks />
        </Route>

        <Route path={Data.paths.admin.organisations.BASE}>
          <Sections />
          <Organisations />
        </Route>
      </Switch>
    </div>
    <Footer />
  </Route>
);
