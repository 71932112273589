import actions from './actions';
import { AnyAction } from 'redux';
import { LookResponse } from '../../Async/Looks/response_models';

export type SaveLookToStore = AnyAction & { look: LookResponse };
export const saveLookToStore = (look: LookResponse): SaveLookToStore => ({
  type: actions.SAVE_LOOK_DATA_TO_STORE,
  look: look,
});

export type SaveLooksToStore = AnyAction & { looks: Array<LookResponse> };
export const saveLooksToStore = (looks: Array<LookResponse>): SaveLooksToStore => ({
  type: actions.SAVE_LOOKS_DATA_TO_STORE,
  looks: looks,
});

export default {
  saveLookToStore,
  saveLooksToStore,
};
