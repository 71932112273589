import { State } from './@records';
import { AnyAction } from 'redux';
import { SaveLoginDataToStore, SaveScopeDataToStore } from './@types';

import actions from './actions';

export const saveLoginDataToStore = (state: State, action: SaveLoginDataToStore): State =>
  state.setIn(['credentials', 'token'], action.token).setIn(['credentials', 'scopes'], action.scopes);

export const saveScopeDataToStore = (state: State, action: SaveScopeDataToStore): State =>
  state.setIn(['scopes'], action.scopes).setIn(['credentials', 'isAuthenticated'], true);

export const successfullyLoggedOut = (state: State, action: SaveScopeDataToStore): State => State();

export default (state: State = State(), action: AnyAction): State => {
  switch (action.type) {
    case actions.SAVE_LOGIN_DATA_TO_STORE:
      return saveLoginDataToStore(state, action as any);

    case actions.SAVE_SCOPE_DATA_TO_STORE:
      return saveScopeDataToStore(state, action as any);

    case actions.SUCCESSFULLY_LOGGED_OUT:
      return successfullyLoggedOut(state, action as any);

    default:
      return state;
  }
};
