import axios, { AxiosResponse } from 'axios';

import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { NoContentResponse } from '../responses';

export const validateToken = (token: string): Promise<AxiosResponse<NoContentResponse>> =>
  axios.post(Endpoints.authentication.VALIDATE_TOKEN(), { token });

export default async (token: string) => await validateToken(token).then(getDataFromAxios.get);
