import React, { useEffect } from 'react';

import { withRouter } from 'react-router';

import Topbar from '../Topbar';
import Form from './Form';
import Loading from './Loading';

import Branches from './Branches';

import machine, { States, Events } from '../../../../../Machines/users/update';
import { UUID } from 'io-ts-types/lib/UUID';
import { useMachine } from '@xstate/react';

export const Main = () => {
  const [current, send] = useMachine(machine);

  useEffect(() => {
    send(Events.REFRESH);
  }, [window.location.pathname]);

  return (
    <div className="flex-1 flex flex-col">
      <Topbar />

      {current.matches(States.GET_USER_ID_FROM_LOCATION_PROPS) && <Loading />}

      {current.matches(States.GET_USER_FROM_STORE) && <Loading />}

      {current.matches(States.UPDATE_USER_SCREEN) && (
        <div>
          <Form isDisabled={false} send={send} context={current.context} />
          <Branches userId={current.context.userId as UUID} />
        </div>
      )}

      {current.matches(States.UPDATE_USER_REQUEST) && (
        <div>
          <Form isDisabled={true} send={send} context={current.context} />
          <Branches userId={current.context.userId as UUID} />
        </div>
      )}

      {current.matches(States.UPDATE_USER_SUCCESS) && (
        <div>
          <Form isDisabled={false} send={send} context={current.context} />
          <Branches userId={current.context.userId as UUID} />
        </div>
      )}
    </div>
  );
};

export default withRouter(Main);
