import authorisation from './authorisation';
import login from './login';
import reset_password from './reset_password';
import verify_token from './verify_token';
import change_password from '../Users/change_password';

export default {
  login,
  authorisation,
  reset_password,
  verify_token,
  change_password,
};
