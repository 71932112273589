import * as React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

type Props = {
  className?: string;
  title: string;
  to: string;
  children: JSX.Element;
};

export const FeatureSidebarItem = (props: Props) => {
  const [isSelected, setIsSelected] = React.useState<boolean>(false);

  const wrapperClass = classNames(
    {
      'font-liven-primary flex flow-row md:flex-col items-center w-full text-sm \
		 text-center text-gray-500 border-l-0 md:border-l-2 border-solid py-2 md:mb-3 cursor-pointer':
        true,
    },
    'border-b md:border-b-0 pb-6 md:pb-0 mb-3 md:mb-0',
  );

  const iconWrapperClass = classNames({
    'p-2 md:p-0 rounded-lg': true,
    'bg-liven-red-300 md:bg-transparent': isSelected,
  });

  const activeClassName = 'md:border-liven-red text-liven-red';
  return (
    <NavLink
      isActive={(match, location) => {
        if (!match) {
          setIsSelected(false);
          return false;
        }
        setIsSelected(true);
        return true;
      }}
      className={wrapperClass}
      activeClassName={activeClassName}
      to={props.to}
      exact
    >
      <div className={iconWrapperClass}>{props.children}</div>
      <div className={'mt-1 font-normal ml-4 md:ml-0'}>{props.title}</div>
    </NavLink>
  );
};

export default FeatureSidebarItem;
