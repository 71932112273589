import * as React from 'react';
import { AutomataService, Events } from '../../../../../../Machines/users/listBranches';
import SearchResult from './SearchResult';

const EnterKeyPressed = (service: AutomataService) => (e: React.KeyboardEvent<HTMLInputElement>) =>
  e.key === 'Enter' && service.send(Events.SEARCH_BRANCHES);

type Props = {
  service: AutomataService;
};

export default ({ service }: Props) => (
  <div id="modal" className="w-screen h-screen absolute top-0 left-0 z-10">
    <div onClick={() => service.send(Events.CLOSE_MODAL)} className="w-screen h-screen fixed pin bg-black opacity-75" />
    <div className="pin flex items-center left-0 top-0">
      <div className="relative mx-6 md:mx-auto w-full md:w-1/2 lg:w-3/5 xl:w-2/5 z-20 m-8">
        <div className="shadow-lg bg-white rounded-lg p-8">
          <div className="flex justify-end mb-6">
            <button onClick={() => service.send(Events.CLOSE_MODAL)}>
              <span className="mr-2">Close</span>
              <span>
                <i className="fa fa-times" />
              </span>
            </button>
          </div>

          <h1 className="text-center text-2xl text-green-dark">Add Branch</h1>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="email">
              Search
            </label>
            <input
              className="text-input"
              autoFocus={true}
              type="text"
              value={service.state.context.search.term}
              onKeyPress={EnterKeyPressed(service)}
              onChange={(e) =>
                service.send({
                  type: Events.SEARCH_TERM_UPDATED,
                  term: e.target.value,
                })
              }
              placeholder="Enter branch name"
            />
          </div>

          <div className="block md:flex items-center justify-center">
            <button
              type="button"
              onClick={() => service.send(Events.SEARCH_BRANCHES)}
              className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-green-200 hover:bg-green-500 transition-250 hover:text-white"
            >
              Search
            </button>
          </div>

          <div className="mt-4">
            {service.state.context.search.record_list.length !== 0 && <div>{service.state.context.search.record_list.length} results</div>}

            {service.state.context.search.record_list.map((branch) => (
              <SearchResult service={service} key={branch.identifier} branch={branch} />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);
