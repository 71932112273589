import { OrderedMap, merge } from 'immutable';
import actions from './actions';
import { State } from './@records';
import { UserRecord } from './@records/User';
import { AnyAction } from 'redux';
import { SaveUsersToStore } from './@types';
import { UUID } from '../../@types';
import { AddUserToStore } from './creators';

export const saveUserDataToStore = (state: State, action: SaveUsersToStore): State => {
  const results = action.users.reduce((acc, user) => acc.set(user.identifier, UserRecord(user)), OrderedMap<UUID, UserRecord>());

  const sortedRecords = merge(state.users, results).sort((u1, u2) => (u1.details.first_name < u2.details.first_name ? -1 : 1));

  return state.set('users', sortedRecords);
};

export const addUserToStore = (state: State, action: AddUserToStore): State =>
  state.setIn(['users', action.user.identifier], UserRecord(action.user));

export default (state: State = State(), action: AnyAction): State => {
  switch (action.type) {
    case actions.SAVE_USER_DATA_TO_STORE:
      return saveUserDataToStore(state, action as any);

    case actions.ADD_USER_DATA_TO_STORE:
      return addUserToStore(state, action as any);

    default:
      return state;
  }
};
