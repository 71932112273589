import { Record, RecordOf, OrderedMap } from 'immutable';
import { UserUUID } from '../@types';
import { UserRecord } from './User';
import { ProfileRecord } from './Profile';

export type State_ = {
  users: OrderedMap<UserUUID, UserRecord>;
  profile: ProfileRecord;
};

const defaultValues: State_ = {
  users: OrderedMap<UserUUID, UserRecord>(),
  profile: ProfileRecord(),
};

export type State = RecordOf<State_>;
export const State: Record.Factory<State_> = Record(defaultValues);
