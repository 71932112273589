import * as React from 'react';
import { State } from 'xstate';
import { AutomataContext, AutomataEvent } from '../../../../../../Machines/organisations/search';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import { State as StoreState } from '../../../../../../Data/@records';
import Organisation from './Organisation';
import { OrganisationRecord } from '../../../../../../Data/organisations/@records/Organisation';

type Props = {
  current: State<AutomataContext, AutomataEvent>;
};
export default ({ current }: Props) => {
  const organisations = useSelector<StoreState, List<OrganisationRecord>>((state) =>
    state.organisations.organisations.toList().sort((u1, u2) => (u1.name < u2.name ? -1 : 1)),
  );

  return (
    <div className="mb-2">
      {organisations.map((o) => (
        <Organisation organisation={o} />
      ))}
    </div>
  );
};
