import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Data from '../../Data';
import Authenticated from './Authenticated';

import Authentication from './Authentication';
import Docs from './Docs';
import Admin from './Admin';
import Merchant from './Merchant';

export default () => (
  <Switch>
    {/* Homepage */}
    <Route exact path={Data.paths.HOME}>
      <Redirect to={Data.paths.authentication.LOGIN} />
    </Route>

    {/* Documentation */}
    <Route exact path={Data.paths.documentation.BASE}>
      <Docs />
    </Route>

    {/* Authentication */}
    <Route path={Data.paths.authentication.BASE}>
      <Authentication />
    </Route>

    {/* Administration */}
    <Route path={Data.paths.admin.BASE}>
      <Authenticated Route={<Admin />} />
    </Route>

    {/* Merchant */}
    <Route path={Data.paths.merchant.BASE}>
      <Authenticated Route={<Merchant />} />
    </Route>
  </Switch>
);
