/**
 * Validate email address format
 *
 * @param {String} base
 *
 * @return {Boolean}
 */
export const validateEmail = (base: string) => {
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]/;
  return emailPattern.test(base);
};
