import * as React from 'react';
import { State } from 'xstate';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

import { AutomataContext, AutomataEvent } from '../../../../../../Machines/looks/search';
import { State as StoreState } from '../../../../../../Data/@records';
import { LookRecord } from '../../../../../../Data/looks/@records/Look';

import Look from './Look';

type Props = {
  current: State<AutomataContext, AutomataEvent>;
};
export default ({ current }: Props) => {
  const looks = useSelector<StoreState, List<LookRecord>>((state) =>
    state.looks.looks
      .filter(
        (r) =>
          r.name.toLowerCase().includes(current.context.term.toLowerCase()) ||
          r.description.toLowerCase().includes(current.context.term.toLowerCase()) ||
          r.link.toLowerCase().includes(current.context.term.toLowerCase()),
      )
      .toList(),
  );

  return (
    <div className="mb-2">
      {looks.map((r) => (
        <Look record={r} key={r.identifier} />
      ))}
    </div>
  );
};
