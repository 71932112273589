import { Record, RecordOf } from 'immutable';
import { Scopes } from '.';
import { Roles } from '../../../Async/Authentication/response_models';

export type TAuthentication = {
  isAuthenticated: boolean;
  token: string;
  role: Roles | null;
  scopes: Array<Scopes>;
};

const defaultValues: TAuthentication = {
  isAuthenticated: false,
  token: '',
  role: 'MERCHANT',
  scopes: [],
};

export type Authentication = RecordOf<TAuthentication>;
export const Authentication: Record.Factory<TAuthentication> = Record(defaultValues);
