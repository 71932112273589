import * as React from 'react';
import { useMachine } from '@xstate/react/lib';
import userSearchMachine, { Events as UserSearchEvents, Send as UserSearchSend } from '../../../../../../../Machines/users/search';
import Filtered from './Filtered';
import All from './All';
import { BranchRecord } from '../../../../../../../Data/organisations/@records/Branch';

const EnterData = (send: UserSearchSend) => (e: React.ChangeEvent<HTMLInputElement>) =>
  send({
    type: UserSearchEvents.ENTER_DATA,
    term: e.target.value,
  });

const SubmitData = (send: UserSearchSend) => (e: React.MouseEvent<HTMLButtonElement>) => send(UserSearchEvents.DATA_SUBMITTED);

type Props = {
  branch: BranchRecord;
  toggleModal: (...args: any) => void;
  reloadDetails: (...args: any) => void;
};

export default ({ branch, toggleModal, reloadDetails }: Props) => {
  const [userCurrent, userSend] = useMachine(userSearchMachine, {
    devTools: true,
  });

  const toggleModalAndReload = (e: React.MouseEvent) => {
    toggleModal(false);
    reloadDetails();
  };

  return (
    <div id="modal" className="w-screen h-screen absolute top-0 left-0 z-10">
      <div onClick={toggleModalAndReload} className="w-screen h-screen fixed pin bg-black opacity-75" />
      <div className="pin flex items-center left-0 top-0">
        <div className="relative mx-6 md:mx-auto w-full md:w-1/2 lg:w-3/5 xl:w-2/5 z-20 m-8">
          <div className="shadow-lg bg-white rounded-lg p-8">
            <div className="flex justify-end mb-6">
              <button onClick={toggleModalAndReload}>
                <span className="mr-2">Close</span>
                <span>
                  <i className="fa fa-times" />
                </span>
              </button>
            </div>

            <h1 className="text-center text-2xl text-green-dark">Add User</h1>

            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="email">
                Search
              </label>
              <input
                className="text-input"
                autoFocus={true}
                type="text"
                value={userCurrent.context.term}
                onChange={EnterData(userSend)}
                placeholder="Search UserDetailResponse"
              />
            </div>

            <div className="block md:flex items-center justify-center">
              <button
                type="button"
                onClick={SubmitData(userSend)}
                className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-green-200 hover:bg-green-500 transition-250 hover:text-white"
              >
                Search
              </button>
            </div>

            <div className="mt-4">
              {userCurrent.context.term === '' && <All branch={branch} />}
              {userCurrent.context.term !== '' && <Filtered current={userCurrent} branch={branch} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
