import * as React from 'react';
import { AutomataService, States } from '../../../../../Machines/users/listUsers';
import { useSelector } from 'react-redux';
import { State } from '../../../../../Data/@records';
import { List } from 'immutable';
import { UserRecord } from '../../../../../Data/users/@records/User';
import { useService } from '@xstate/react/lib';

type Props = {
  service: AutomataService;
};
export default ({ service }: Props) => {
  const [current, send] = useService(service);
  const users = useSelector<State, List<UserRecord>>((state) => state.users.users.toList());
  return (
    <div className="flex-1 flex flex-col bg-white">
      {/* Details Panel */}
      <div className="p-2">
        {current.matches(States.LOAD_USERS_REQUEST) && <div>Loading</div>}
        {current.matches(States.LOAD_USERS_SUCCESS) && (
          <div>
            {users.map((u) => (
              <div key={u.identifier}>
                {u.details.first_name} {u.details.last_name}
              </div>
            ))}
          </div>
        )}
        {current.matches(States.LOAD_USERS_ERROR) && <div>Error</div>}
      </div>
    </div>
  );
};
