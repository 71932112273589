import { combineReducers } from 'redux-immutable';

import authentication from './authentication/reducers';
import organisations from './organisations/reducers';
import users from './users/reducers';
import looks from './looks/reducers';

export default combineReducers({
  authentication,
  organisations,
  users,
  looks,
});
