import * as React from 'react';

import { Events, CurrentState, Send } from '../../../../../../Machines/organisations/create';

export const updateOrganisationName = (send: Send) => (e: React.ChangeEvent<HTMLInputElement>) =>
  send({
    type: Events.ENTER_ORGANISATION_NAME,
    value: e.target.value,
  });

type Props = {
  isDisabled: boolean;
  current: CurrentState;
  send: Send;
};

export default ({ isDisabled, current, send }: Props) => (
  <div>
    <input
      value={current.context.formData.name}
      onChange={updateOrganisationName(send)}
      className="text-input"
      type="text"
      placeholder="Enter Organisation name"
      disabled={isDisabled}
    />

    <hr />

    <button disabled={isDisabled} onClick={() => send(Events.SUBMIT_DATA)} className="btn" type="button">
      Create Organisation
    </button>
  </div>
);
