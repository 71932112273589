import creators from './creators';
import paths from './paths';
import store from './store';
import async from './async';

export default {
  creators,
  paths,
  store,
  async,
};
