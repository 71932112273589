import authentication from './Authentication';
import branches from './Branches';
import endpoints from './endpoints';
import users from './Users';
import looks from './Looks';
import organisations from './Organisations';
import settings from './Settings';
import transact from './Transact';

export default {
  authentication,
  branches,
  endpoints,
  users,
  looks,
  organisations,
  settings,
  transact,
};
