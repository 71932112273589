import * as React from 'react';
import { AutomataContext, AutomataEvent, AutomataService, Events } from '../../../../../../Machines/users/search';
import { State } from 'xstate';

const EnterData = (service: AutomataService) => (e: React.ChangeEvent<HTMLInputElement>) =>
  service.send({
    type: Events.ENTER_DATA,
    term: e.target.value,
  });

const KeyPressed = (service: AutomataService) => (e: React.KeyboardEvent<HTMLInputElement>) =>
  service.send({
    type: Events.KEY_PRESSED,
    key: e.key,
  });

type Props = {
  service: AutomataService;
  current: State<AutomataContext, AutomataEvent>;
};
export default ({ service, current }: Props) => (
  <input
    type="search"
    onKeyPress={KeyPressed(service)}
    onChange={EnterData(service)}
    value={current.context.term}
    placeholder="Search"
    disabled={false}
    className="text-input"
  />
);
