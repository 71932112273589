import * as t from 'io-ts';

// Directly pulled from the docs: https://github.com/gcanti/io-ts#generic-types
export const ListResponse = <C extends t.Mixed>(codec: C) =>
  t.type({
    page_size: t.number,
    current_page: t.number,
    record_size: t.number,
    record_list: t.array(codec),
  });
