import { Record, RecordOf } from 'immutable';
import { UUID } from '../../../@types';
import * as t from 'io-ts';

export type BranchId = UUID;

type Branch = {
  identifier: BranchId;
  name: string;
  branch_id: string | null;
  users: Array<{
    identifier: string;
    email: string;
    details: {
      first_name: string;
      last_name: string | null;
    };
  }>;
};
const defaultValues: Branch = {
  identifier: '',
  branch_id: '',
  name: '',
  users: [],
};

export type BranchRecord = RecordOf<Branch>;
export const BranchRecord: Record.Factory<Branch> = Record(defaultValues);
