import * as React from 'react';

export default () => (
  <tr>
    <td />
    <td>No Users assigned to this branch</td>
    <td>
      <button
        type="button"
        disabled={true}
        className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-gray-400 duration-100 hover:text-white"
      >
        No Actions
      </button>
    </td>
  </tr>
);
