import { Machine, Interpreter, MachineConfig, assign, DoneInvokeEvent, State } from 'xstate';
import { unsafeDeleteAt } from 'fp-ts/lib/Array';
import { Lens } from 'monocle-ts';

import Data from '../../Data';
import Async from '../../Async';
import History from '../../history';

import { CreateOrganisationRequest } from '../../Async/Organisations/create';
import { DetailOrganisationResponse } from '../../Async/Organisations/response_models';

// States

export enum States {
  CREATE_ORGANISATION_SCREEN = 'CREATE_ORGANISATION_SCREEN',
  CREATE_ORGANISATION_REQUEST = 'CREATE_ORGANISATION_REQUEST',
  CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS',
  CREATE_ORGANISATION_ERROR = 'CREATE_ORGANISATION_ERROR',
}

export type AutomataStates = {
  states: {
    [States.CREATE_ORGANISATION_SCREEN]: {};
    [States.CREATE_ORGANISATION_REQUEST]: {};
    [States.CREATE_ORGANISATION_SUCCESS]: {};
    [States.CREATE_ORGANISATION_ERROR]: {};
  };
};

// Context

export type AutomataContext = {
  formData: CreateOrganisationRequest;
};

// Events

export enum Events {
  SUBMIT_DATA = 'SUBMIT_DATA',
  ENTER_ORGANISATION_NAME = 'ENTER_ORGANISATION_NAME',
  ADD_BRANCH = 'ADD_BRANCH',
  REMOVE_BRANCH = 'REMOVE_BRANCH',
}

export type SubmitData = {
  type: Events.SUBMIT_DATA;
};
export type EnterOrganisationName = {
  type: Events.ENTER_ORGANISATION_NAME;
  value: string;
};
export type AddBranch = {
  type: Events.ADD_BRANCH;
  name: string;
};
export type RemoveBranch = {
  type: Events.REMOVE_BRANCH;
  index: number;
};

export type AutomataEvent = SubmitData | EnterOrganisationName | AddBranch | RemoveBranch;

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

// Services

export const create = (c: AutomataContext, e: AutomataEvent) => Async.organisations.create(c.formData);

// Actions

export const EnterOrganisationName = assign((c: AutomataContext, e: EnterOrganisationName) =>
  Lens.fromPath<AutomataContext>()(['formData', 'name']).set(e.value)(c),
);

export const AddBranch = assign((c: AutomataContext, e: AddBranch) => {
  const branches = c.formData.branches.concat([e.name]);
  return Lens.fromPath<AutomataContext>()(['formData', 'branches']).set(branches)(c);
});

export const RemoveBranch = assign((c: AutomataContext, e: RemoveBranch) => {
  const branches = unsafeDeleteAt(e.index, c.formData.branches);
  return Lens.fromPath<AutomataContext>()(['formData', 'branches']).set(branches)(c);
});

export const AddOrganisationToStore = (c: AutomataContext, e: DoneInvokeEvent<DetailOrganisationResponse>) =>
  Data.store.dispatch(Data.creators.organisation.saveOrganisationToStore(e.data.record));

export const RedirectToDetailPage = (c: AutomataContext, e: DoneInvokeEvent<DetailOrganisationResponse>) =>
  History.push(Data.paths.admin.organisations.TO_DETAIL(e.data.identifier));

// Config

export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'Machine:Organisation:Create',
  initial: States.CREATE_ORGANISATION_SCREEN,
  context: {
    formData: {
      name: '',
      branches: [],
    },
  },
  states: {
    [States.CREATE_ORGANISATION_SCREEN]: {
      on: {
        [Events.ENTER_ORGANISATION_NAME]: {
          actions: ['EnterOrganisationName'],
        },
        [Events.ADD_BRANCH]: {
          actions: ['AddBranch'],
        },
        [Events.REMOVE_BRANCH]: {
          actions: ['RemoveBranch'],
        },
        [Events.SUBMIT_DATA]: {
          target: States.CREATE_ORGANISATION_REQUEST,
        },
      },
    },
    [States.CREATE_ORGANISATION_REQUEST]: {
      invoke: {
        src: 'create',
        onDone: {
          target: States.CREATE_ORGANISATION_SUCCESS,
          actions: ['AddOrganisationToStore', 'RedirectToDetailPage'],
        },
        onError: {
          target: States.CREATE_ORGANISATION_ERROR,
          actions: [],
        },
      },
    },
    [States.CREATE_ORGANISATION_SUCCESS]: {
      type: 'final',
      entry: [],
    },
    [States.CREATE_ORGANISATION_ERROR]: {
      on: {},
    },
  },
};

export const options: any = {
  services: {
    create,
  },
  actions: {
    EnterOrganisationName,
    AddBranch,
    RemoveBranch,
    AddOrganisationToStore,
    RedirectToDetailPage,
  },
};

export default Machine(config, options);
