import React from 'react';

import ChangePassword from './ChangePassword';

export default () => (
  <div className="flex-grow px-16 py-16 bg-gray-100">
    <h1 className="text-2xl">Account</h1>
    <ChangePassword />
  </div>
);
