import * as t from 'io-ts';
import { DetailResponse, ListResponse } from '../generics';

export const SettingDetails = t.type({
  identifier: t.string,
  name: t.string,
  value: t.string,
});

export const ListSettingResponse = ListResponse(SettingDetails);
export const UpdateSettingResponse = DetailResponse(SettingDetails);

export type SettingDetails = t.TypeOf<typeof SettingDetails>;
export type ListSettingResponse = t.TypeOf<typeof ListSettingResponse>;
export type UpdateSettingResponse = t.TypeOf<typeof UpdateSettingResponse>;
