import * as React from 'react';
import classNames from 'classnames';

import { BranchWithLooksDetails } from '../../../../../../Async/Looks/get_for_user';
import paths from '../../../../../../Data/paths';
import { Link } from 'react-router-dom';
import { TransactActivatedBranch } from '../../../../../../Async/Transact/response_models';

export type Indexable<A> = {
  [index: number]: A;
  length: number;
};

export const safePop: <A>(a: Indexable<A>, n: number, fallback: A) => A = (a, n, fallback) => (a.length > n ? a[n] : fallback);

export const getInitials = (orgName: string) => {
  const names: Array<string> = orgName.split(' ');
  return safePop(safePop(names, 0, ''), 0, '') + safePop(safePop(names, 1, ''), 0, '');
};

export type OrgCardProps = {
  isCollapsed: boolean;
  orgName: string;
  orgAddress?: string;
  orgThumbnailUrl?: string;
  branch: TransactActivatedBranch;
  isSelected: boolean;
  onClick: () => void;
};
export default ({
  isCollapsed = false,
  orgName,
  orgAddress,
  orgThumbnailUrl = 'https://www.force/break/stuff.yew',
  branch,
  isSelected,
  onClick = () => {},
}: OrgCardProps) => {
  const wrapperClass = classNames({
    'w-full px-4 py-2 my-4 rounded-large font-liven-primary': true,
    'bg-gray-300': isSelected && !isCollapsed,
    'cursor-pointer': !isSelected,
  });
  const containerClass = classNames({
    'flex items-center min-h-12': true,
    'flex-col font-bold': isCollapsed,
  });
  const thumbnailClass = classNames({
    'w-10 h-10 p-px border border-gray-500 border-solid rounded-full \
        min-h-10 min-w-10 font-normal text-center bg-gray-400 leading-9 uppercase':
      true,
    'p-0 font-bold border-2 border-gray-700': isSelected,
    'mb-2': isCollapsed,
  });
  const cardDetailsClass = classNames({
    'flex flex-col overflow-hidden whitespace-no-wrap': true,
    'ml-4': !isCollapsed,
    'm-0 font-bold': isCollapsed,
  });
  const orgNameClass = classNames({
    'text-xs ': true,
    'text-gray-600': !isSelected,
    'font-bold text-black': isSelected,
    'text-xs text-center whitespace-normal': isCollapsed,
  });
  const orgAddressClass = classNames({
    'text-xs text-gray-600': true,
    hidden: isCollapsed,
  });

  return (
    <Link className={wrapperClass} to={paths.merchant.transact.TO_VIEW_TEAM(branch.identifier)}>
      <div className={containerClass}>
        {/* Initials */}
        <object className={thumbnailClass} type="image/png" data={orgThumbnailUrl}>
          {getInitials(orgName)}
        </object>

        {/* Card Details  */}
        <div className={cardDetailsClass}>
          <div className={orgNameClass}>{orgName}</div>
          <div className={orgAddressClass}>{orgAddress}</div>
        </div>
      </div>
    </Link>
  );
};
