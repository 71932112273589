import authentication from './authentication/creators';
import users from './users/creators';
import organisation from './organisations/creators';
import looks from './looks/creators';

export default {
  authentication,
  users,
  organisation,
  looks,
};
