import React from 'react';
import { Switch, Route } from 'react-router';

import Data from '../../../../Data';
import List from './List';
import Create from './Create';
import Detail from './Detail';
import Sidebar from './Sidebar';

export default () => (
  <div className="font-sans antialiased flex flex-grow">
    <Sidebar />
    <Switch>
      <Route exact path={Data.paths.admin.users.BASE}>
        <List />
      </Route>

      <Route exact path={Data.paths.admin.users.CREATE}>
        <Create />
      </Route>

      <Route exact path={`${Data.paths.admin.users.DETAIL_ROUTE}`}>
        <Detail />
      </Route>
    </Switch>
  </div>
);
