import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { UserUUID } from '../../Data/users/@types';
import { UserDetailResponse } from './response_models';

export const get = (identifier: UserUUID): Promise<AxiosResponse<UserDetailResponse>> =>
  axios.get(Endpoints.users.DETAILS(identifier), Data.async.authorisationHeaders());

export default async (identifier: UserUUID) => await get(identifier).then(getDataFromAxios.get).then(tp.decode(UserDetailResponse));
