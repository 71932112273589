import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router';
import { useMachine } from '@xstate/react/lib';

import machine from '../../../Machines/looks/listLooksForUser';
import Data from '../../../Data';
import Account from './Pages/Account';
import FeatureSidebar from './Sidebar/FeatureSidebar';
import DashboardSidebar from './Sidebar/OrgSidebar/Dashboard';
import TransactSidebar from './Sidebar/OrgSidebar/Transact';
import DashboardPage from './Pages/Dashboard';
import Transact from './Pages/Transact';
import Logout from './Logout';
import paths from '../../../Data/paths';

export default () => {
  const [current, send, service] = useMachine(machine, { devTools: true });
  const userInitials = current.context.userInitials;
  const fullName = current.context.fullName;

  useEffect((): (() => void) => {
    // @ts-ignore
    window.Intercom('boot', {
      // @ts-ignore
      app_id: INTERCOM_APP_ID,
    });
    // @ts-ignore
    return () => window.Intercom('shutdown');
  }, [window.location.pathname.includes('merchant')]);

  const BranchSiderbar = () => (
    <Switch>
      <Route exact path={paths.merchant.BASE}>
        <DashboardSidebar state={current} service={service} current={current} />
      </Route>
      <Route path={paths.merchant.TRANSACT}>
        <TransactSidebar />
      </Route>
    </Switch>
  );

  return (
    <div className={'flex flex-col md:flex-row bg-white font-liven-primary'}>
      {/* Sidebar */}
      <div className={'flex justify-between py-2 md:p-0 md:h-screen'}>
        <FeatureSidebar userInitials={userInitials} fullName={fullName} />
        <BranchSiderbar />
      </div>

      {/* Active Route */}
      <div className={'md:h-screen overflow-y-auto w-full'}>
        <div className="bg-white font-liven-primary overflow-auto">
          <div className={'md:px-16 px-5 md:py-8 py-5 md:mb-10 md:pb-4'}>
            <Logout />
            <Switch>
              <Route exact path={Data.paths.merchant.BASE}>
                <DashboardPage current={current} send={send} />
              </Route>

              <Route exact path={Data.paths.merchant.ACCOUNT}>
                <Account />
              </Route>

              <Route path={Data.paths.merchant.TRANSACT}>
                <Transact />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};
