import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { DetailedLookResponse } from './response_models';

export const getLook = (identifier: string): Promise<AxiosResponse<DetailedLookResponse>> =>
  axios.get(Endpoints.looks.DETAIL(identifier), Data.async.authorisationHeaders());

export default async (identifier: string) => await getLook(identifier).then(getDataFromAxios.get).then(tp.decode(DetailedLookResponse));
