import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { DetailOrganisationResponse } from './response_models';

export const RemoveLookFromBranchRequest = t.exact(
  t.type({
    branchId: t.string,
    lookId: t.string,
  }),
);

export type RemoveLookFromBranchRequest = t.TypeOf<typeof RemoveLookFromBranchRequest>;

export const removeLookFromBranch = (request: RemoveLookFromBranchRequest): Promise<AxiosResponse<DetailOrganisationResponse>> =>
  axios.delete(Endpoints.organisations.LOOK_BRANCH(), {
    data: {
      lookId: request.lookId,
      branchId: request.branchId,
    },
    headers: {
      'X-TOKEN': Data.store.getState().authentication.credentials.token,
    },
  });

export default async (request: RemoveLookFromBranchRequest) =>
  await removeLookFromBranch(request).then(getDataFromAxios.get).then(tp.decode(DetailOrganisationResponse));
