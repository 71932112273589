import React from 'react';
import Endpoints from '../../../Async/endpoints';
import Header from '../../Header';

export default () => (
  <div>
    <Header />
    <iframe src={Endpoints.documentation.SWAGGER} className="w-full documentation-offset" />
  </div>
);
