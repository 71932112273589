import { AxiosResponse } from 'axios';
import { Machine, MachineConfig, Interpreter, assign, DoneInvokeEvent, State } from 'xstate';
import { Lens } from 'monocle-ts';
import { setItem, getItem, removeItem } from 'fp-ts-local-storage';
import { fold } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

import Data from '../../Data';
import Async from '../../Async';

import constants from './constants';
import History from '../../history';
import { Scopes } from '../../Data/authentication/@records';
import { SuccessfulLogin } from '../../View/Routes/Authentication/Login/@types';
import { AuthorisationResponse, LoginResponse, Roles } from '../../Async/Authentication/response_models';
import { AutomataStates } from '../organisations/update';

// States
export enum States {
  EXISTING_TOKEN_CHECK = 'EXISTING_TOKEN_CHECK',
  EXISTING_TOKEN_REQUEST = 'EXISTING_TOKEN_REQUEST',

  LOGIN_VIEW = 'LOGIN_VIEW',
  LOGIN_SUBMIT = 'LOGIN_SUBMIT',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',

  ACCESS_SCOPES_REQUEST = 'DETERMINE_ACCESS_SCOPES',
  ACCESS_SCOPES_SUCCESS = 'ACCESS_SCOPES_SUCCESS',
  ACCESS_SCOPES_ERROR = 'ACCESS_SCOPES_ERROR',

  MOVE_TO_ADMIN_SECTION = 'MOVE_TO_ADMIN_SECTION',
  MOVE_TO_MERCHANT_SECTION = 'MOVE_TO_MERCHANT_SECTION',
}

export type AutomataStateSchema = {
  states: {
    [States.EXISTING_TOKEN_CHECK]: {};
    [States.EXISTING_TOKEN_REQUEST]: {};

    [States.LOGIN_VIEW]: {};
    [States.LOGIN_SUBMIT]: {};
    [States.LOGIN_ERROR]: {};
    [States.LOGIN_SUCCESS]: {};

    [States.ACCESS_SCOPES_REQUEST]: {};
    [States.ACCESS_SCOPES_SUCCESS]: {};
    [States.ACCESS_SCOPES_ERROR]: {};

    [States.MOVE_TO_ADMIN_SECTION]: {};
    [States.MOVE_TO_MERCHANT_SECTION]: {};
  };
};

// Events
export enum Events {
  SUBMIT_LOGIN_DATA = 'SUBMIT_LOGIN_DATA',
  SUCCESSFUL_LOGIN = 'SUCCESSFUL_LOGIN',
  TO_LOGIN_VIEW = 'TO_LOGIN_VIEW',
  ENTER_DATA = 'ENTER_DATA',
}

export type SubmitLoginData = {
  type: Events.SUBMIT_LOGIN_DATA;
};
export type ToLoginView = {
  type: Events.TO_LOGIN_VIEW;
};
export type EnterData = {
  type: Events.ENTER_DATA;
  value: string;
  field: keyof AutomataContext['login'];
};

export type SuccessfulAuthenticationRequest = {
  type: Events.SUCCESSFUL_LOGIN;
} & AxiosResponse<{
  data: SuccessfulLogin;
}>;

export type AutomataEvent = SubmitLoginData | ToLoginView | EnterData | SuccessfulAuthenticationRequest;

// Context
export type AutomataContext = {
  authentication: {
    oldToken: {
      exists: boolean;
      token: string;
    };
    role: Roles;
    token: string;
    scopes: Array<Scopes>;
  };
  login: {
    email: string;
    password: string;
  };
};

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

// Services

export const submitLoginData = (c: AutomataContext, e: AutomataEvent) => Async.authentication.login(c.login.email, c.login.password);

export const verifyToken = (c: AutomataContext, e: AutomataEvent) => Async.authentication.verify_token(c.authentication.oldToken.token);

export const getAuthorisationScopes = (c: AutomataContext, e: AutomataEvent) => Async.authentication.authorisation();

export const enterLoginData = assign({
  login: (c: AutomataContext, e: EnterData): any => Object.assign(c.login, { [e.field]: e.value }),
});

export const clearLoginData = assign({
  login: (c: AutomataContext, e: EnterData): any => Object.assign(c.login, { password: '', email: '' }),
});

// Actions

export const sendLoginDataToStore = (c: AutomataContext, e: DoneInvokeEvent<LoginResponse>): void => {
  const payload = Data.creators.authentication.saveLoginDataToStore(c.authentication.token, c.authentication.scopes);
  Data.store.dispatch(payload);
};

export const assignTokenToContext = assign(
  (c: AutomataContext, e: DoneInvokeEvent<LoginResponse>): AutomataContext =>
    Lens.fromPath<AutomataContext>()(['authentication', 'token']).set(e.data.token)(c),
);

export const assignExistingTokenToContext = assign(
  (c: AutomataContext, e: DoneInvokeEvent<LoginResponse>): AutomataContext =>
    Lens.fromPath<AutomataContext>()(['authentication', 'token']).set(c.authentication.oldToken.token)(c),
);

export const sendScopeDataToStore = (c: AutomataContext, e: DoneInvokeEvent<AuthorisationResponse>): void => {
  const payload = Data.creators.authentication.saveScopeDataToStore(e.data);
  Data.store.dispatch(payload);
};

export const assignRoleDataToContext = assign(
  (c: AutomataContext, e: DoneInvokeEvent<AuthorisationResponse>): AutomataContext =>
    Lens.fromPath<AutomataContext>()(['authentication', 'role']).set(e.data.role)(c),
);

export const redirectToAdminPage = (c: AutomataContext, e: DoneInvokeEvent<AuthorisationResponse>): void => History.push(Data.paths.admin.users.BASE);

export const redirectToMerchantPage = (c: AutomataContext, e: DoneInvokeEvent<AuthorisationResponse>): void => {
  const redirectTo = window.location.pathname.startsWith(Data.paths.merchant.BASE) ? window.location.pathname : Data.paths.merchant.BASE;
  History.push(redirectTo);
};

export const getTokenFromLocalStorage = assign(
  (c: AutomataContext, e): AutomataContext =>
    pipe(
      getItem(constants.TOKEN_LABEL)(),
      fold(
        () => c,
        (v) =>
          pipe(
            Lens.fromPath<AutomataContext>()(['authentication', 'oldToken', 'token']).set(v)(c),
            Lens.fromPath<AutomataContext>()(['authentication', 'oldToken', 'exists']).set(true),
          ),
      ),
    ),
);

export const removeTokenFromLocalStorage = (c: AutomataContext, e: Events) => removeItem(constants.TOKEN_LABEL)();

export const saveToLocalStorage = (c: AutomataContext, e: DoneInvokeEvent<AuthorisationResponse>) =>
  setItem(constants.TOKEN_LABEL, c.authentication.token)();

export const config: MachineConfig<AutomataContext, AutomataStateSchema, AutomataEvent> = {
  id: 'Login:Machine',
  initial: States.EXISTING_TOKEN_CHECK,
  context: {
    authentication: {
      oldToken: {
        exists: false,
        token: '',
      },
      role: 'MERCHANT',
      token: '',
      scopes: [],
    },
    login: {
      email: '',
      password: '',
    },
  },
  states: {
    [States.EXISTING_TOKEN_CHECK]: {
      entry: ['getTokenFromLocalStorage'],
      after: {
        500: [
          {
            cond: (c, e) => c.authentication.oldToken.exists === true,
            target: States.EXISTING_TOKEN_REQUEST,
          },
          {
            target: States.LOGIN_VIEW,
          },
        ],
      },
    },
    [States.EXISTING_TOKEN_REQUEST]: {
      invoke: {
        src: 'verifyToken',
        onDone: {
          target: States.ACCESS_SCOPES_REQUEST,
          actions: ['assignExistingTokenToContext', 'sendLoginDataToStore'],
        },
        onError: {
          target: States.LOGIN_VIEW,
          actions: ['removeTokenFromLocalStorage'],
        },
      },
    },
    [States.LOGIN_VIEW]: {
      on: {
        ENTER_DATA: {
          actions: ['enterLoginData'],
        },
        SUBMIT_LOGIN_DATA: {
          target: States.LOGIN_SUBMIT,
        },
      },
    },
    [States.LOGIN_SUBMIT]: {
      invoke: {
        src: 'submitLoginData',
        onDone: {
          target: States.LOGIN_SUCCESS,
          actions: ['clearLoginData', 'assignTokenToContext', 'sendLoginDataToStore'],
        },
        onError: {
          target: States.LOGIN_ERROR,
        },
      },
    },
    [States.LOGIN_SUCCESS]: {
      after: {
        500: {
          target: States.ACCESS_SCOPES_REQUEST,
        },
      },
    },
    [States.LOGIN_ERROR]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['enterLoginData'],
        },
        [Events.SUBMIT_LOGIN_DATA]: {
          target: States.LOGIN_SUBMIT,
        },
      },
    },

    [States.ACCESS_SCOPES_REQUEST]: {
      invoke: {
        src: 'getAuthorisationScopes',
        onDone: {
          target: States.ACCESS_SCOPES_SUCCESS,
          actions: ['assignRoleDataToContext', 'sendScopeDataToStore', 'saveToLocalStorage'],
        },
        onError: {
          target: States.ACCESS_SCOPES_ERROR,
        },
      },
    },

    [States.ACCESS_SCOPES_SUCCESS]: {
      on: {
        '': [
          {
            target: States.MOVE_TO_ADMIN_SECTION,
            cond: (c, e) => c.authentication.role === 'ADMINISTRATOR',
          },
          {
            target: States.MOVE_TO_ADMIN_SECTION,
            cond: (c, e) => c.authentication.role === 'STAFF',
          },
          {
            target: States.MOVE_TO_MERCHANT_SECTION,
          },
        ],
      },
    },

    [States.ACCESS_SCOPES_ERROR]: {},

    [States.MOVE_TO_ADMIN_SECTION]: {
      entry: ['redirectToAdminPage'],
      type: 'final',
    },
    [States.MOVE_TO_MERCHANT_SECTION]: {
      entry: ['redirectToMerchantPage'],
      type: 'final',
    },
  },
};

const options: any = {
  services: {
    submitLoginData,
    verifyToken,
    getAuthorisationScopes,
  },
  actions: {
    clearLoginData,
    enterLoginData,
    assignRoleDataToContext,
    assignTokenToContext,
    getTokenFromLocalStorage,
    assignExistingTokenToContext,
    removeTokenFromLocalStorage,
    saveToLocalStorage,
    sendLoginDataToStore,
    sendScopeDataToStore,
    redirectToAdminPage,
    redirectToMerchantPage,
  },
};

export default Machine<AutomataContext, AutomataStateSchema, AutomataEvent>(config, options);
