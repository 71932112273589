import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Data from '../../Data';
import { State } from '../../Data/@records';

const Login = () => (
  <Link to={Data.paths.authentication.LOGIN} className="btn-outline">
    Login
  </Link>
);

const Logout = () => (
  <Link to={Data.paths.authentication.LOGOUT} className="btn-outline">
    Logout
  </Link>
);

export default () => {
  const isAuthenticated = useSelector((state: State) => state.authentication.credentials.isAuthenticated);
  return isAuthenticated ? <Logout /> : <Login />;
};
