import * as t from 'io-ts';
import { DetailResponse, ListResponse } from '../generics';

export const UserProfile = t.exact(
  t.type({
    first_name: t.string,
    last_name: t.union([t.string, t.null]),
  }),
);

const UserDetails = t.exact(
  t.type({
    identifier: t.string,
    email: t.string,
    details: UserProfile,
    is_admin: t.boolean,
  }),
);

export const UserProfileResponse = DetailResponse(UserProfile);
export const UserDetailResponse = DetailResponse(UserDetails);
export const UserListResponse = ListResponse(UserDetails);

// Domain Types
export type UserProfile = t.TypeOf<typeof UserProfile>;
export type UserDetails = t.TypeOf<typeof UserDetails>;
// Responses
export type UserProfileResponse = t.TypeOf<typeof UserProfileResponse>;
export type UserListResponse = t.TypeOf<typeof UserListResponse>;
export type UserDetailResponse = t.TypeOf<typeof UserDetailResponse>;
