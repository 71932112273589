const auth = 'auth';
const docs = 'docs';
const admin = 'admin';
const branch = 'branch';
const user = 'users';
const organisation = 'organisations';
const merchant = 'merchant';
const looks = 'looks';
const transact = 'transact';

// es-lint disable
export default {
  HOME: '/',
  BLOG: '/',

  authentication: {
    BASE: `/${auth}/`,
    LOGIN: `/${auth}/login`,
    LOGOUT: `/${auth}/logout`,
    RESET_PASSWORD: `/${auth}/reset-password/:token`,
  },

  documentation: {
    BASE: `/${docs}/`,
    SWAGGER: `/${docs}/swagger`,
    REDOC: `/${docs}/redoc`,
  },

  merchant: {
    BASE: `/${merchant}/`,
    ACCOUNT: `/${merchant}/account`,
    LOOKS: `/${merchant}/${looks}/:identifier`,
    TRANSACT: `/${merchant}/${transact}/:identifier`,
    transact: {
      VIEW_TEAM: `/${merchant}/${transact}/:identifier/team`,
      TO_VIEW_TEAM: (identifier: string) => `/${merchant}/${transact}/${identifier}/team`,

      ADD_NEW_TEAM_MEMBER: `/${merchant}/${transact}/:identifier/add`,
      TO_ADD_NEW_TEAM_MEMBER: (identifier: string) => `/${merchant}/${transact}/${identifier}/add`,
    },
  },

  admin: {
    BASE: `/${admin}/`,
    ORGANISATIONS: `/${admin}/organisations`,
    SETTINGS: `/${admin}/settings`,

    looks: {
      BASE: `/${admin}/${looks}`,
      CREATE: `/${admin}/${looks}/create`,
      DETAIL: `/${admin}/${looks}/:identifier`,
      TO_DETAIL: (identifier: string) => `/${admin}/${looks}/${identifier}`,
    },

    users: {
      BASE: `/${admin}/${user}`,
      CREATE: `/${admin}/${user}/create`,
      DETAIL: (identifier: string) => `/${admin}/${user}/${identifier}`,
      DETAIL_ROUTE: `/${admin}/${user}/:identifier`,
      ADD_BRANCH: `/${admin}/${user}/:identifier/add-branch`,
    },

    organisations: {
      BASE: `/${admin}/${organisation}`,
      CREATE: `/${admin}/${organisation}/create`,

      DETAIL: `/${admin}/${organisation}/:identifier`,
      TO_DETAIL: (identifier: string) => `/${admin}/${organisation}/${identifier}`,
    },

    branches: {
      BASE: `/${admin}/${branch}`,
      CREATE: `/${admin}/${branch}/create`,

      DETAIL: (identifier: string) => `/${admin}/${branch}/${identifier}`,

      DETAIL_ROUTE: `/${admin}/${branch}/:identifier`,
    },
  },

  user: {
    PROFILE: `/${user}/profile`,
  },
};
