import * as React from 'react';
import { Link } from 'react-router-dom';
import Data from '../../../../../../Data';
import { OrganisationRecord } from '../../../../../../Data/organisations/@records/Organisation';

type Props = {
  organisation: OrganisationRecord;
};

export default ({ organisation }: Props) => (
  <Link
    to={Data.paths.admin.organisations.TO_DETAIL(organisation.identifier)}
    className="py-1 pl-4 mb-2 border text-gray-600 rounded-full block cursor-pointer hover:border-liven-base transition-250"
  >
    {organisation.name}
  </Link>
);
