import * as React from 'react';
import { BranchBasicResponse } from '../../../../../../Async/Users/list_branches';
import { AutomataService, Events } from '../../../../../../Machines/users/listBranches';

type Props = {
  branch: BranchBasicResponse;
  service: AutomataService;
};
export default ({ branch, service }: Props) => (
  <tr className="hover:bg-grey-lighter">
    <td className="py-4 px-6 border-b border-grey-light">{branch.name}</td>
    <td className="py-4 px-6 border-b border-grey-light">
      <button
        onClick={() =>
          service.send({
            type: Events.REMOVE_BRANCH,
            branchId: branch.identifier,
          })
        }
        className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-red-200 hover:bg-red-500 transition-250"
      >
        Remove
      </button>
      <button
        onClick={console.log}
        className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-green-200 hover:bg-green-500 transition-250 hover:text-white"
      >
        View
      </button>
    </td>
  </tr>
);
