import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';
import { UUID } from 'io-ts-types/lib/UUID';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { UserRecord } from '../../Data/users/@records/User';
import { ListResponse } from '../generics';

export const RemoveUserFromBranchRequest = t.exact(
  t.type({
    branchId: UUID,
  }),
);

export const BranchResponse = t.exact(
  t.type({
    identifier: UUID,
    name: t.string,
    organisation: t.type({
      identifier: UUID,
      name: t.string,
    }),
  }),
);

export const RemoveUserFromBranchListResponse = ListResponse(BranchResponse);

export type BranchResponse = t.TypeOf<typeof BranchResponse>;
export type GetUserBranchesListResponse = t.TypeOf<typeof RemoveUserFromBranchListResponse>;

export const removeUserFromBranch = (user: UserRecord, branchId: string): Promise<AxiosResponse<GetUserBranchesListResponse>> =>
  axios.post(Endpoints.users.REMOVE_USER_FROM_BRANCH(user), { branchId }, Data.async.authorisationHeaders());

export default async (user: UserRecord, branchId: string) =>
  await removeUserFromBranch(user, branchId).then(getDataFromAxios.get).then(tp.decode(RemoveUserFromBranchListResponse));
