import * as x from 'xstate';
import { Lens } from 'monocle-ts';
import Async from '../../Async';
import * as brands from '../../brands';

// States

export enum States {
  IDLE = 'IDLE',
  REVOKE_TRANSACT_SESSION_REQUEST = 'REVOKE_TRANSACT_SESSION_SESSION',
  REVOKE_TRANSACT_SESSION_SUCCESS = 'REVOKE_TRANSACT_SESSION_SESSION_SUCCESS',
  REVOKE_TRANSACT_SESSION_ERROR = 'REVOKE_TRANSACT_SESSION_SESSION_ERROR',
}

export type AutomataStates = {
  states: {
    [States.IDLE]: {};
    [States.REVOKE_TRANSACT_SESSION_REQUEST]: {};
    [States.REVOKE_TRANSACT_SESSION_SUCCESS]: {};
    [States.REVOKE_TRANSACT_SESSION_ERROR]: {};
  };
};

// Context

export type AutomataContext = {
  data: {
    identifier: brands.TransactSessionIdentifier;
  };
};

// Events

export enum Events {
  REVOKE_SESSION = 'REVOKE_SESSION',
}

export type RevokeSession = {
  type: Events.REVOKE_SESSION;
};

export type AutomataEvent = RevokeSession;

// Services
export const revokeSession = (c: AutomataContext, e: AutomataEvent) => Async.transact.revoke(c.data.identifier);

// Actions
export const AssignDataToContext = x.assign((c: AutomataContext, e: any) => Lens.fromPath<AutomataContext>()(['data', e.field]).set(e.value)(c));
// Config

export const config = (identifier: brands.TransactSessionIdentifier): x.MachineConfig<AutomataContext, AutomataStates, AutomataEvent> => ({
  id: 'Machine:Transact:Sessions:Add',
  initial: States.IDLE,
  context: {
    data: {
      identifier: identifier,
    },
  },
  states: {
    [States.IDLE]: {
      on: {
        [Events.REVOKE_SESSION]: {
          target: States.REVOKE_TRANSACT_SESSION_REQUEST,
        },
      },
    },
    [States.REVOKE_TRANSACT_SESSION_REQUEST]: {
      invoke: {
        src: 'revokeSession',
        onDone: {
          target: States.REVOKE_TRANSACT_SESSION_SUCCESS,
          actions: [],
        },
        onError: {
          target: States.REVOKE_TRANSACT_SESSION_ERROR,
        },
      },
    },
    [States.REVOKE_TRANSACT_SESSION_SUCCESS]: {},
    [States.REVOKE_TRANSACT_SESSION_ERROR]: {},
  },
});

export const options: any = {
  services: {
    revokeSession,
  },
  actions: {},
};

export type Service = x.Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = x.State<AutomataContext, AutomataEvent>;
export type Machine = x.MachineConfig<AutomataContext, AutomataStates, AutomataEvent>;
export type Send = Service['send'];
export const machine = (identifier: brands.TransactSessionIdentifier) => x.Machine(config(identifier), options);

export default machine;
