import Data from '../../Data';
import Async from '../../Async';

import { Machine, Interpreter, MachineConfig, assign, DoneInvokeEvent, State } from 'xstate';

import { Errors } from 'io-ts';
import { array } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/pipeable';
import { fromTraversable, Lens, Prism } from 'monocle-ts';
import { Either, isLeft, isRight, fold } from 'fp-ts/lib/Either';

import extractIdentifierFromPath, { Match } from '../utilities/extractIdentifierFromPath';
import { OrganisationUUID } from '../../Data/organisations/@records/Organisation';
import { DetailOrganisationResponse, BranchResponse } from '../../Async/Organisations/response_models';

// States
export enum States {
  GET_ORGANISATION_ID_FROM_LOCATION_PROPS = 'GET_ORGANISATION_ID_FROM_LOCATION_PROPS',
  ORGANISATION_ID_PROP_INVALID = 'ORGANISATION_ID_PROP_INVALID',
  GET_ORGANISATION_FROM_STORE = 'GET_ORGANISATION_FROM_STORE',
  GET_ORGANISATION_FROM_STORE_ERROR = 'GET_ORGANISATION_FROM_STORE_ERROR',

  UPDATE_ORGANISATION_SCREEN = 'UPDATE_ORGANISATION_SCREEN',
  UPDATE_ORGANISATION_REQUEST = 'UPDATE_ORGANISATION_REQUEST',
  UPDATE_ORGANISATION_ERROR = 'UPDATE_ORGANISATION_ERROR',

  UPDATE_BRANCH_REQUEST = 'UPDATE_BRANCH_REQUEST',
  UPDATE_BRANCH_ERROR = 'UPDATE_BRANCH_ERROR',

  ADD_LOOK_TO_BRANCH_SCREEN = 'ADD_LOOK_TO_BRANCH_SCREEN',
  REMOVE_LOOK_FROM_BRANCH_REQUEST = 'REMOVE_LOOK_FROM_BRANCH_REQUEST',
  REMOVE_LOOK_FROM_BRANCH_ERROR = 'REMOVE_LOOK_FROM_BRANCH_ERROR',

  ADD_USER_TO_BRANCH_SCREEN = 'ADD_USER_TO_BRANCH_SCREEN',
  REMOVE_USER_FROM_BRANCH_REQUEST = 'REMOVE_USER_FROM_BRANCH_REQUEST',
  REMOVE_USER_FROM_BRANCH_ERROR = 'REMOVE_USER_FROM_BRANCH_ERROR',

  CREATE_NEW_BRANCH_REQUEST = 'CREATE_NEW_BRANCH_REQUEST',
  CREATE_NEW_BRANCH_SUCCESS = 'CREATE_NEW_BRANCH_SUCCESS',
  CREATE_NEW_BRANCH_ERROR = 'CREATE_NEW_BRANCH_ERROR',

  UPDATE_TRANSACT_REQUEST = 'UPDATE_TRANSACT_REQUEST',
  UPDATE_TRANSACT_SUCCESS = 'UPDATE_TRANSACT_SUCCESS',
  UPDATE_TRANSACT_ERROR = 'UPDATE_TRANSACT_ERROR',

  TERMINATE_AND_RETURN_TO_ORGANISATION_HOMEPAGE = 'TERMINATE_AND_RETURN_TO_ORGANISATION_HOMEPAGE',
}

export type AutomataStates = {
  states: {
    [States.GET_ORGANISATION_ID_FROM_LOCATION_PROPS]: {};
    [States.ORGANISATION_ID_PROP_INVALID]: {};
    [States.GET_ORGANISATION_FROM_STORE]: {};
    [States.GET_ORGANISATION_FROM_STORE_ERROR]: {};
    [States.UPDATE_ORGANISATION_SCREEN]: {};

    [States.UPDATE_ORGANISATION_REQUEST]: {};
    [States.UPDATE_ORGANISATION_ERROR]: {};

    [States.UPDATE_BRANCH_REQUEST]: {};
    [States.UPDATE_BRANCH_ERROR]: {};

    [States.ADD_LOOK_TO_BRANCH_SCREEN]: {};
    [States.REMOVE_LOOK_FROM_BRANCH_REQUEST]: {};
    [States.REMOVE_LOOK_FROM_BRANCH_ERROR]: {};

    [States.ADD_USER_TO_BRANCH_SCREEN]: {};

    [States.REMOVE_USER_FROM_BRANCH_REQUEST]: {};
    [States.REMOVE_USER_FROM_BRANCH_ERROR]: {};

    [States.CREATE_NEW_BRANCH_REQUEST]: {};
    [States.CREATE_NEW_BRANCH_SUCCESS]: {};
    [States.CREATE_NEW_BRANCH_ERROR]: {};

    [States.UPDATE_TRANSACT_REQUEST]: {};
    [States.UPDATE_TRANSACT_SUCCESS]: {};
    [States.UPDATE_TRANSACT_ERROR]: {};

    [States.TERMINATE_AND_RETURN_TO_ORGANISATION_HOMEPAGE]: {};
  };
};

// Context

export type AutomataContext = {
  organisationIdE: Either<Errors, Match>;
  organisationId: OrganisationUUID;
  data: {
    name: string;
    branches: Array<BranchResponse>;
  };
  newBranchName: string;
};

// Events

export enum Events {
  SUBMIT_DATA = 'SUBMIT_DATA',
  ENTER_DATA = 'ENTER_DATA',
  CLOSE_MODAL = 'CLOSE_MODAL',
  REMOVE_LOOK_FROM_BRANCH = 'REMOVE_LOOK_FROM_BRANCH',
  ADD_LOOK_TO_BRANCH = 'ADD_LOOK_TO_BRANCH',
  ADD_USER_TO_BRANCH = 'ADD_USER_TO_BRANCH',
  REMOVE_USER_FROM_BRANCH = 'REMOVE_USER_FROM_BRANCH',
  MODIFY_BRANCH_DETAILS = 'MODIFY_BRANCH_DETAILS',
  SUBMIT_UPDATED_BRANCH_DETAILS = 'SUBMIT_UPDATED_BRANCH_DETAILS',
  MODIFY_TRANSACT_DETAILS = 'MODIFY_TRANSACT_DETAILS',
  RELOAD_ORGANISATION_DATA = 'RELOAD_ORGANISATION_DATA',
  REFRESH = 'REFRESH',
  ENTER_NEW_BRANCH_NAME = 'ENTER_NEW_BRANCH_NAME',
  SUBMIT_CREATE_NEW_BRANCH = 'SUBMIT_CREATE_NEW_BRANCH',

  UPDATE_TRANSACT = 'UPDATE_TRANSACT',
}

export type SubmitData = {
  type: Events.SUBMIT_DATA;
};

export type EnterData = {
  type: Events.ENTER_DATA;
  value: string;
  field: keyof AutomataContext['data'];
};

export type RemoveLookFromBranch = {
  type: Events.REMOVE_LOOK_FROM_BRANCH;
  branchId: string;
  lookId: string;
};
export type CloseModal = {
  type: Events.CLOSE_MODAL;
};
export type AddLookToBranch = {
  type: Events.ADD_LOOK_TO_BRANCH;
};
export type AddUserToBranch = {
  type: Events.ADD_USER_TO_BRANCH;
};
export type RemoveUserFromBranch = {
  type: Events.REMOVE_USER_FROM_BRANCH;
  branchId: string;
  userId: string;
};
export type UpdateBranchDetails = {
  type: Events.MODIFY_BRANCH_DETAILS;
  branchId: string;
  value: string;
  field: 'name' | 'branch_id';
};

export type UpdateTransactDetails = {
  type: Events.MODIFY_TRANSACT_DETAILS;
  branchId: string;
  value: boolean | null | number;
  field: keyof DetailOrganisationResponse['record']['branches'][0]['transact'];
};

export type SubmitUpdatedBranchDetails = {
  type: Events.SUBMIT_UPDATED_BRANCH_DETAILS;
  identifier: string;
  branch_id: string;
  name: string;
};
export type ReloadOrganisationData = {
  type: Events.RELOAD_ORGANISATION_DATA;
};
export type Refresh = {
  type: Events.REFRESH;
};
export type EnterNewBranchName = {
  type: Events.ENTER_NEW_BRANCH_NAME;
  branchName: string;
};
export type SubmitCreateNewBranch = {
  type: Events.SUBMIT_CREATE_NEW_BRANCH;
};
export type UpdateTransact = {
  type: Events.UPDATE_TRANSACT;
  branch: BranchResponse;
};

export type AutomataEvent =
  | SubmitData
  | EnterData
  | CloseModal
  | RemoveLookFromBranch
  | AddUserToBranch
  | AddLookToBranch
  | RemoveUserFromBranch
  | UpdateBranchDetails
  | UpdateTransactDetails
  | SubmitUpdatedBranchDetails
  | ReloadOrganisationData
  | Refresh
  | EnterNewBranchName
  | SubmitCreateNewBranch
  | UpdateTransact;

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

// Services

export const getOrganisationDetails = (c: AutomataContext, _: AutomataEvent) => Async.organisations.detail(c.organisationId);

export const updateOrganisationDetails = (c: AutomataContext, e: AutomataEvent) =>
  Async.organisations.update({ identifier: c.organisationId, ...c.data });

export const removeLookFromBranch = (c: AutomataContext, e: RemoveLookFromBranch) => Async.organisations.remove_look_from_branch(e);

export const removeUserFromBranch = (c: AutomataContext, e: RemoveUserFromBranch) => Async.organisations.remove_user_from_branch(e);

export const submitUpdatedBranchDetails = (c: AutomataContext, e: SubmitUpdatedBranchDetails) => Async.organisations.update_branch(e);

export const createNewBranch = (c: AutomataContext, e: AutomataEvent) =>
  Async.organisations.create_branch({
    branchName: c.newBranchName,
    orgIdentifier: c.organisationId,
  });

export const updateTransactDetails = (c: AutomataContext, e: UpdateTransact) => (
  console.log({ c, e }), Async.transact.update(e.branch.identifier, e.branch.transact)
);

// Actions

export const getOrganisationIdE = assign((c: AutomataContext, e: AutomataEvent) => {
  const valueE = extractIdentifierFromPath(Data.paths.admin.organisations.DETAIL);
  return Lens.fromProp<AutomataContext>()('organisationIdE').set(valueE)(c);
});

export const assignOrganisationIdFromE = assign((c: AutomataContext, e: AutomataEvent) => {
  const organisationId = fold(
    (e: Errors): any => null,
    (a: Match) => a.params.identifier,
  )(c.organisationIdE);
  return Lens.fromProp<AutomataContext>()('organisationId').set(organisationId)(c);
});

export const saveOrganisationDataToContext = assign((c: AutomataContext, e: DoneInvokeEvent<DetailOrganisationResponse>) => {
  const lens = Lens.fromPath<AutomataContext>();

  return pipe(lens(['data', 'name']).set(e.data.record.name)(c), lens(['data', 'branches']).set(e.data.record.branches));
});

export const SaveOrganisationToStore = (c: AutomataContext, e: DoneInvokeEvent<DetailOrganisationResponse>) =>
  Data.store.dispatch(Data.creators.organisation.saveOrganisationToStore(e.data.record));

export const EnterData = assign((c: AutomataContext, e: EnterData) => Lens.fromPath<AutomataContext>()(['data', e.field]).set(e.value)(c));

export const UpdateBranchProperties = assign(
  (c: AutomataContext, e: UpdateBranchDetails): AutomataContext =>
    Lens.fromPath<AutomataContext>()(['data', 'branches'])
      .composeTraversal(fromTraversable(array)<BranchResponse>())
      .composePrism(Prism.fromPredicate((branch) => branch.identifier === e.branchId))
      .composeLens(Lens.fromProp<BranchResponse>()(e.field))
      .set(e.value)(c),
);

export const UpdateTransactProperties = assign(
  (c: AutomataContext, e: UpdateTransactDetails): AutomataContext =>
    pipe(
      c,
      Lens.fromPath<AutomataContext>()(['data', 'branches'])
        .composeTraversal(fromTraversable(array)<BranchResponse>())
        .composePrism(Prism.fromPredicate((branch) => branch.identifier === e.branchId))
        .composeLens(Lens.fromPath<BranchResponse>()(['transact', e.field]))
        .set(e.value),
    ),
);

export const updateNewBranchName = assign(
  (c: AutomataContext, e: EnterNewBranchName): AutomataContext => Lens.fromProp<AutomataContext>()('newBranchName').set(e.branchName)(c),
);
export const clearNewBranchName = assign((c: AutomataContext): AutomataContext => Lens.fromProp<AutomataContext>()('newBranchName').set('')(c));

// Guards

export const orgIdIsLeft = (c: AutomataContext, e: AutomataEvent): boolean => isLeft(c.organisationIdE);

export const orgIdIsRight = (c: AutomataContext, e: AutomataEvent): boolean => isRight(c.organisationIdE);

// Config

export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'Organisation:Detail:Update:Machine',
  initial: States.GET_ORGANISATION_ID_FROM_LOCATION_PROPS,
  context: {
    organisationIdE: null as any,
    organisationId: '',
    data: {
      name: '',
      branches: [],
    },
    newBranchName: '',
  },
  states: {
    [States.GET_ORGANISATION_ID_FROM_LOCATION_PROPS]: {
      entry: ['getOrganisationIdE', 'CreateUserSearchMachine'],
      on: {
        '': [
          {
            cond: 'orgIdIsLeft',
            target: States.ORGANISATION_ID_PROP_INVALID,
          },
          {
            cond: 'orgIdIsRight',
            target: States.GET_ORGANISATION_FROM_STORE,
            actions: ['assignOrganisationIdFromE'],
          },
        ],
      },
    },
    [States.ORGANISATION_ID_PROP_INVALID]: {
      after: {
        2000: {
          target: States.TERMINATE_AND_RETURN_TO_ORGANISATION_HOMEPAGE,
        },
      },
    },
    [States.GET_ORGANISATION_FROM_STORE]: {
      invoke: {
        src: 'getOrganisationDetails',
        onDone: {
          target: States.UPDATE_ORGANISATION_SCREEN,
          actions: ['saveOrganisationDataToContext', 'SaveOrganisationToStore'],
        },
        onError: {
          target: States.GET_ORGANISATION_FROM_STORE_ERROR,
          actions: [],
        },
      },
    },
    [States.GET_ORGANISATION_FROM_STORE_ERROR]: {},
    [States.UPDATE_ORGANISATION_SCREEN]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['EnterData'],
        },
        [Events.MODIFY_BRANCH_DETAILS]: {
          actions: ['UpdateBranchProperties'],
        },
        [Events.SUBMIT_UPDATED_BRANCH_DETAILS]: {
          target: States.UPDATE_BRANCH_REQUEST,
        },
        [Events.SUBMIT_DATA]: {
          target: States.UPDATE_ORGANISATION_REQUEST,
        },
        [Events.REMOVE_LOOK_FROM_BRANCH]: {
          target: States.REMOVE_LOOK_FROM_BRANCH_REQUEST,
        },
        [Events.REMOVE_USER_FROM_BRANCH]: {
          target: States.REMOVE_USER_FROM_BRANCH_REQUEST,
        },
        [Events.ADD_LOOK_TO_BRANCH]: {
          target: States.ADD_LOOK_TO_BRANCH_SCREEN,
        },
        [Events.ADD_USER_TO_BRANCH]: {
          target: States.ADD_USER_TO_BRANCH_SCREEN,
        },
        [Events.RELOAD_ORGANISATION_DATA]: {
          target: States.GET_ORGANISATION_FROM_STORE,
        },
        [Events.ENTER_NEW_BRANCH_NAME]: {
          actions: ['updateNewBranchName'],
        },
        [Events.SUBMIT_CREATE_NEW_BRANCH]: {
          target: States.CREATE_NEW_BRANCH_REQUEST,
        },
        [Events.MODIFY_TRANSACT_DETAILS]: {
          actions: ['UpdateTransactProperties'],
        },
      },
    },
    [States.UPDATE_ORGANISATION_REQUEST]: {
      invoke: {
        src: 'updateOrganisationDetails',
        onDone: {
          target: States.UPDATE_ORGANISATION_SCREEN,
          actions: ['saveOrganisationDataToContext', 'SaveOrganisationToStore'],
        },
        onError: {
          target: States.UPDATE_ORGANISATION_ERROR,
        },
      },
    },
    [States.UPDATE_BRANCH_REQUEST]: {
      invoke: {
        src: 'submitUpdatedBranchDetails',
        onDone: {
          target: States.UPDATE_ORGANISATION_SCREEN,
          actions: ['SaveOrganisationToStore', 'saveOrganisationDataToContext'],
        },
        onError: {
          target: States.UPDATE_BRANCH_ERROR,
        },
      },
    },

    [States.UPDATE_BRANCH_ERROR]: {
      on: {
        '': {
          target: States.UPDATE_ORGANISATION_SCREEN,
        },
      },
    },

    [States.UPDATE_ORGANISATION_ERROR]: {},

    // Remove Looks
    [States.ADD_LOOK_TO_BRANCH_SCREEN]: {
      on: {
        [Events.CLOSE_MODAL]: {
          target: States.GET_ORGANISATION_FROM_STORE,
        },
      },
    },
    [States.REMOVE_LOOK_FROM_BRANCH_REQUEST]: {
      invoke: {
        src: 'removeLookFromBranch',
        onDone: {
          target: States.UPDATE_ORGANISATION_SCREEN,
          actions: ['saveOrganisationDataToContext', 'SaveOrganisationToStore'],
        },
        onError: {
          target: States.UPDATE_ORGANISATION_SCREEN,
          actions: [],
        },
      },
    },

    [States.REMOVE_LOOK_FROM_BRANCH_ERROR]: {},
    // USERS
    // Add UserDetailResponse
    [States.ADD_USER_TO_BRANCH_SCREEN]: {
      on: {
        [Events.CLOSE_MODAL]: {
          target: States.GET_ORGANISATION_FROM_STORE,
        },
      },
    },
    // Remove Users
    [States.REMOVE_USER_FROM_BRANCH_REQUEST]: {
      invoke: {
        src: 'removeUserFromBranch',
        onDone: {
          target: States.UPDATE_ORGANISATION_SCREEN,
          actions: ['saveOrganisationDataToContext', 'SaveOrganisationToStore'],
        },
        onError: {
          target: States.UPDATE_ORGANISATION_SCREEN,
        },
      },
    },

    [States.REMOVE_USER_FROM_BRANCH_ERROR]: {},

    [States.CREATE_NEW_BRANCH_REQUEST]: {
      invoke: {
        src: 'createNewBranch',
        onDone: {
          target: States.CREATE_NEW_BRANCH_SUCCESS,
          actions: ['saveOrganisationDataToContext', 'SaveOrganisationToStore'],
        },
        onError: {
          target: States.CREATE_NEW_BRANCH_ERROR,
        },
      },
    },
    [States.CREATE_NEW_BRANCH_SUCCESS]: {
      entry: ['clearNewBranchName'],
      on: {
        '': {
          target: States.UPDATE_ORGANISATION_SCREEN,
        },
      },
    },
    [States.CREATE_NEW_BRANCH_ERROR]: {
      on: {
        '': {
          target: States.UPDATE_ORGANISATION_SCREEN,
        },
      },
    },

    [States.UPDATE_TRANSACT_REQUEST]: {
      invoke: {
        src: 'updateTransactDetails',
        onDone: {
          target: States.GET_ORGANISATION_FROM_STORE,
        },
        onError: {
          target: States.UPDATE_TRANSACT_ERROR,
        },
      },
    },
    [States.UPDATE_TRANSACT_SUCCESS]: {
      // always: States.GET_ORGANISATION_FROM_STORE
    },
    [States.UPDATE_TRANSACT_ERROR]: {},

    [States.TERMINATE_AND_RETURN_TO_ORGANISATION_HOMEPAGE]: {
      type: 'final',
      entry: ['redirectToUserHomePage'],
    },
  },
  on: {
    [Events.REFRESH]: {
      target: States.GET_ORGANISATION_ID_FROM_LOCATION_PROPS,
    },
    [Events.UPDATE_TRANSACT]: {
      target: States.UPDATE_TRANSACT_REQUEST,
    },
  },
};

// Options
export const options: any = {
  services: {
    getOrganisationDetails,
    updateOrganisationDetails,
    removeLookFromBranch,
    removeUserFromBranch,
    submitUpdatedBranchDetails,
    createNewBranch,
    updateTransactDetails,
  },
  actions: {
    EnterData,
    getOrganisationIdE,
    assignOrganisationIdFromE,
    saveOrganisationDataToContext,
    SaveOrganisationToStore,
    UpdateBranchProperties,
    UpdateTransactProperties,
    updateNewBranchName,
    clearNewBranchName,
  },
  guards: {
    orgIdIsLeft,
    orgIdIsRight,
  },
};

export default Machine(config, options);
