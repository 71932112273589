import * as React from 'react';

import { AutomataContext, AutomataService, Events } from '../../../../../Machines/users/createUser';

export const sendUserData = (service: AutomataService, field: keyof AutomataContext['formData']) => (e: React.ChangeEvent<HTMLInputElement>) =>
  service.send({
    type: Events.ENTER_DATA,
    field: field,
    value: e.target.value,
  });

type Props = {
  isDisabled: boolean;
  service: AutomataService;
};

export default ({ isDisabled, service }: Props) => (
  <div>
    <input
      value={service.machine.context?.formData.email}
      onChange={sendUserData(service, 'email')}
      onFocus={(e) => service.send(Events.INPUT_FOCUS)}
      className="text-input"
      type="email"
      placeholder="Email"
      disabled={isDisabled}
    />

    <input
      value={service.machine.context?.formData.first_name}
      onChange={sendUserData(service, 'first_name')}
      className="text-input"
      type="text"
      placeholder="First Name"
      disabled={isDisabled}
    />

    <input
      value={service.machine.context?.formData.last_name}
      onChange={sendUserData(service, 'last_name')}
      className="text-input"
      type="text"
      placeholder="Last Name"
      disabled={isDisabled}
    />

    <hr />
    <button disabled={isDisabled} onClick={() => service.send(Events.SUBMIT_DATA)} className="btn" type="button">
      Create User
    </button>
  </div>
);
