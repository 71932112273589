import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { DetailedLookResponse } from './response_models';

export const UpdateLookRequest = t.exact(
  t.type({
    name: t.string,
    description: t.string,
    link: t.string,
  }),
);

export type UpdateLookRequest = t.TypeOf<typeof UpdateLookRequest>;

export const updateLook = (identifier: string, request: UpdateLookRequest): Promise<AxiosResponse<DetailedLookResponse>> =>
  axios.put(Endpoints.looks.DETAIL(identifier), request, Data.async.authorisationHeaders());

export default async (identifier: string, request: UpdateLookRequest) =>
  await updateLook(identifier, request).then(getDataFromAxios.get).then(tp.decode(DetailedLookResponse));
