import * as React from 'react';
import History from '../../../../../../../history';
import Data from '../../../../../../../Data';

import { Send, Events } from '../../../../../../../Machines/organisations/update';
import { BranchResponse, LookResponse } from '../../../../../../../Async/Organisations/response_models';

type Props = {
  look: LookResponse;
  send: Send;
  branch: BranchResponse;
};

export const RemoveLookFromBranch = (branch: BranchResponse, look: LookResponse, send: Send) => (e: React.MouseEvent<HTMLButtonElement>) =>
  send({
    type: Events.REMOVE_LOOK_FROM_BRANCH,
    branchId: branch.identifier,
    lookId: look.identifier,
  });

export default ({ send, look, branch }: Props) => (
  <tr>
    <td />
    <td>{look.name}</td>
    <td>
      <button
        onClick={() => History.push(Data.paths.admin.looks.TO_DETAIL(look.identifier))}
        className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-gray-400 hover:bg-gray-200 transition-250"
      >
        View
      </button>

      <button
        onClick={RemoveLookFromBranch(branch, look, send)}
        className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-red-200 hover:bg-red-500 transition-250 ml-5"
      >
        Remove
      </button>
    </td>
  </tr>
);
