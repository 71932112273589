import * as F from 'fp-ts/lib/function';
import * as P from 'fp-ts/lib/pipeable';
import * as O from 'fp-ts/lib/Option';
import * as React from 'react';
import classNames from 'classnames';

import { Props } from '../';
import { TickIcon } from '../../../../../../Static/liven/Icons/TickIcon';
import * as automata from '../../../../../../Machines/transact/add_session';
import { useMachine } from '@xstate/react';
import { BranchIdentifier } from '../../../../../../brands';

const updateItem =
  (send: automata.Send, field: keyof Omit<automata.AutomataContext['data'], 'identifier' | 'contactMethod'>) =>
  (e: React.FormEvent<HTMLInputElement>) =>
    send({
      type: automata.Events.UPDATE_CONTEXT_ITEM,
      field: field,
      value: e.currentTarget.value as any,
    });
export default (props: Props) => {
  const [current, send] = useMachine(automata.machine(props.identifier as BranchIdentifier));

  const fieldWrapper = 'py-6 space-y-10';
  const fieldSubWrapper = 'relative border-b border-gray-200 focus-within:border-liven-red-200 flex';
  const userInputClass = 'block w-full appearance-none focus:outline-none bg-transparent py-2 text-md font-light';
  const tickIconClass = classNames({
    'flex-shrink-0 self-center opacity-0': true,
    'opacity-100': false,
  });
  const userFlotingLabelClass = classNames('absolute top-0 duration-300 origin-0 -z-1 text-gray-500');

  const setSMS = () => send({ type: automata.Events.TOGGLE_METHOD, method: 'SMS' });
  const setPIN = () => send({ type: automata.Events.TOGGLE_METHOD, method: 'PIN' });

  const pin = P.pipe(
    current.context.result,
    O.map((r) => r.code),
    O.map((v) => v.toString()),
    O.fold(F.constant(''), F.identity),
  );

  return (
    <div className="w-3/4">
      <div className={fieldWrapper}>
        <div className={fieldSubWrapper}>
          <input
            type="text"
            name="name"
            placeholder="Enter Staff Name"
            value={current.context.data.name}
            onChange={updateItem(send, 'name')}
            className={userInputClass}
          />
          <span className={tickIconClass}>
            <TickIcon />
          </span>
          <label htmlFor="name" className={userFlotingLabelClass}>
            Staff Name
          </label>
        </div>
      </div>

      {current.context.method === 'SMS' && (
        <div className={fieldWrapper}>
          <div className={fieldSubWrapper}>
            <input
              type="text"
              name="contactValue"
              placeholder="Enter Mobile Number"
              value={current.context.data.contactValue}
              onChange={updateItem(send, 'contactValue')}
              className={userInputClass}
            />
            <span className={tickIconClass}>
              <TickIcon />
            </span>
            <label htmlFor="contactValue" className={userFlotingLabelClass}>
              Contact Value
            </label>
          </div>
        </div>
      )}

      <div className="flex items-center">
        <div>
          <div>
            <input
              id="push_everything"
              name="push_notifications"
              checked={current.context.method === 'PIN'}
              disabled={current.value === automata.States.ADD_TRANSACT_SESSION_REQUEST}
              onClick={setPIN}
              type="radio"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            />
          </div>
          <div>
            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
              PIN
            </label>
          </div>
        </div>
        <div>
          <div>
            <input
              id="push_everything"
              name="push_notifications"
              checked={current.context.method === 'SMS'}
              disabled={current.value === automata.States.ADD_TRANSACT_SESSION_REQUEST}
              onClick={setSMS}
              type="radio"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            />
          </div>
          <div>
            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
              SMS
            </label>
          </div>
        </div>
      </div>

      {current.value === automata.States.ADD_TRANSACT_SESSION_ERROR && (
        <div onClick={() => send({ type: automata.Events.RESTART })} className="border bg-red-200 border-red-500 text-red-900 rounded-md px-4 py-3">
          There has been an error creating the user session
        </div>
      )}

      {current.value === automata.States.ADD_TRANSACT_SESSION_SUCCESS && (
        <div
          onClick={() => send({ type: automata.Events.RESTART })}
          className="border bg-green-200 border-green-500 text-green-900 rounded-md px-4 py-3 mt-5"
        >
          Successfully created
        </div>
      )}

      {current.value === automata.States.ADD_TRANSACT_SESSION_SUCCESS && current.context.method === 'PIN' && (
        <div className="border bg-blue-200 border-blue-500 text-blue-900 rounded-md px-4 py-3 mt-5">PIN: {pin}</div>
      )}

      <div className="flex mt-5">
        <div
          className="text-liven-base border rounded-md px-3 py-2 border-liven-base cursor-pointer"
          onClick={() => send({ type: automata.Events.CREATE_SESSION })}
        >
          Create
        </div>
      </div>
    </div>
  );
};
