import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';

import { getDataFromAxios } from '../common';
import { AuthorisationResponse } from './response_models';

export const authorisation = (): Promise<AxiosResponse<AuthorisationResponse>> =>
  axios.get(Endpoints.authentication.GET_SCOPES(), Data.async.authorisationHeaders());

export default async () => await authorisation().then(getDataFromAxios.get).then(tp.decode(AuthorisationResponse));
