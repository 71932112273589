import * as React from 'react';

import { Events, CurrentState, Send, AutomataContext } from '../../../../../Machines/looks/create';

export const updateLookData = (send: Send, field: keyof AutomataContext['formData']) => (e: React.ChangeEvent<HTMLInputElement>) =>
  send({
    type: Events.ENTER_DATA,
    value: e.target.value,
    field: field,
  });

type Props = {
  isDisabled: boolean;
  current: CurrentState;
  send: Send;
};

export default ({ isDisabled, current, send }: Props) => (
  <div>
    <input
      value={current.context.formData.name}
      onChange={updateLookData(send, 'name')}
      className="text-input"
      type="text"
      placeholder="Enter Look Name"
      disabled={isDisabled}
    />

    <input
      value={current.context.formData.description}
      onChange={updateLookData(send, 'description')}
      className="text-input"
      type="text"
      placeholder="Enter Look Description"
      disabled={isDisabled}
    />

    <input
      value={current.context.formData.link}
      onChange={updateLookData(send, 'link')}
      className="text-input"
      type="text"
      placeholder="Enter Look Embed Path"
      disabled={isDisabled}
    />

    <hr />

    <button disabled={isDisabled} onClick={() => send(Events.SUBMIT_DATA)} className="btn" type="button">
      Create Look
    </button>
  </div>
);
