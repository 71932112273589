import * as React from 'react';
import Data from '../../../../Data';
import { Link } from 'react-router-dom';
import { AutomataService } from '../../../../Machines/users/listUsers';

type Props = {
  service: AutomataService;
};
export default () => (
  <div className="border-b flex px-6 py-0 flex-row-reverse ">
    <div className="flex items-center justify-center text-gray-600 hover:text-liven-base cursor-pointer py-2 px-2 border-l border-gray-300">
      <Link to={Data.paths.admin.looks.CREATE} className="h-12 w-12 flex items-center justify-center font-semibold rounded-lg overflow-hidden">
        Add
      </Link>
      <svg className="fill-current h-5 w-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
      </svg>
    </div>
  </div>
);
