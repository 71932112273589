import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { DetailOrganisationResponse } from './response_models';

export const UpdateBranchDetailsRequest = t.type({
  identifier: t.string,
  name: t.string,
  branch_id: t.string,
});

export type UpdateOrganisationRequest = t.TypeOf<typeof UpdateBranchDetailsRequest>;

export const updateBranchDetails = (request: UpdateOrganisationRequest): Promise<AxiosResponse<DetailOrganisationResponse>> =>
  axios.put(
    Endpoints.organisations.branches.BRANCH_DETAILS(request.identifier),
    {
      name: request.name,
      branch_id: request.branch_id,
    },
    Data.async.authorisationHeaders(),
  );

export default async (request: UpdateOrganisationRequest) =>
  await updateBranchDetails(request).then(getDataFromAxios.get).then(tp.decode(DetailOrganisationResponse));
