import * as React from 'react';

const defaultColour = '#FF0B75';
type Props = {
  className?: string;
  fillColour?: string;
  dimensions?: number;
};
export default ({ className, fillColour, dimensions }: Props) => (
  <svg width={dimensions} height={dimensions} className={className || ''} viewBox="0 0 693 693" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M346.489 0.0214844C277.96 0.0214844 210.97 20.3427 153.99 58.4154C97.0104 96.4881 52.6 150.602 26.3751 213.915C0.150128 277.228 -6.71151 346.895 6.65785 414.107C20.0272 481.32 53.0271 543.058 101.484 591.515C149.942 639.973 211.68 672.973 278.893 686.342C346.105 699.711 415.772 692.85 479.085 666.625C542.398 640.4 596.512 595.989 634.584 539.01C672.657 482.03 692.978 415.04 692.978 346.511C692.866 254.651 656.324 166.585 591.369 101.63C526.414 36.6755 438.349 0.134321 346.489 0.0214844V0.0214844ZM652.215 346.511C652.215 406.998 634.277 466.127 600.671 516.419C567.064 566.711 519.298 605.908 463.413 629.051C407.528 652.195 346.036 658.246 286.712 646.439C227.388 634.632 172.898 605.498 130.133 562.721C87.3678 519.944 58.2489 465.446 46.4587 406.119C34.6685 346.792 40.7368 285.301 63.896 229.423C87.0552 173.545 126.265 125.789 176.567 92.1966C226.868 58.6039 286.002 40.6828 346.489 40.6996C427.56 40.79 505.283 73.0409 562.6 130.374C619.918 187.708 652.147 265.44 652.215 346.511Z"
      fill={fillColour || defaultColour}
    />
    <path
      d="M205.694 322.037C187.267 314.712 166.72 314.802 148.359 322.29C129.997 329.778 115.246 344.081 107.197 362.204C103.581 371.013 101.77 380.458 101.873 389.98C101.975 399.502 103.989 408.906 107.794 417.635C111.782 427.075 117.605 435.63 124.925 442.803C132.244 449.976 140.915 455.625 150.433 459.422C162.986 464.17 176.328 466.486 189.747 466.244C219.189 465.184 248.172 458.618 275.197 446.886C278.206 446.23 281.004 444.833 283.336 442.822C285.669 440.811 287.463 438.25 288.556 435.37C289.648 432.49 290.005 429.384 289.593 426.332C289.181 423.279 288.014 420.378 286.198 417.891C285.004 414.821 256.691 342.419 205.694 322.037Z"
      fill={fillColour || defaultColour}
    />
    <path
      d="M405.757 417.89C403.963 420.378 402.812 423.271 402.407 426.311C402.002 429.352 402.356 432.445 403.436 435.316C404.517 438.187 406.291 440.745 408.601 442.764C410.911 444.783 413.684 446.198 416.673 446.885C443.812 458.632 472.91 465.198 502.464 466.243C515.882 466.476 529.223 464.161 541.778 459.421C551.297 455.624 559.967 449.975 567.287 442.802C574.606 435.629 580.429 427.075 584.417 417.634C588.222 408.905 590.236 399.501 590.339 389.979C590.441 380.457 588.631 371.012 585.014 362.203C576.964 343.977 562.142 329.596 543.681 322.1C525.22 314.605 504.568 314.582 486.09 322.036C434.923 342.418 406.951 414.82 405.757 417.89Z"
      fill={fillColour || defaultColour}
    />
    <path
      d="M420.598 284.597C420.614 264.762 412.95 245.692 399.214 231.383C385.478 217.075 366.736 208.639 346.917 207.846C337.416 207.779 327.997 209.608 319.212 213.225C310.426 216.842 302.45 222.174 295.749 228.91C288.441 236.06 282.621 244.587 278.625 253.999C274.629 263.41 272.536 273.52 272.468 283.744C272.826 297.185 275.72 310.436 280.996 322.802C292.894 349.863 309.724 374.473 330.629 395.375C332.556 397.742 334.953 399.684 337.668 401.079C340.384 402.473 343.358 403.289 346.405 403.476C349.399 403.179 352.299 402.268 354.925 400.801C357.551 399.333 359.847 397.341 361.67 394.948C363.632 392.305 420.428 339.517 420.598 284.597Z"
      fill={fillColour || defaultColour}
    />
  </svg>
);
