import * as React from 'react';
import Form from './Form';
import { CurrentState, Send } from '../../../../../Machines/looks/create';

type Props = {
  current: CurrentState;
  send: Send;
};
export default ({ current, send }: Props) => (
  <div>
    <div className="bg-red-100 border-l-4 border-red-600 text-red-900 p-4" role="alert">
      <p className="font-bold">Error</p>
      <p>This request could not be completed</p>
    </div>
    <Form isDisabled={false} current={current} send={send} />
  </div>
);
