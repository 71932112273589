import * as React from 'react';

import { Events, CurrentState, Send, AutomataContext } from '../../../../../../Machines/organisations/update';

export const updateOrganisationName = (send: Send, field: keyof AutomataContext['data']) => (e: React.ChangeEvent<HTMLInputElement>) =>
  send({
    type: Events.ENTER_DATA,
    value: e.target.value,
    field: field,
  });

type Props = {
  isDisabled: boolean;
  current: CurrentState;
  send: Send;
};

export default ({ isDisabled, current, send }: Props) => (
  <div className="flex justify-between items-center">
    <div className="w-3/4 px-2">
      <input
        value={current.context.data.name}
        onChange={updateOrganisationName(send, 'name')}
        className="text-input"
        type="text"
        placeholder="Enter Organisation name"
        disabled={isDisabled}
      />
    </div>

    <div className="w-1/4 px-2">
      <button disabled={isDisabled} onClick={() => send(Events.SUBMIT_DATA)} className="btn" type="button">
        Update
      </button>
    </div>
  </div>
);
