import { createStore, Store } from 'redux';
import { State } from './@records';

import reducers from './reducers';

export default createStore(
  reducers,
  State(),
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
) as Store<State>;
