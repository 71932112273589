import * as t from 'io-ts';
import { DetailResponse, ListResponse } from '../generics';
import { UUID } from 'io-ts-types/lib/UUID';

export const UserResponse = t.exact(
  t.type({
    identifier: t.string,
    email: t.string,
    details: t.type({
      first_name: t.string,
      last_name: t.string,
    }),
  }),
);

export const LookResponse = t.exact(
  t.type({
    identifier: t.string,
    name: t.string,
    description: t.string,
  }),
);

export const Transact = t.exact(
  t.type({
    is_active: t.boolean,
    branch_code: t.union([t.null, t.number]),
    security_pin: t.union([t.null, t.number]),
  }),
);
export const BranchBasicResponse = t.exact(
  t.type({
    identifier: UUID,
    name: t.string,
    branch_id: t.union([t.string, t.null]),
    organisation: t.exact(
      t.type({
        identifier: UUID,
        name: t.string,
      }),
    ),
  }),
);

export const BranchResponse = t.intersection([
  BranchBasicResponse,
  t.exact(
    t.type({
      users: t.array(UserResponse),
      looks: t.array(LookResponse),
      transact: Transact,
    }),
  ),
]);

export const OrganisationResponse = t.exact(
  t.type({
    identifier: t.string,
    name: t.string,
    branches: t.array(BranchResponse),
  }),
);

export const BranchSearchResponse = ListResponse(BranchResponse);
export const OrganisationSearchResponse = ListResponse(OrganisationResponse);
export const DetailOrganisationResponse = DetailResponse(OrganisationResponse);

export type Transact = t.TypeOf<typeof Transact>;
export type LookResponse = t.TypeOf<typeof LookResponse>;
export type UserResponse = t.TypeOf<typeof UserResponse>;
export type BranchResponse = t.TypeOf<typeof BranchResponse>;
export type OrganisationResponse = t.TypeOf<typeof OrganisationResponse>;
export type DetailOrganisationResponse = t.TypeOf<typeof DetailOrganisationResponse>;
export type OrganisationSearchResponse = t.TypeOf<typeof OrganisationSearchResponse>;
