import * as React from 'react';
import classNames from 'classnames';
import { State } from 'xstate';

import OrgCard from './OrgCard';
import MagnifyGlassIcon from '../../../../../Static/liven/Icons/MagnifyGlassIcon';
import { AutomataContext, AutomataEvent, AutomataService, CurrentState, Events } from '../../../../../Machines/looks/listLooksForUser';
import ChevronIcon from '../../../../../Static/liven/Icons/ChevronIcon';
import TimesIcon from '../../../../../Static/liven/Icons/TimesIcon';
import CaretDownIcon from '../../../../../Static/liven/Icons/CaretDownIcon';
import { BranchWithLooksDetails } from '../../../../../Async/Looks/get_for_user';

const searchLooks = (service: AutomataService) => (e: React.ChangeEvent<HTMLInputElement>) =>
  service.send({
    type: Events.SEARCH_TERM_UPDATED,
    searchTerm: e.target.value,
  });

type Props = {
  state: State<AutomataContext, AutomataEvent>;
  service: AutomataService;
  current: CurrentState;
};

export const OrgSidebar = ({ state, service, current }: Props) => {
  const [isCollapsed, toggleCollapsed] = React.useState(true);

  const activeBranch = current.context.looks.filter((b: BranchWithLooksDetails) => b.identifier === current?.context.activeBranch).pop();
  const activeBranchName = activeBranch ? activeBranch.name : '';

  const sidebarTransitionClass = 'transition-all duration-500 ease-in-out font-liven-primary';
  const containerClass = classNames({
    [sidebarTransitionClass]: true,
    'flex flex-col min-h-full max-h-full bg-gray-200 fixed md:relative left-0 w-full z-50': true,
    'items-start md:w-72 top-0': !isCollapsed,
    'items-center px-0 hidden md:flex md:w-34': isCollapsed,
  });
  const SideContainerClass = classNames('flex md:flex-col flex-col-reverse w-full px-5');
  const searchbarClass = classNames({
    [sidebarTransitionClass]: true,
    'items-center h-10 p-4 mb-5 md:mt-10 md:mb-8 text-sm text-gray-500 \
		 rounded-full w-10': true,
    'flex w-full bg-gray-300': !isCollapsed,
    'inline-block w-full text-center bg-transparent': isCollapsed,
  });
  const searchIconClass = classNames('inline-block w-4 text-gray-500 fill-current');
  const searchbarInputClass = classNames({
    'focus: flex-grow w-full text-black bg-gray-300 outline-none': true,
    hidden: isCollapsed,
  });
  const titleClass = classNames({
    [sidebarTransitionClass]: true,
    'relative flex items-center justify-between w-full mt-12 mb-5 md:mt-0 md:mb-6 ml-0 \
		text-2xl md:text-lg font-bold': true,
    'ml-2': isCollapsed,
  });
  const toggleClass = classNames({
    [sidebarTransitionClass]: true,
    'w-10 h-10 bg-white rounded-full hidden md:flex items-center \
		justify-center shadow-xs leading-10 text-center font-light absolute right-0 cursor-pointer':
      true,
    '-mr-10': !isCollapsed,
    'rotate-180 -mr-8': isCollapsed,
  });
  const chevronClass = classNames({
    [sidebarTransitionClass]: true,
    'fill-current': true,
    'text-gray-500 ': !isCollapsed,
    'text-liven-red transform rotate-180': isCollapsed,
  });
  const orgsContainerClass = classNames('flex flex-col flex-grow relative w-full overflow-auto mb-8');
  const orgsSubContainerClass = classNames('absolute w-full px-5');
  const dropdownContainerClass = classNames('flex items-center cursor-pointer sm:flex md:hidden p-4');
  const dropdownLabelClass = classNames('mr-1 text-sm font-semibold');
  const TimerIconClass = classNames('md:hidden mt-6 cursor-pointer');

  const onItemClick = () => {
    const viewportWidth = window.innerWidth;
    if (viewportWidth && viewportWidth < 768) {
      toggleCollapsed(!isCollapsed);
    }
  };

  const OrganisatonCard = OrgCard('dashboard');

  return (
    <div className="flex">
      <div className={dropdownContainerClass} onClick={() => toggleCollapsed(!isCollapsed)}>
        <span className={dropdownLabelClass}>{activeBranchName}</span>
        <CaretDownIcon />
      </div>
      <div className={containerClass}>
        <div className={SideContainerClass}>
          {/* Search */}
          <div className={searchbarClass}>
            <input className={searchbarInputClass} onChange={searchLooks(service)} placeholder="Search" type="text" />
            <span className={searchIconClass} onClick={() => isCollapsed && toggleCollapsed(false)}>
              <MagnifyGlassIcon />
            </span>
          </div>

          {/* Title */}
          <div className={titleClass}>
            <div>Orgs</div>
            <span className={toggleClass} onClick={() => toggleCollapsed(!isCollapsed)}>
              <ChevronIcon className={chevronClass} />
            </span>
          </div>
          <div className={TimerIconClass} onClick={() => toggleCollapsed(!isCollapsed)}>
            <TimesIcon />
          </div>
        </div>

        {/* Orgs List */}
        <div className={orgsContainerClass}>
          <div className={orgsSubContainerClass}>
            {state.context.searchResults.map((branch) => (
              <OrganisatonCard
                branch={branch}
                isSelected={current.context.activeBranch === branch.identifier}
                service={service}
                orgName={branch.name}
                isCollapsed={isCollapsed}
                onClick={onItemClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgSidebar;
