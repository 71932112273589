import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import generics from '../generics';

export const LookDetails = t.exact(
  t.type({
    identifier: t.string,
    name: t.string,
    description: t.union([t.string, t.null]),
    link: t.string,
  }),
);

export const BranchWithLooksDetails = t.exact(
  t.type({
    identifier: t.string,
    name: t.string,
    looks: t.array(LookDetails),
    num_branches: t.number,
  }),
);

export const ListLooksForUserResponse = generics.ListResponse(BranchWithLooksDetails);

export type LookDetails = t.TypeOf<typeof LookDetails>;
export type BranchWithLooksDetails = t.TypeOf<typeof BranchWithLooksDetails>;
export type ListLooksForUserResponse = t.TypeOf<typeof ListLooksForUserResponse>;

export const loadLookList = (pageSize: number, currentPage: number, identifier: string): Promise<AxiosResponse<ListLooksForUserResponse>> =>
  axios.get(Endpoints.looks.LIST_FOR_USER(pageSize, currentPage, identifier), Data.async.authorisationHeaders());

export default async (pageSize: number, currentPage: number, identifier: string) =>
  await loadLookList(pageSize, currentPage, identifier).then(getDataFromAxios.get).then(tp.decode(ListLooksForUserResponse));
