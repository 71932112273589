import Data from '../../Data';
import { Machine, Interpreter, MachineConfig, assign, DoneInvokeEvent } from 'xstate';
import createUser, { CreateUserResponse } from '../../Async/Users/create';
import History from '../../history';

// States
export enum States {
  CREATE_USER_SCREEN = 'CREATE_USER_SCREEN',
  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR = 'CREATE_USER_ERROR',
}

export type AutomataStates = {
  states: {
    [States.CREATE_USER_SCREEN]: {};
    [States.CREATE_USER_REQUEST]: {};
    [States.CREATE_USER_SUCCESS]: {};
    [States.CREATE_USER_ERROR]: {};
  };
};

// Context
export type AutomataContext = {
  formData: {
    email: string;
    first_name: string;
    last_name: string;
  };
};

// Events
export enum Events {
  SUBMIT_DATA = 'SUBMIT_DATA',
  ENTER_DATA = 'ENTER_DATA',
  INPUT_FOCUS = 'INPUT_FOCUS',
}

export type SubmitData = {
  type: Events.SUBMIT_DATA;
};
export type EnterData = {
  type: Events.ENTER_DATA;
  value: string;
  field: keyof AutomataContext['formData'];
};
export type Focus = {
  type: Events.INPUT_FOCUS;
};
export type AutomataEvent = SubmitData | EnterData | Focus;

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;

// Services
export const sCreateUser = (c: AutomataContext, e: AutomataEvent) =>
  createUser({
    email: c.formData.email,
    first_name: c.formData.first_name,
    last_name: c.formData.last_name,
  });

// Actions
export const aEnterData = assign({
  formData: (c: AutomataContext, e: EnterData): any => Object.assign(c.formData, { [e.field]: e.value }),
});

export const aResetData = assign({
  formData: (c: AutomataContext, e: EnterData): any => ({
    email: '',
    first_name: '',
    last_name: '',
  }),
});

export const aAddUserToStore = (c: AutomataContext, e: DoneInvokeEvent<CreateUserResponse>) =>
  Data.store.dispatch(Data.creators.users.addUserToStore(e.data));

export const aRedirectToUserDetailsPage = (c: AutomataContext, e: DoneInvokeEvent<CreateUserResponse>) =>
  History.push(Data.paths.admin.users.DETAIL(e.data.identifier));

// Config
export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'CreateUser:Machine',
  initial: States.CREATE_USER_SCREEN,
  context: {
    formData: {
      email: '',
      first_name: '',
      last_name: '',
    },
  },
  states: {
    [States.CREATE_USER_SCREEN]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['aEnterData'],
        },
        [Events.SUBMIT_DATA]: {
          target: States.CREATE_USER_REQUEST,
        },
      },
    },
    [States.CREATE_USER_REQUEST]: {
      invoke: {
        src: 'sCreateUser',
        onDone: {
          target: States.CREATE_USER_SUCCESS,
          actions: ['aResetData', 'aAddUserToStore'],
        },
        onError: {
          target: States.CREATE_USER_ERROR,
        },
      },
    },
    [States.CREATE_USER_SUCCESS]: {
      type: 'final',
      entry: ['aResetData', 'aRedirectToUserDetailsPage'],
    },
    [States.CREATE_USER_ERROR]: {
      on: {
        [Events.INPUT_FOCUS]: {
          target: States.CREATE_USER_SCREEN,
        },
      },
    },
  },
};

export const options: any = {
  services: {
    sCreateUser,
  },
  actions: {
    aResetData,
    aEnterData,
    aAddUserToStore,
    aRedirectToUserDetailsPage,
  },
};

export default Machine(config, options);
