import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';
import { UUID } from 'io-ts-types/lib/UUID';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { UserRecord } from '../../Data/users/@records/User';

export const AssignUserToBranchResponse = t.exact(
  t.type({
    branchId: UUID,
    userId: t.string,
  }),
);

export type AssignUserToBranchResponse = t.TypeOf<typeof AssignUserToBranchResponse>;

export const assignUserToBranch = (userId: string, branchId: string): Promise<AxiosResponse<AssignUserToBranchResponse>> =>
  axios.post(Endpoints.users.ASSIGN_USER_TO_BRANCH(), { branchId, userId }, Data.async.authorisationHeaders());

export default async (userId: string, branchId: string) =>
  await assignUserToBranch(userId, branchId).then(getDataFromAxios.get).then(tp.decode(AssignUserToBranchResponse));
