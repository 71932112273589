import * as React from 'react';

import Users from './Users';
import AddUser from './Users/Add';
import Looks from './Looks';
import AddLook from './Looks/Add';
import { Events } from '../../../../../../Machines/organisations/update';
import { Props } from './@types';
import { BranchRecord } from '../../../../../../Data/organisations/@records/Branch';

export const generateBranchInput = (branch: Props['branch']) => (branch.branch_id === null ? '' : branch.branch_id);

export const updateBranchDetails = (send: Props['send'], branchId: string, field: 'name' | 'branch_id') => (e: React.ChangeEvent<HTMLInputElement>) =>
  send({
    type: Events.MODIFY_BRANCH_DETAILS,
    branchId: branchId,
    field: field,
    value: e.target.value,
  });

export const updateTransactDetails =
  (send: Props['send'], branchId: string, field: 'is_active' | 'branch_code' | 'security_pin') => (e: React.ChangeEvent<HTMLInputElement>) =>
    send({
      type: Events.MODIFY_TRANSACT_DETAILS,
      branchId: branchId,
      field: field,
      value: e.target.value as unknown as number | boolean | null,
    });

export const submitUpdatedBranchDetails = (send: Props['send'], branch: Props['branch']) => (e: React.MouseEvent<HTMLButtonElement>) =>
  send({
    type: Events.SUBMIT_UPDATED_BRANCH_DETAILS,
    identifier: branch.identifier,
    branch_id: branch.branch_id || '',
    name: branch.name,
  });

export const reloadDetails = (send: Props['send']) => () => send(Events.RELOAD_ORGANISATION_DATA);

export const TableBreak = () => (
  <tr className="bg-gray-300 h-3 my-2">
    <td />
    <td />
    <td />
  </tr>
);

export default ({ current, send, branch }: Props) => {
  const [showAddUser, setAddUser] = React.useState(false);
  const [showAddLook, setAddLook] = React.useState(false);
  const branchRecord = BranchRecord(branch);

  const updateTransact = () => send({ type: Events.UPDATE_TRANSACT, branch: branch });

  console.log(branch);
  console.log(branch.transact.branch_code);
  console.log(branch.transact.security_pin);

  return (
    <div className="hover:bg-grey-lighter p-4 border-2 rounded-lg border-gray-400 m-3">
      <div className="py-4 px-6 border-b border-grey-light text-lg">
        <h1 className="text-2xl uppercase">{branch.name}</h1>
        <table className="w-full">
          <thead>
            <tr>
              <th className="w-1/6 text-left" />
              <th className="w-3/6 text-left">Value</th>
              <th className="w-2/6 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <TableBreak />

            <tr>
              <td className="text-pink-500 uppercase">DETAILS</td>
              <td />
              <td />
            </tr>

            <tr>
              <td>Name</td>
              <td>
                <input
                  type="text"
                  className="w-full text-input-inline"
                  onChange={updateBranchDetails(send, branch.identifier, 'name')}
                  placeholder="Branch ID"
                  value={branch.name}
                />
              </td>
              <td className="align-middle">
                <button
                  type="button"
                  onClick={submitUpdatedBranchDetails(send, branch)}
                  className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-liven-pastel hover:bg-liven-hot transition-250 hover:text-white"
                >
                  Update
                </button>
              </td>
            </tr>

            <tr>
              <td>Branch Id</td>
              <td>
                <input
                  type="text"
                  className="w-full text-input-inline"
                  onChange={updateBranchDetails(send, branch.identifier, 'branch_id')}
                  placeholder="Branch ID"
                  value={generateBranchInput(branch)}
                />
              </td>
              <td>
                <button
                  type="button"
                  onClick={submitUpdatedBranchDetails(send, branch)}
                  className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-liven-pastel hover:bg-liven-hot transition-250 hover:text-white"
                >
                  Update
                </button>
              </td>
            </tr>

            <TableBreak />

            <tr>
              <td className="text-pink-500 uppercase">Transact</td>
              <td />
              <td />
            </tr>

            <tr>
              <tr></tr>
              <td>Branch Code</td>
              <td>
                <input
                  type="text"
                  className="w-full text-input-inline"
                  onChange={updateTransactDetails(send, branch.identifier, 'branch_code')}
                  value={branch.transact.branch_code?.toString()}
                />
              </td>
            </tr>

            <tr>
              <tr></tr>
              <td>Security PIN</td>
              <td>
                <input
                  type="text"
                  className="w-full text-input-inline"
                  onChange={updateTransactDetails(send, branch.identifier, 'security_pin')}
                  value={branch.transact.security_pin?.toString()}
                />
              </td>
            </tr>

            <tr>
              <tr></tr>
              <td>Is Active</td>
              <td>{branch.transact.is_active === true ? 'Yes' : 'No'}</td>
            </tr>

            <tr>
              <tr></tr>
              <td></td>
              <td>
                <button
                  onClick={updateTransact}
                  className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-red-200 hover:bg-red-500 transition-250"
                >
                  Save
                </button>
              </td>
            </tr>

            <TableBreak />

            <tr>
              <td className="text-pink-500 uppercase">Users</td>
              <td />
              <td>
                <button
                  className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-green-200 hover:bg-green-500 transition-250 hover:text-white"
                  onClick={() => setAddUser(true)}
                >
                  Add User
                </button>
              </td>
            </tr>

            <Users users={branch.users} send={send} branch={branch} />

            {showAddUser && <AddUser branch={branchRecord} toggleModal={setAddUser} reloadDetails={reloadDetails(send)} />}

            <TableBreak />

            <tr>
              <td className="text-pink-500 uppercase">Looks</td>
              <td />
              <td>
                <button
                  className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-green-200 hover:bg-green-500 transition-250 hover:text-white"
                  onClick={() => setAddLook(true)}
                >
                  Add Look
                </button>
              </td>
            </tr>

            <Looks looks={branch.looks} send={send} branch={branch} current={current} />

            {showAddLook && <AddLook branch={branchRecord} toggleModal={setAddLook} reloadDetails={reloadDetails(send)} />}
          </tbody>
        </table>
        <div></div>
      </div>
    </div>
  );
};
