import React from 'react';

import Form from './Form';
import { AutomataService } from '../../../../Machines/authentication/login';

type Props = {
  service: AutomataService;
};

export default ({ service }: Props) => <Form service={service} isDisabled={false} />;
