import * as React from 'react';

type Props = {
  className?: string;
};

export const ChevronIcon = ({ className }: Props) => (
  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M7.14246 13.9998C6.95395 13.9991 6.76754 13.9603 6.59439 13.8858C6.42125 13.8112 6.26496 13.7025 6.13489 13.566L0.733161 7.96837C0.476752 7.70678 0.33313 7.35508 0.33313 6.98878C0.33313 6.62248 0.476752 6.27079 0.733161 6.0092L6.3308 0.411555C6.46128 0.281076 6.61618 0.177575 6.78666 0.10696C6.95714 0.0363453 7.13986 2.74964e-09 7.32438 0C7.50891 -2.74964e-09 7.69163 0.0363453 7.86211 0.10696C8.03259 0.177575 8.18749 0.281076 8.31797 0.411555C8.44844 0.542034 8.55195 0.696935 8.62256 0.867414C8.69318 1.03789 8.72952 1.22061 8.72952 1.40514C8.72952 1.58966 8.69318 1.77238 8.62256 1.94286C8.55195 2.11334 8.44844 2.26824 8.31797 2.39872L3.69991 7.00278L8.15004 11.6208C8.41068 11.883 8.55697 12.2377 8.55697 12.6074C8.55697 12.9771 8.41068 13.3318 8.15004 13.594C8.01761 13.7254 7.86026 13.829 7.68723 13.8987C7.5142 13.9684 7.32898 14.0028 7.14246 13.9998Z" />
  </svg>
);

export default ChevronIcon;
