import * as React from 'react';

type Props = {
  className?: string;
  type: 'visible' | 'visible-off';
};
export const EyeIcon = ({ className, type }: Props) => (
  <>
    {type === 'visible' && (
      <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.19048 0.666666C3.3208 0.666666 0.17527 5.5793 0.118378 5.66875C0.0418006 5.76297 8.27908e-05 5.8797 0 6C9.68678e-05 6.1032 0.0308453 6.20415 0.0885169 6.29062C0.0892241 6.29167 0.0899351 6.29271 0.0906498 6.29375C0.100038 6.31267 2.74044 11.3333 8.19048 11.3333C13.6174 11.3333 16.2539 6.36199 16.286 6.30104C16.2882 6.29759 16.2903 6.29412 16.2924 6.29062C16.3501 6.20415 16.3809 6.1032 16.381 6C16.3809 5.88018 16.3396 5.76386 16.2636 5.66979C16.2633 5.66944 16.2629 5.6691 16.2626 5.66875C16.2057 5.5793 13.0601 0.666666 8.19048 0.666666ZM8.19048 2.26667C10.3014 2.26667 12.0127 3.93813 12.0127 6C12.0127 8.06187 10.3014 9.73333 8.19048 9.73333C6.07952 9.73333 4.36825 8.06187 4.36825 6C4.36825 3.93813 6.07952 2.26667 8.19048 2.26667ZM8.19048 4.4C7.75603 4.4 7.33937 4.56857 7.03217 4.86863C6.72497 5.16869 6.55238 5.57565 6.55238 6C6.55238 6.42435 6.72497 6.83131 7.03217 7.13137C7.33937 7.43143 7.75603 7.6 8.19048 7.6C8.62493 7.6 9.04158 7.43143 9.34878 7.13137C9.65599 6.83131 9.82857 6.42435 9.82857 6C9.82857 5.57565 9.65599 5.16869 9.34878 4.86863C9.04158 4.56857 8.62493 4.4 8.19048 4.4Z"
          fill="#1D1D1F"
        />
      </svg>
    )}
    {type === 'visible-off' && (
      <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 24 24" width="17">
        <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
      </svg>
    )}
  </>
);

export default EyeIcon;
