import * as React from 'react';

import { AutomataContext, Events, Send } from '../../../../../Machines/users/update';

export const sendUserData =
  (send: Send, field: keyof AutomataContext['formData'], valueOveride: any = null) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('Checkbox clickevent: ', valueOveride);
    return send({
      type: Events.ENTER_DATA,
      field: field,
      value: valueOveride === null ? e.target.value : valueOveride,
    });
  };

type Props = {
  isDisabled: boolean;
  context: AutomataContext;
  send: Send;
};

export default ({ isDisabled, context, send }: Props) => (
  <div className="w-5/6 px-6 py-4 mx-auto my-6 rounded shadow-md">
    <input
      value={context.formData.email}
      onChange={sendUserData(send, 'email')}
      className="mb-4 text-input"
      type="email"
      placeholder="Email"
      disabled={isDisabled}
    />

    <input
      value={context.formData.first_name}
      onChange={sendUserData(send, 'first_name')}
      className="mb-4 text-input"
      type="text"
      placeholder="First Name"
      disabled={isDisabled}
    />

    <input
      value={context.formData.last_name}
      onChange={sendUserData(send, 'last_name')}
      className="mb-4 text-input"
      type="text"
      placeholder="Last Name"
      disabled={isDisabled}
    />

    <input
      value={context.formData.password1}
      onChange={sendUserData(send, 'password1')}
      className="mb-4 text-input"
      type="text"
      placeholder="Password"
      disabled={isDisabled}
    />

    <input
      value={context.formData.password2}
      onChange={sendUserData(send, 'password2')}
      className="mb-4 text-input"
      type="text"
      placeholder="Repeat Password"
      disabled={isDisabled}
    />

    <div className="py-4">
      <label className="pr-2">Is Admin</label>
      {context.formData.is_admin ? (
        <input onChange={sendUserData(send, 'is_admin', false)} type="checkbox" disabled={isDisabled} checked />
      ) : (
        <input onChange={sendUserData(send, 'is_admin', true)} type="checkbox" disabled={isDisabled} />
      )}
    </div>
    <div className="flex justify-start">
      <button disabled={isDisabled} onClick={() => send(Events.SUBMIT_DATA)} className="mb-4 btn" type="button">
        Update User
      </button>
    </div>
  </div>
);
