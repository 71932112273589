import * as t from 'io-ts';

export const ListResponse = <C extends t.Mixed>(codec: C) =>
  t.type({
    page_size: t.number,
    current_page: t.number,
    record_size: t.number,
    record_list: t.array(codec),
  });

export const DetailResponse = <C extends t.Mixed>(codec: C) =>
  t.type({
    identifier: t.string,
    date_created: t.string,
    date_modified: t.string,
    changes: t.number,
    resource: t.exact(
      t.type({
        identifier: t.string,
        is_archived: t.boolean,
        date_archived: t.union([t.null, t.string]),
      }),
    ),
    created_by: t.union([
      t.null,
      t.exact(
        t.type({
          identifier: t.string,
          first_name: t.string,
          last_name: t.string,
        }),
      ),
    ]),
    modified_by: t.union([
      t.null,
      t.exact(
        t.type({
          identifier: t.string,
          first_name: t.string,
          last_name: t.string,
        }),
      ),
    ]),
    record: codec,
  });

export default {
  DetailResponse,
  ListResponse,
};
