import Data from '../../Data';
import Async from '../../Async';
import { Machine, Interpreter, MachineConfig, assign, DoneInvokeEvent } from 'xstate';
import { UserRecord } from '../../Data/users/@records/User';
import { UUID } from 'io-ts-types/lib/UUID';
import { GetUserBranchesListResponse } from '../../Async/Users/list_branches';
import { Lens } from 'monocle-ts';
import { SearchBranchListResponse } from '../../Async/Branches/search';

// States
export enum States {
  MACHINE_SETUP = 'MACHINE_SETUP',
  GET_USER_BRANCHES_REQUEST = 'GET_USER_BRANCHES_REQUEST',
  GET_USER_BRANCHES_SUCCESS = 'GET_USER_BRANCHES_SUCCESS',
  GET_USER_BRANCHES_ERROR = 'GET_USER_BRANCHES_ERROR',

  REMOVE_USER_BRANCHES_REQUEST = 'REMOVE_USER_BRANCHES_REQUEST',
  REMOVE_USER_BRANCHES_ERROR = 'REMOVE_USER_BRANCHES_ERROR',

  SEARCH_BRANCH_SCREEN = 'SEARCH_BRANCH_SCREEN',
  SEARCH_BRANCH_REQUEST = 'SEARCH_BRANCH_REQUEST',
  SEARCH_BRANCH_ERROR = 'SEARCH_BRANCH_ERROR',

  ASSIGN_USER_TO_BRANCH_REQUEST = 'ASSIGN_USER_TO_BRANCH_REQUEST',
  ASSIGN_USER_TO_BRANCH_ERROR = 'ASSIGN_USER_TO_BRANCH_ERROR',
}

export type AutomataStates = {
  states: {
    [States.MACHINE_SETUP]: {};
    [States.GET_USER_BRANCHES_REQUEST]: {};
    [States.GET_USER_BRANCHES_SUCCESS]: {};
    [States.GET_USER_BRANCHES_ERROR]: {};

    [States.REMOVE_USER_BRANCHES_REQUEST]: {};
    [States.REMOVE_USER_BRANCHES_ERROR]: {};

    [States.SEARCH_BRANCH_SCREEN]: {};
    [States.SEARCH_BRANCH_REQUEST]: {};
    [States.SEARCH_BRANCH_ERROR]: {};

    [States.ASSIGN_USER_TO_BRANCH_REQUEST]: {};
    [States.ASSIGN_USER_TO_BRANCH_ERROR]: {};
  };
};

// Context
export type AutomataContext = {
  userId: UUID | '';
  search: {
    term: string;
    record_size: number;
    record_list: SearchBranchListResponse['record_list'];
  };
  data: {
    record_size: number;
    record_list: GetUserBranchesListResponse['record_list'];
  };
};

export const AutomataContext = (): AutomataContext => ({
  userId: '',
  search: {
    term: '',
    record_size: 0,
    record_list: [],
  },
  data: {
    record_size: 0,
    record_list: [],
  },
});

// Events
export enum Events {
  SET_USER_ID = 'SET_USER_ID',
  LOAD_BRANCHES = 'LOAD_BRANCHES',
  REMOVE_BRANCH = 'REMOVE_BRANCH',
  SEARCH_BRANCHES = 'SEARCH_BRANCHES',
  SEARCH_TERM_UPDATED = 'SEARCH_TERM_UPDATED',
  ADD_NEW_BRANCH = 'ADD_NEW_BRANCH',
  CLOSE_MODAL = 'CLOSE_MODAL',
  RESET_STATE = 'RESET_STATE',
  ASSIGN_USER_TO_BRANCH = 'ASSIGN_USER_TO_BRANCH',
}

export type SetUserId = { type: Events.SET_USER_ID; data: string };
export type LoadBranches = { type: Events.LOAD_BRANCHES };
export type RemoveBranch = { type: Events.REMOVE_BRANCH; branchId: string };
export type SearchBranches = { type: Events.SEARCH_BRANCHES };
export type SearchTermUpdated = {
  type: Events.SEARCH_TERM_UPDATED;
  term: string;
};
export type AddNewBranch = { type: Events.ADD_NEW_BRANCH };
export type CloseModal = { type: Events.CLOSE_MODAL };
export type ResetState = { type: Events.RESET_STATE };
export type AssignUserToBranch = {
  type: Events.ASSIGN_USER_TO_BRANCH;
  branchId: string;
};
export type AutomataEvent =
  | LoadBranches
  | SetUserId
  | RemoveBranch
  | SearchBranches
  | AddNewBranch
  | SearchTermUpdated
  | CloseModal
  | ResetState
  | AssignUserToBranch;

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;

// Services
export const getUserBranches = async (c: AutomataContext, e: AutomataEvent) => {
  const user = Data.store.getState().users.get(c.userId, UserRecord({ identifier: c.userId }));
  return await Async.users.list_branches(user);
};

export const removeUserBranch = async (c: AutomataContext, e: RemoveBranch) => {
  const user = Data.store.getState().users.get(c.userId, UserRecord({ identifier: c.userId }));
  return await Async.users.remove_branch(user, e.branchId);
};

export const assignUserBranch = async (c: AutomataContext, e: AssignUserToBranch) => await Async.users.assign_branch(c.userId, e.branchId);

export const searchBranches = async (c: AutomataContext, e: SearchBranches) => await Async.branches.search(5, c.search.term);

// Actions
export const setUserId = assign((c: AutomataContext, e: SetUserId) => Object.assign(c, { userId: e.data }));

export const assignBranchesToContext = assign(
  (c: AutomataContext, e: DoneInvokeEvent<GetUserBranchesListResponse>): AutomataContext =>
    Object.assign(c, {
      data: {
        record_list: e.data.record_list,
        record_count: e.data.record_size,
      },
    }),
);

export const assignSearchTermToContext = assign(
  (c: AutomataContext, e: SearchTermUpdated): AutomataContext => Lens.fromPath<AutomataContext>()(['search', 'term']).set(e.term)(c),
);

const assignSearchResultsToContext = assign(
  (c: AutomataContext, e: DoneInvokeEvent<SearchBranchListResponse>): AutomataContext =>
    Lens.fromPath<AutomataContext>()(['search', 'record_list']).set(e.data.record_list)(c),
);

const resetSearchResults = assign(
  (c: AutomataContext, e: DoneInvokeEvent<SearchBranchListResponse>): AutomataContext =>
    Lens.fromPath<AutomataContext>()(['search']).set(AutomataContext().search)(c),
);

// Config
export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'UserDetailResponse:Detail:Branch:Machine',
  initial: States.MACHINE_SETUP,
  context: AutomataContext(),
  states: {
    [States.MACHINE_SETUP]: {
      on: {
        [Events.SET_USER_ID]: {
          actions: ['setUserId'],
          target: States.GET_USER_BRANCHES_REQUEST,
        },
      },
    },
    [States.GET_USER_BRANCHES_REQUEST]: {
      invoke: {
        src: 'getUserBranches',
        onDone: {
          target: States.GET_USER_BRANCHES_SUCCESS,
          actions: ['assignBranchesToContext'],
        },
        onError: {
          target: States.GET_USER_BRANCHES_ERROR,
        },
      },
    },
    [States.GET_USER_BRANCHES_SUCCESS]: {
      on: {
        [Events.REMOVE_BRANCH]: {
          target: States.REMOVE_USER_BRANCHES_REQUEST,
        },
        [Events.ADD_NEW_BRANCH]: {
          target: States.SEARCH_BRANCH_SCREEN,
        },
      },
    },

    [States.GET_USER_BRANCHES_ERROR]: {
      on: {
        [Events.LOAD_BRANCHES]: {
          target: States.GET_USER_BRANCHES_REQUEST,
        },
      },
    },

    [States.REMOVE_USER_BRANCHES_REQUEST]: {
      invoke: {
        src: 'removeUserBranch',
        onDone: {
          target: States.GET_USER_BRANCHES_SUCCESS,
          actions: ['assignBranchesToContext'],
        },
      },
    },

    [States.REMOVE_USER_BRANCHES_ERROR]: {},

    // Search branches
    [States.SEARCH_BRANCH_SCREEN]: {
      on: {
        [Events.SEARCH_TERM_UPDATED]: {
          target: States.SEARCH_BRANCH_SCREEN,
          actions: ['assignSearchTermToContext'],
        },
        [Events.SEARCH_BRANCHES]: {
          target: States.SEARCH_BRANCH_REQUEST,
        },
        [Events.CLOSE_MODAL]: {
          target: States.GET_USER_BRANCHES_SUCCESS,
          actions: ['resetSearchResults'],
        },
        [Events.ASSIGN_USER_TO_BRANCH]: {
          target: States.ASSIGN_USER_TO_BRANCH_REQUEST,
        },
      },
    },
    [States.SEARCH_BRANCH_REQUEST]: {
      invoke: {
        src: 'searchBranches',
        onDone: {
          target: States.SEARCH_BRANCH_SCREEN,
          actions: ['assignSearchResultsToContext'],
        },
        onError: {
          target: States.SEARCH_BRANCH_ERROR,
        },
      },
    },
    [States.SEARCH_BRANCH_ERROR]: {
      on: {
        [Events.SEARCH_TERM_UPDATED]: {
          target: States.SEARCH_BRANCH_SCREEN,
          actions: ['assignSearchTermToContext'],
        },
        [Events.CLOSE_MODAL]: {
          target: States.GET_USER_BRANCHES_SUCCESS,
          actions: ['resetSearchResults'],
        },
      },
    },
    [States.ASSIGN_USER_TO_BRANCH_REQUEST]: {
      invoke: {
        src: 'assignUserBranch',
        onDone: {
          target: States.GET_USER_BRANCHES_REQUEST,
        },
        onError: {
          target: States.ASSIGN_USER_TO_BRANCH_ERROR,
        },
      },
    },
    [States.ASSIGN_USER_TO_BRANCH_ERROR]: {},
  },
};

export const options: any = {
  services: {
    getUserBranches,
    removeUserBranch,
    searchBranches,
    assignUserBranch,
  },
  actions: {
    setUserId,
    assignBranchesToContext,
    assignSearchTermToContext,
    assignSearchResultsToContext,
    resetSearchResults,
  },
};

export default Machine(config, options);
