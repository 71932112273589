import actions from './actions';
import { OrganisationResponse } from '../../Async/Organisations/list';
import { AnyAction } from 'redux';

export type SaveOrganisationToStore = AnyAction & {
  organisation: OrganisationResponse;
};
export const saveOrganisationToStore = (organisation: OrganisationResponse): SaveOrganisationToStore => ({
  type: actions.SAVE_ORGANISATION_DATA_TO_STORE,
  organisation: organisation,
});

export type SaveOrganisationsToStore = AnyAction & {
  organisations: Array<OrganisationResponse>;
};
export const saveOrganisationsToStore = (organisations: Array<OrganisationResponse>): SaveOrganisationsToStore => ({
  type: actions.SAVE_ORGANISATIONS_DATA_TO_STORE,
  organisations: organisations,
});

export default {
  saveOrganisationToStore,
  saveOrganisationsToStore,
};
