import Async from '../../Async';
import { Machine, MachineConfig, Interpreter, DoneInvokeEvent, assign } from 'xstate';
import { ListSettingResponse, SettingDetails } from '../../Async/Settings/response_models';
import { Lens } from 'monocle-ts';

// States

export enum States {
  LOAD_SETTINGS_REQUEST = 'LOAD_SETTINGS_REQUEST',
  LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS',
  LOAD_SETTINGS_ERROR = 'LOAD_SETTINGS_ERROR',
}

export type AutomataStates = {
  states: {
    [States.LOAD_SETTINGS_REQUEST]: {};
    [States.LOAD_SETTINGS_SUCCESS]: {};
    [States.LOAD_SETTINGS_ERROR]: {};
  };
};

// Events

export enum Events {
  LOAD_SETTINGS_LIST = 'LOAD_SETTINGS_LIST',
}

export type LoadSettingsData = { type: Events.LOAD_SETTINGS_LIST };
export type AutomataEvent = LoadSettingsData;

// Context

export type AutomataContext = {
  settings: Array<SettingDetails>;
};

export const AutomataContext = (): AutomataContext => ({
  settings: [],
});

// Services

export const loadSettingsList = (c: AutomataContext, e: AutomataEvent) => Async.settings.list(30, 0);

// Actions

export const assignSettingsToContext = assign((c: AutomataContext, e: DoneInvokeEvent<ListSettingResponse>) =>
  Lens.fromProp<AutomataContext>()('settings').set(e.data.record_list)(c),
);

// Config
export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'Settings:List:Machine',
  initial: States.LOAD_SETTINGS_REQUEST,
  context: AutomataContext(),
  states: {
    [States.LOAD_SETTINGS_REQUEST]: {
      invoke: {
        src: 'loadSettingsList',
        onDone: {
          target: States.LOAD_SETTINGS_SUCCESS,
          actions: ['assignSettingsToContext'],
        },
        onError: {
          target: States.LOAD_SETTINGS_ERROR,
        },
      },
    },
    [States.LOAD_SETTINGS_SUCCESS]: {},
    [States.LOAD_SETTINGS_ERROR]: {
      on: {
        [Events.LOAD_SETTINGS_LIST]: {
          target: States.LOAD_SETTINGS_REQUEST,
        },
      },
    },
  },
};

const options: any = {
  services: {
    loadSettingsList,
  },
  actions: {
    assignSettingsToContext,
  },
};

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export default Machine<AutomataContext, AutomataStates, AutomataEvent>(config, options);
