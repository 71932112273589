import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { OrganisationSearchResponse } from './response_models';

export const searchOrganisations = (term: string): Promise<AxiosResponse<OrganisationSearchResponse>> =>
  axios.post(Endpoints.organisations.SEARCH(20), { term }, Data.async.authorisationHeaders());

export default async (term: string) => await searchOrganisations(term).then(getDataFromAxios.get).then(tp.decode(OrganisationSearchResponse));
