import * as x from 'xstate';
import { pipe } from 'fp-ts/lib/pipeable';
import { Lens } from 'monocle-ts';
import Async from '../../Async';
import { TransactSessionListResponse } from '../../Async/Transact/response_models';

// States

export enum States {
  UPDATE_TRANSACT_REQUEST = 'GET_TRANSACT_SESSIONS_REQUEST',
  UPDATE_TRANSACT_SUCCESS = 'GET_TRANSACT_SESSIONS_SUCCESS',
  UPDATE_TRANSACT_ERROR = 'GET_TRANSACT_SESSIONS_ERROR',
}

export type AutomataStates = {
  states: {
    [States.UPDATE_TRANSACT_REQUEST]: {};
    [States.UPDATE_TRANSACT_SUCCESS]: {};
    [States.UPDATE_TRANSACT_ERROR]: {};
  };
};

// Context

export type AutomataContext = {
  data: {
    identifier: string;
    result: TransactSessionListResponse;
  };
};

// Events

export enum Events {
  LOAD_USERS = 'LOAD_USERS',
}

export type LoadSessions = {
  type: Events.LOAD_USERS;
};

export type AutomataEvent = LoadSessions;

// Services

export const listSessions = (c: AutomataContext, e: AutomataEvent) => Async.transact.list(c.data.identifier);

// Actions

export const assignDataToContext = x.assign((c: AutomataContext, e: x.DoneInvokeEvent<TransactSessionListResponse>) =>
  pipe(c, Lens.fromPath<AutomataContext>()(['data', 'result']).set(e.data)),
);

// Config

export const config = (identifier: string): x.MachineConfig<AutomataContext, AutomataStates, AutomataEvent> => ({
  id: 'Machine:Transact:Sessions',
  initial: States.UPDATE_TRANSACT_REQUEST,
  context: {
    data: {
      identifier: identifier,
      result: {
        count: 0,
        records: [],
      },
    },
  },
  states: {
    [States.UPDATE_TRANSACT_REQUEST]: {
      invoke: {
        src: 'listSessions',
        onDone: {
          target: States.UPDATE_TRANSACT_SUCCESS,
          actions: ['assignDataToContext'],
        },
        onError: {
          target: States.UPDATE_TRANSACT_ERROR,
        },
      },
    },
    [States.UPDATE_TRANSACT_SUCCESS]: {},
    [States.UPDATE_TRANSACT_ERROR]: {},
  },
});

export const options: any = {
  services: {
    listSessions,
  },
  actions: {
    assignDataToContext,
  },
};

export type Service = x.Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = x.State<AutomataContext, AutomataEvent>;
export type Machine = x.MachineConfig<AutomataContext, AutomataStates, AutomataEvent>;
export type Send = Service['send'];
export const machine = (identifier: string) => x.Machine(config(identifier), options);

export default machine;
