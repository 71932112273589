import * as React from 'react';
import classNames from 'classnames';

import TransactCard from './OrgCard/Transact';
import MagnifyGlassIcon from '../../../../../Static/liven/Icons/MagnifyGlassIcon';
import * as machine from '../../../../../Machines/transact/activated_branches';
import ChevronIcon from '../../../../../Static/liven/Icons/ChevronIcon';
import TimesIcon from '../../../../../Static/liven/Icons/TimesIcon';
import CaretDownIcon from '../../../../../Static/liven/Icons/CaretDownIcon';
import { useMachine } from '@xstate/react';

import { pipe } from 'fp-ts/lib/pipeable';
import * as O from 'fp-ts/lib/Option';
import * as F from 'fp-ts/lib/function';
import { Branch } from '../../../../../Async/Organisations/list';

const sidebarTransitionClass = classNames('transition-all duration-500 ease-in-out font-liven-primary');
const orgsContainerClass = classNames('flex flex-col flex-grow relative w-full overflow-auto mb-8');
const orgsSubContainerClass = classNames('absolute w-full px-5');
const dropdownContainerClass = classNames('flex items-center cursor-pointer sm:flex md:hidden p-4');
const dropdownLabelClass = classNames('mr-1 text-sm font-semibold');
const TimerIconClass = classNames('md:hidden mt-6 cursor-pointer');

const searchIconClass = classNames('inline-block w-4 text-gray-500 fill-current');

const containerClass = (isCollapsed: boolean) =>
  classNames({
    [sidebarTransitionClass]: true,
    'flex flex-col min-h-full max-h-full bg-gray-200 fixed md:relative left-0 w-full z-50': true,
    'items-start md:w-72 top-0': !isCollapsed,
    'items-center px-0 hidden md:flex md:w-34': isCollapsed,
  });

const SideContainerClass = classNames('flex md:flex-col flex-col-reverse w-full px-5');

const searchbarClass = (isCollapsed: boolean) =>
  classNames({
    [sidebarTransitionClass]: true,
    'items-center h-10 p-4 mb-5 md:mt-10 md:mb-8 text-sm text-gray-500 \
		 rounded-full w-10': true,
    'flex w-full bg-gray-300': !isCollapsed,
    'inline-block w-full text-center bg-transparent': isCollapsed,
  });

const searchbarInputClass = (isCollapsed: boolean) =>
  classNames({
    'focus: flex-grow w-full text-black bg-gray-300 outline-none': true,
    hidden: isCollapsed,
  });
const titleClass = (isCollapsed: boolean) =>
  classNames({
    [sidebarTransitionClass]: true,
    'relative flex items-center justify-between w-full mt-12 mb-5 md:mt-0 md:mb-6 ml-0 \
		text-2xl md:text-lg font-bold': true,
    'ml-2': isCollapsed,
  });
const toggleClass = (isCollapsed: boolean) =>
  classNames({
    [sidebarTransitionClass]: true,
    'w-10 h-10 bg-white rounded-full hidden md:flex items-center \
		justify-center shadow-xs leading-10 text-center font-light absolute right-0 cursor-pointer':
      true,
    '-mr-10': !isCollapsed,
    'rotate-180 -mr-8': isCollapsed,
  });
const chevronClass = (isCollapsed: boolean) =>
  classNames({
    [sidebarTransitionClass]: true,
    'fill-current': true,
    'text-gray-500 ': !isCollapsed,
    'text-liven-red transform rotate-180': isCollapsed,
  });

export default () => {
  const [isCollapsed, toggleCollapsed] = React.useState(true);

  const onItemClick = (isCollapsed: boolean) => {
    const viewportWidth = window.innerWidth;
    if (viewportWidth && viewportWidth < 768) {
      toggleCollapsed(!isCollapsed);
    }
  };

  const [current, send] = useMachine(machine.machine, { devTools: true });
  const data = pipe(
    current.context.data.result,
    O.map((v) => v.record_list),
    O.fold(F.constant([]), F.identity),
  );

  return (
    <div className="flex">
      <div className={dropdownContainerClass} onClick={() => toggleCollapsed(!isCollapsed)}>
        <CaretDownIcon />
      </div>
      <div className={containerClass(isCollapsed)}>
        <div className={SideContainerClass}>
          {/* Search */}
          <div className={searchbarClass(isCollapsed)}>
            {/* <input className={searchbarInputClass(isCollapsed)} onChange={searchLooks(service)} placeholder="Search" type="text" /> */}
            <span className={searchIconClass} onClick={() => isCollapsed && toggleCollapsed(false)}>
              <MagnifyGlassIcon />
            </span>
          </div>

          {/* Title */}
          <div className={titleClass(isCollapsed)}>
            <div>Branches</div>
            <span className={toggleClass(isCollapsed)} onClick={() => toggleCollapsed(!isCollapsed)}>
              <ChevronIcon className={chevronClass(isCollapsed)} />
            </span>
          </div>
          <div className={TimerIconClass} onClick={() => toggleCollapsed(!isCollapsed)}>
            <TimesIcon />
          </div>
        </div>

        {/* Orgs List */}
        <div className={orgsContainerClass}>
          <div className={orgsSubContainerClass}>
            {data.map((branch) => (
              <TransactCard orgName={branch.name} branch={branch} isCollapsed={isCollapsed} isSelected={false} onClick={() => {}} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
