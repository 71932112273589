import * as React from 'react';

import { CurrentState, Send } from '../../../../../Machines/organisations/create';
import Organisation from './Organisation';
import Branches from './Branches';

type Props = {
  send: Send;
  current: CurrentState;
};

export default ({ send, current }: Props) => (
  <div>
    <Organisation current={current} isDisabled={true} send={send} />
    <Branches current={current} isDisabled={true} send={send} />
  </div>
);
