import Async from '../../Async';
import { Machine, MachineConfig, Interpreter, DoneInvokeEvent, assign, State } from 'xstate';

import Fuse from 'fuse.js';

import { Lens } from 'monocle-ts';

import { pipe } from 'fp-ts/lib/pipeable';
import * as O from 'fp-ts/lib/Option';
import * as A from 'fp-ts/lib/Array';
import * as F from 'fp-ts/lib/function';

import History from '../../history';
import Data from '../../Data';
import { UserProfileResponse } from '../../Async/Users/response_models';
import { ListLooksForUserResponse, BranchWithLooksDetails } from '../../Async/Looks/get_for_user';
import { TokenResponse } from '../../Async/Looks/response_models';

// States
export enum States {
  LOAD_PROFILE_REQUEST = 'LOAD_PROFILE_REQUEST',
  LOAD_PROFILE_ERROR = 'LOAD_PROFILE_ERROR',

  LOAD_LOOKS_REQUEST = 'LOAD_LOOKS_REQUEST',
  LOAD_LOOKS_SUCCESS = 'LOAD_LOOKS_SUCCESS',
  LOAD_LOOKS_ERROR = 'LOAD_LOOKS_ERROR',

  FETCH_LOOK_TOKEN_REQUEST = 'FETCH_LOOK_TOKEN_REQUEST',
  FETCH_LOOK_TOKEN_SUCCESS = 'FETCH_LOOK_TOKEN_SUCCESS',
  FETCH_LOOK_TOKEN_ERROR = 'FETCH_LOOK_TOKEN_ERROR',
}

export type AutomataStates = {
  states: {
    [States.LOAD_PROFILE_REQUEST]: {};
    [States.LOAD_PROFILE_ERROR]: {};

    [States.LOAD_LOOKS_REQUEST]: {};
    [States.LOAD_LOOKS_SUCCESS]: {};
    [States.LOAD_LOOKS_ERROR]: {};

    [States.FETCH_LOOK_TOKEN_REQUEST]: {};
    [States.FETCH_LOOK_TOKEN_SUCCESS]: {};
    [States.FETCH_LOOK_TOKEN_ERROR]: {};
  };
};

// Events
export enum Events {
  LOAD_LOOKS_LIST = 'LOAD_LOOKS_LIST',
  FETCH_LOOK_TOKEN = 'FETCH_LOOK_TOKEN',
  ACTIVATE_BRANCH = 'ACTIVATE_BRANCH',
  LOOK_LOADED = 'LOOK_LOADED',
  SEARCH_TERM_UPDATED = 'SEARCH_TERM_UPDATED',
}

export type LoadLooksData = {
  type: Events.LOAD_LOOKS_LIST;
};
export type LoadLookToken = {
  type: Events.FETCH_LOOK_TOKEN;
  lookIdentifier: string;
};
export type ActivateBranch = {
  type: Events.ACTIVATE_BRANCH;
  branchIdentifier: string;
};
export type LookLoaded = {
  type: Events.LOOK_LOADED;
};
export type SearchKeyEntered = {
  type: Events.SEARCH_TERM_UPDATED;
  searchTerm: string;
};

export type AutomataEvent = LoadLooksData | LoadLookToken | ActivateBranch | LookLoaded | SearchKeyEntered;

// Context
export type AutomataContext = {
  userIdentifier: string;
  oneTimeToken: string;
  looks: Array<BranchWithLooksDetails>;
  activeBranch: string;
  activeLook: string;
  firstName: string;
  fullName: string;
  userInitials: string;
  lookIsLoading: boolean;
  // fuseInstance: Fuse<BranchWithLooksDetails>;
  searchResults: Array<BranchWithLooksDetails>;
};

export const FuseOptions: Fuse.IFuseOptions<BranchWithLooksDetails> = {
  keys: ['name'],
  shouldSort: false,
};

export const contextLens = Lens.fromPath<AutomataContext>();

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

// Services
export const loadUserProfile = (c: AutomataContext, e: AutomataEvent) => Async.users.profile();

export const loadLooksList = (c: AutomataContext, e: AutomataEvent) => Async.looks.get_for_user(100, 0, c.userIdentifier);

export const getLookToken = (c: AutomataContext, e: AutomataEvent) =>
  c.activeBranch !== null && Async.looks.generate_token(c.activeLook, c.activeBranch);

// Actions
export const assignProfileDataToContext = assign((c: AutomataContext, e: DoneInvokeEvent<UserProfileResponse>) =>
  pipe(
    contextLens(['userIdentifier']).set(e.data.identifier)(c),
    contextLens(['firstName']).set(e.data.record.first_name),
    contextLens(['fullName']).set(`${e.data.record.first_name || ''} ${e.data.record.last_name || ''}`),
    contextLens(['userInitials']).set(`${e.data.record.first_name?.charAt(0) || ''} ${e.data.record.last_name?.charAt(0) || ''}`),
  ),
);

export const assignLooksToContext = assign((c: AutomataContext, e: DoneInvokeEvent<ListLooksForUserResponse>) =>
  pipe(
    Lens.fromProp<AutomataContext>()('looks').set(e.data.record_list)(c),
    Lens.fromProp<AutomataContext>()('searchResults').set(e.data.record_list),
    Lens.fromProp<AutomataContext>()('activeBranch').set(e.data.record_list[0].identifier),
  ),
);

export const toggleLookLoaded = assign((c: AutomataContext, e: AutomataEvent) => pipe(c, contextLens(['lookIsLoading']).set(false)));

export const activateFirstLookForBranch = assign((c: AutomataContext) =>
  // TODO: rewrite this when I actually know what I'm doing.
  pipe(
    c.looks,
    A.findFirst((b) => b.identifier === c.activeBranch),
    O.fold(
      () => [],
      (b) => b.looks.map((l) => l.identifier),
    ),
    A.head,
    O.getOrElse(() => ''),
    Lens.fromProp<AutomataContext>()('activeLook').set,
  )(c),
);

export const assignTokenRequestFieldsToContext = assign(
  (c: AutomataContext, e: LoadLookToken): AutomataContext => Lens.fromProp<AutomataContext>()('activeLook').set(e.lookIdentifier)(c),
);

export const assignOneTimeHrefToContext = assign((c: AutomataContext, e: DoneInvokeEvent<TokenResponse>): AutomataContext => {
  const lens = Lens.fromProp<AutomataContext>();
  return lens('oneTimeToken').set(e.data.otp_token)(c);
});

export const activateBranch = assign(
  (c: AutomataContext, e: ActivateBranch): AutomataContext => Lens.fromProp<AutomataContext>()('activeBranch').set(e.branchIdentifier)(c),
);

export const redirectToDataViewPage = (c: AutomataContext, e: AutomataEvent) =>
  window.location.pathname !== Data.paths.merchant.BASE && History.push(Data.paths.merchant.BASE);

// export const initFuse = assign((c: AutomataContext, e: DoneInvokeEvent<ListLooksForUserResponse>) =>
//   Lens.fromProp<AutomataContext>()('fuseInstance').set(new Fuse(e.data.record_list, FuseOptions))(c),
// );

export const sortByNumOfBranches = (firtsEl: BranchWithLooksDetails, secondEl: BranchWithLooksDetails) => {
  if (firtsEl.num_branches > secondEl.num_branches) {
    return -1;
  }

  if (firtsEl.num_branches === secondEl.num_branches) {
    return firtsEl.name < secondEl.name ? -1 : 1;
  }

  return 0;
};

export const filterLooksForUser = assign((c: AutomataContext, e: SearchKeyEntered) =>
  pipe(
    e.searchTerm,
    O.fromPredicate((v) => v.length > 0),
    O.map((_) => c.looks),
    O.map(A.filter((look) => look.name.toLowerCase().includes(e.searchTerm.toLocaleLowerCase()))),
    O.map(contextLens(['searchResults']).set),
    O.fold(F.constant(c), (l) => l(c)),
  ),
);

export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'Machine:Looks:UserDetailResponse:List',
  initial: States.LOAD_PROFILE_REQUEST,
  context: {
    userIdentifier: '',
    oneTimeToken: '',
    activeBranch: '',
    activeLook: '',
    looks: [],
    firstName: '',
    fullName: '',
    userInitials: '',
    lookIsLoading: false,
    // fuseInstance: new Fuse([]),
    searchResults: [],
  },
  states: {
    [States.LOAD_PROFILE_REQUEST]: {
      invoke: {
        src: 'loadUserProfile',
        onDone: {
          actions: ['assignProfileDataToContext'],
          target: States.LOAD_LOOKS_REQUEST,
        },
        onError: {
          target: States.LOAD_PROFILE_ERROR,
        },
      },
    },
    [States.LOAD_PROFILE_ERROR]: {},
    [States.LOAD_LOOKS_REQUEST]: {
      invoke: {
        src: 'loadLooksList',
        onDone: {
          target: States.FETCH_LOOK_TOKEN_REQUEST,
          actions: ['assignLooksToContext', 'activateFirstLookForBranch', 'initFuse'],
        },
        onError: {
          target: States.LOAD_LOOKS_ERROR,
        },
      },
    },
    [States.LOAD_LOOKS_SUCCESS]: {
      on: {
        [Events.FETCH_LOOK_TOKEN]: {
          actions: ['assignTokenRequestFieldsToContext'],
          target: States.FETCH_LOOK_TOKEN_REQUEST,
        },
        [Events.SEARCH_TERM_UPDATED]: {
          actions: ['filterLooksForUser'],
        },
      },
    },
    [States.LOAD_LOOKS_ERROR]: {},

    [States.FETCH_LOOK_TOKEN_REQUEST]: {
      entry: assign<AutomataContext>({
        lookIsLoading: true,
      }),
      invoke: {
        src: 'getLookToken',
        onDone: {
          target: States.FETCH_LOOK_TOKEN_SUCCESS,
          actions: ['assignOneTimeHrefToContext'],
        },
        onError: {
          target: States.FETCH_LOOK_TOKEN_ERROR,
        },
      },
      on: {
        [Events.FETCH_LOOK_TOKEN]: {
          actions: ['assignTokenRequestFieldsToContext'],
          target: States.FETCH_LOOK_TOKEN_REQUEST,
        },
      },
    },
    [States.FETCH_LOOK_TOKEN_SUCCESS]: {
      on: {
        [Events.LOOK_LOADED]: {
          target: States.LOAD_LOOKS_SUCCESS,
          actions: (c, e) =>
            assign<AutomataContext>({
              lookIsLoading: false,
            }),
        },
        [Events.FETCH_LOOK_TOKEN]: {
          actions: ['assignTokenRequestFieldsToContext'],
          target: States.FETCH_LOOK_TOKEN_REQUEST,
        },
      },
    },
    [States.FETCH_LOOK_TOKEN_ERROR]: {},
  },
  on: {
    [Events.ACTIVATE_BRANCH]: {
      actions: ['redirectToDataViewPage', 'activateBranch', 'activateFirstLookForBranch'],
      target: States.FETCH_LOOK_TOKEN_REQUEST,
    },
  },
};

const options: any = {
  services: {
    loadUserProfile,
    loadLooksList,
    getLookToken,
  },
  actions: {
    assignLooksToContext,
    assignProfileDataToContext,
    assignTokenRequestFieldsToContext,
    assignOneTimeHrefToContext,
    activateBranch,
    redirectToDataViewPage,
    activateFirstLookForBranch,
    // initFuse,
    filterLooksForUser,
  },
};

export default Machine<AutomataContext, AutomataStates, AutomataEvent>(config, options);
