import * as F from 'fp-ts/lib/function';
import * as B from 'fp-ts/lib/boolean';
import * as React from 'react';

import Login from './Authentication/Login';
import { useAppSelector } from '../../hooks';

type Props = {
  Route: JSX.Element;
};

export default (props: Props): JSX.Element =>
  F.pipe(
    useAppSelector((state) => state.authentication.credentials.isAuthenticated),
    B.fold(F.constant(<Login />), F.constant(props.Route)),
  );
