import list from './list';
import create from './create';
import detail from './detail';
import search from './search';
import update from './update';
import get_for_user from './get_for_user';
import generate_token from './generate_token';

export default {
  list,
  create,
  detail,
  search,
  update,
  get_for_user,
  generate_token,
};
