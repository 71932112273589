import Data from '../../Data';
import Async from '../../Async';
import History from '../../history';

import { Either, isLeft, isRight, fold } from 'fp-ts/lib/Either';
import { Errors } from 'io-ts';
import { Lens } from 'monocle-ts';
import { pipe } from 'fp-ts/lib/pipeable';

import { Machine, Interpreter, MachineConfig, assign, DoneInvokeEvent } from 'xstate';

import extractIdentifierFromPath, { Match } from '../utilities/extractIdentifierFromPath';
import { CreateUserResponse } from '../../Async/Users/create';
import { UserRecord } from '../../Data/users/@records/User';
import { UserUUID } from '../../Data/users/@types';
import { UserDetailResponse } from '../../Async/Users/response_models';

// States
export enum States {
  GET_USER_ID_FROM_LOCATION_PROPS = 'GET_USER_ID_FROM_LOCATION_PROPS',
  USER_ID_PROP_INVALID = 'USER_ID_PROP_INVALID',
  GET_USER_FROM_STORE = 'GET_USER_FROM_STORE',
  GET_USER_FROM_STORE_ERROR = 'GET_USER_FROM_STORE_ERROR',
  UPDATE_USER_SCREEN = 'UPDATE_USER_SCREEN',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',

  TERMINATE_AND_RETURN_TO_USER_HOMEPAGE = 'TERMINATE_AND_RETURN_TO_USER_HOMEPAGE',
}

export type AutomataStates = {
  states: {
    [States.GET_USER_ID_FROM_LOCATION_PROPS]: {};
    [States.USER_ID_PROP_INVALID]: {};
    [States.GET_USER_FROM_STORE]: {};
    [States.GET_USER_FROM_STORE_ERROR]: {};
    [States.UPDATE_USER_SCREEN]: {};
    [States.UPDATE_USER_REQUEST]: {};
    [States.UPDATE_USER_SUCCESS]: {};
    [States.UPDATE_USER_ERROR]: {};
    [States.TERMINATE_AND_RETURN_TO_USER_HOMEPAGE]: {};
  };
};

// Context
export type AutomataContext = {
  userIdE: Either<Errors, Match>;
  userId: UserUUID;
  formData: {
    email: string;
    first_name: string;
    last_name: string;
    password1: string;
    password2: string;
    is_admin: boolean;
    branches: Array<{
      name: string;
      identifier: string;
    }>;
  };
};

// Events
export enum Events {
  SUBMIT_DATA = 'SUBMIT_DATA',
  ENTER_DATA = 'ENTER_DATA',
  INPUT_FOCUS = 'INPUT_FOCUS',
  REFRESH = 'REFRESH',
}

export type SubmitData = {
  type: Events.SUBMIT_DATA;
};

export type EnterData = {
  type: Events.ENTER_DATA;
  value: string;
  field: keyof AutomataContext['formData'];
};

export type Focus = {
  type: Events.INPUT_FOCUS;
};
export type Refresh = {
  type: Events.REFRESH;
};

export type AutomataEvent = SubmitData | EnterData | Focus | Refresh;

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type Send = AutomataService['send'];

// Services
export const getUserDetails = (c: AutomataContext, e: AutomataEvent) => Async.users.details(c.userId);

export const updateUserDetails = async (c: AutomataContext, e: AutomataEvent) => {
  const record = Data.store.getState().users.users.get(c.userId, UserRecord());
  return await Async.users.update(record, {
    email: c.formData.email,
    details: {
      first_name: c.formData.first_name,
      last_name: c.formData.last_name,
    },
    password: {
      password1: c.formData.password1,
      password2: c.formData.password2,
    },
    is_admin: c.formData.is_admin,
  });
};

// Actions
export const getUserIdE = assign((c: AutomataContext, e: AutomataEvent): AutomataContext => {
  const valueE = extractIdentifierFromPath(Data.paths.admin.users.DETAIL_ROUTE);
  return Object.assign(c, { userIdE: valueE });
});

export const assignUserIdFromE = assign((c: AutomataContext, e: AutomataEvent): AutomataContext => {
  const userId = fold(
    (e: Errors): any => null,
    (a: Match) => a.params.identifier,
  )(c.userIdE);
  return Object.assign(c, { userId: userId });
});

export const saveUserDataToContext = assign((c: AutomataContext, e: DoneInvokeEvent<UserDetailResponse>): AutomataContext => {
  const lens = Lens.fromPath<AutomataContext>();

  return pipe(
    lens(['formData', 'email']).set(e.data.record.email)(c),
    lens(['formData', 'first_name']).set(e.data.record.details.first_name),
    lens(['formData', 'last_name']).set(e.data.record.details.last_name || ''),
    lens(['formData', 'is_admin']).set(e.data.record.is_admin),
  );
});

export const enterData = assign({
  formData: (c: AutomataContext, e: EnterData): any => Object.assign(c.formData, { [e.field]: e.value }),
});

export const aResetData = assign({
  formData: (c: AutomataContext, e: EnterData): any => ({
    email: '',
    first_name: '',
    last_name: '',
  }),
});

export const clearPasswordFields = assign({
  formData: (c: AutomataContext, e: EnterData): any => ({
    ...c.formData,
    password1: '',
    password2: '',
  }),
});

export const redirectToUserHomePage = (c: AutomataContext, e: DoneInvokeEvent<CreateUserResponse>) => History.push(Data.paths.admin.users.BASE);

export const saveUserToStore = (c: AutomataContext, e: DoneInvokeEvent<UserDetailResponse>) =>
  Data.store.dispatch(Data.creators.users.addUserToStore(e.data.record));

export const aRedirectToUserDetailsPage = (c: AutomataContext, e: DoneInvokeEvent<CreateUserResponse>) =>
  History.push(Data.paths.admin.users.DETAIL(e.data.identifier));

// conditions
export const userIdIsLeft = (c: AutomataContext, e: AutomataEvent): boolean => isLeft(c.userIdE);

export const userIdIsRight = (c: AutomataContext, e: AutomataEvent): boolean => isRight(c.userIdE);

// Config
export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'UserDetailResponse:Detail:Machine',
  initial: States.GET_USER_ID_FROM_LOCATION_PROPS,
  context: {
    userIdE: null as any,
    userId: '',
    formData: {
      email: '',
      first_name: '',
      last_name: '',
      password1: '',
      password2: '',
      is_admin: false,
      branches: [],
    },
  },
  states: {
    [States.GET_USER_ID_FROM_LOCATION_PROPS]: {
      entry: ['getUserIdE'],
      on: {
        '': [
          {
            cond: 'userIdIsLeft',
            target: States.USER_ID_PROP_INVALID,
          },
          {
            cond: 'userIdIsRight',
            target: States.GET_USER_FROM_STORE,
            actions: ['assignUserIdFromE'],
          },
        ],
      },
    },
    [States.USER_ID_PROP_INVALID]: {
      after: {
        2000: {
          target: States.TERMINATE_AND_RETURN_TO_USER_HOMEPAGE,
        },
      },
    },
    [States.GET_USER_FROM_STORE]: {
      invoke: {
        src: 'getUserDetails',
        onDone: {
          target: States.UPDATE_USER_SCREEN,
          actions: ['saveUserDataToContext', 'saveUserToStore'],
        },
        onError: {
          target: States.GET_USER_FROM_STORE_ERROR,
          actions: [(c, e) => console.log({ e, c })],
        },
      },
    },
    [States.GET_USER_FROM_STORE_ERROR]: {},
    [States.UPDATE_USER_SCREEN]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['enterData'],
        },
        [Events.SUBMIT_DATA]: {
          target: States.UPDATE_USER_REQUEST,
        },
      },
    },
    [States.UPDATE_USER_REQUEST]: {
      invoke: {
        src: 'updateUserDetails',
        onDone: {
          target: States.UPDATE_USER_SUCCESS,
          actions: ['saveUserToStore', 'clearPasswordFields'],
        },
        onError: {
          target: States.UPDATE_USER_ERROR,
          actions: ['clearPasswordFields'],
        },
      },
    },
    [States.UPDATE_USER_SUCCESS]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['enterData'],
        },
        [Events.SUBMIT_DATA]: {
          target: States.UPDATE_USER_REQUEST,
        },
      },
    },
    [States.UPDATE_USER_ERROR]: {
      on: {
        [Events.INPUT_FOCUS]: {
          target: States.UPDATE_USER_SCREEN,
        },
      },
    },

    [States.TERMINATE_AND_RETURN_TO_USER_HOMEPAGE]: {
      type: 'final',
      entry: ['redirectToUserHomePage'],
    },
  },
  on: {
    [Events.REFRESH]: {
      target: States.GET_USER_ID_FROM_LOCATION_PROPS,
    },
  },
};

// Options
export const options: any = {
  services: {
    getUserDetails,
    updateUserDetails,
  },
  actions: {
    getUserIdE,
    assignUserIdFromE,
    aResetData,
    clearPasswordFields,
    enterData,
    saveUserDataToContext,
    saveUserToStore,
    aRedirectToUserDetailsPage,
    redirectToUserHomePage,
  },
  guards: {
    userIdIsLeft,
    userIdIsRight,
  },
};

export default Machine(config, options);
