import { UserRecord } from '../Data/users/@records/User';
import { UserUUID } from '../Data/users/@types';
import * as brands from '../brands';

const host = `${process.env.REACT_APP_BACKEND_PROTOCOL}://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}`;

const sections = {
  admin: {
    settings: 'admin/settings',
  },
  auth: 'auth',
  docs: 'docs',
  users: 'users',
  looks: 'looks',
  organisations: 'organisations',
  transact: 'transact',
};

export default {
  authentication: {
    LOGIN: () => `${host}/${sections.auth}/login`,
    LOGOUT: `${host}/${sections.auth}/logout`,
    GET_SCOPES: () => `${host}/${sections.auth}/scopes`,
    RESET_PASSWORD: () => `${host}/${sections.auth}/password/reset`,
    VALIDATE_TOKEN: () => `${host}/${sections.auth}/validate-token`,
  },

  documentation: {
    SWAGGER: `${host}/${sections.docs}/swagger`,
    REDOC: `${host}/${sections.docs}/redoc`,
  },

  users: {
    LIST: (pageSize: number, currentPage: number) => `${host}/${sections.users}/?page_size=${pageSize}&current_page=${currentPage}`,

    DETAILS: (identifier: UserUUID) => `${host}/${sections.users}/${identifier}`,

    CREATE: () => `${host}/${sections.users}`,

    PROFILE: () => `${host}/users/profile`,

    SEARCH: () => `${host}/${sections.users}/search/all`,

    UPDATE: (user: UserRecord) => `${host}/${sections.users}/${user.identifier}`,

    BRANCHES: (user: UserRecord) => `${host}/${sections.users}/${user.identifier}/branches`,

    ASSIGN_USER_TO_BRANCH: () => `${host}/${sections.users}/branch/assign`,

    REMOVE_USER_FROM_BRANCH: (user: UserRecord) => `${host}/${sections.users}/${user.identifier}/branch/remove`,

    CHANGE_PASSWORD: () => `${host}/${sections.users}/password/change`,
  },

  admin: {
    settings: {
      LIST: (pageSize: number, currentPage: number) => `${host}/${sections.admin.settings}?page_size=${pageSize}&current_page=${currentPage}`,

      DETAILS: (identifier: string) => `${host}/${sections.admin.settings}/${identifier}`,
    },
  },

  looks: {
    CREATE: () => `${host}/${sections.looks}`,

    DETAIL: (identifier: string) => `${host}/${sections.looks}/${identifier}`,

    LIST: (pageSize: number, currentPage: number) => `${host}/${sections.looks}/?page_size=${pageSize}&current_page=${currentPage}`,

    SEARCH: (pageSize: number) => `${host}/${sections.looks}/search/all?page_size=${pageSize}`,

    GET_TOKEN: () => `${host}/${sections.looks}/generate-token`,

    LIST_FOR_USER: (pageSize: number, currentPage: number, identifier: string) =>
      `${host}/${sections.looks}/user/${identifier}?page_size=${pageSize}&current_page=${currentPage}`,
  },

  organisations: {
    CREATE: () => `${host}/${sections.organisations}`,

    LIST: (pageSize: number, currentPage: number) => `${host}/${sections.organisations}/?page_size=${pageSize}&current_page=${currentPage}`,

    DETAIL: (identifier: string) => `${host}/${sections.organisations}/${identifier}`,

    SEARCH: (pageSize: number) => `${host}/${sections.organisations}/search/all?page_size=${pageSize}`,

    REMOVE_LOOK_FROM_BRANCH: () => `${host}/organisations/branch/look/remove`,

    USER_BRANCH: () => `${host}/organisations/branch/user`,

    LOOK_BRANCH: () => `${host}/organisations/branch/look`,

    branches: {
      CREATE: (identifier: string) => `${host}/organisations/${identifier}/branch`,

      SEARCH_BY_NAME: (pageSize: number) => `${host}/${sections.organisations}/branch/search/all?page_size=${pageSize}`,

      BRANCH_DETAILS: (identifier: string) => `${host}/${sections.organisations}/branch/update/${identifier}`,
    },
  },

  transact: {
    UPDATE: (identifier: string) => `${host}/branch/${identifier}/transact/`,

    CREATE_NEW_BRANCH: (identifier: string) => `${host}/branch/${identifier}/transact/new-branch`,

    LIST_SESSIONS: (identifier: string) => `${host}/branch/${identifier}/transact/session`,

    CREATE_NEW_SESSION: (identifier: string) => `${host}/branch/${identifier}/transact/session`,

    REVOKE_SESSION: (identifier: brands.TransactSessionIdentifier) => `${host}/branch/${identifier}/transact/session`,

    TRANSACT_ACTIVATED_BRANCHES: (pageSize: number, currentPage: number) =>
      `${host}/${sections.transact}/branches?page_size=${pageSize}&current_page=${currentPage}`,
  },
};
