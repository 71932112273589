import * as React from 'react';
import classnames from 'classnames';
import { useMachine } from '@xstate/react/lib';

import machine, { Send, Events, CurrentState, States } from '../../../../../../../Machines/organisations/add_user_to_branch';
import { UserRecord } from '../../../../../../../Data/users/@records/User';
import { BranchRecord } from '../../../../../../../Data/organisations/@records/Branch';

type Props = {
  user: UserRecord;
  branch: BranchRecord;
};

const SubmitData = (send: Send) => (e: React.MouseEvent) => send(Events.SUBMIT_DATA);

const cssClasses = (current: CurrentState): string =>
  classnames([current.matches(States.ADD_USER_TO_BRANCH_SUCCESS) && ['bg-green-100'], ['cursor-pointer', 'hover:bg-gray-200', 'transition-250']]);

export default ({ user, branch }: Props) => {
  const [current, send] = useMachine(machine(branch, user), {
    devTools: true,
  });

  return (
    <div className={cssClasses(current)} onClick={SubmitData(send)}>
      {user.details.first_name} {user.details.last_name}
    </div>
  );
};
