import React, { useEffect } from 'react';

import machine, { Events } from '../../../../../Machines/organisations/update';

import Organisation from './Organisation';
import Branches from './Branches';
import { withRouter } from 'react-router';
import { useMachine } from '@xstate/react';
import AddBranch from './Branches/AddBranch';

export const Main = () => {
  const [current, send] = useMachine(machine);

  useEffect(() => {
    send(Events.REFRESH);
  }, [window.location.pathname]);

  return (
    <div className="flex-1 flex flex-col p-12">
      <h1 className="text-2xl my-5">Organisation</h1>
      <Organisation isDisabled={false} current={current} send={send} />
      <h1 className="text-2xl my-5">Branches</h1>
      <AddBranch isDisabled={false} current={current} send={send} />
      <Branches current={current} send={send} />
    </div>
  );
};

export default withRouter(Main);
