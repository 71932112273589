import Store from './store';
import { AxiosRequestConfig } from 'axios';

const authorisationHeaders = (): AxiosRequestConfig => ({
  headers: {
    'X-TOKEN': Store.getState().authentication.credentials.token,
  },
});

export default {
  authorisationHeaders,
};
