import * as t from 'io-ts';
import { ListResponse, DetailResponse } from '../generics';

export const LookResponse = t.exact(
  t.type({
    identifier: t.string,
    name: t.string,
    description: t.string,
    link: t.string,
  }),
);

export const TokenResponse = t.exact(
  t.type({
    identifier: t.string,
    name: t.string,
    description: t.union([t.string, t.null]),
    otp_token: t.string,
  }),
);

export const LooksSearchResponse = ListResponse(LookResponse);
export const ListLooksResponse = ListResponse(LookResponse);
export const DetailedLookResponse = DetailResponse(LookResponse);

export type TokenResponse = t.TypeOf<typeof TokenResponse>;
export type LookResponse = t.TypeOf<typeof LookResponse>;
export type LooksSearchResponse = t.TypeOf<typeof LooksSearchResponse>;
export type ListLooksResponse = t.TypeOf<typeof ListLooksResponse>;
export type DetailedLookResponse = t.TypeOf<typeof DetailedLookResponse>;
