import * as React from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import { State as StoreState } from '../../../../../../../Data/@records';
import { UserRecord } from '../../../../../../../Data/users/@records/User';
import { BranchRecord } from '../../../../../../../Data/organisations/@records/Branch';

import AddUser from './AddUser';

type Props = {
  branch: BranchRecord;
};

export default ({ branch }: Props) => {
  const users = useSelector<StoreState, List<UserRecord>>((state) =>
    state.users.users.toList().sort((u1, u2) => (u1.details.first_name < u2.details.first_name ? -1 : 1)),
  );

  return (
    <div className="mb-2">
      {users.map((user) => (
        <AddUser branch={branch} user={user} />
      ))}
    </div>
  );
};
