import { Record, RecordOf } from 'immutable';
import { UUID } from '../../../@types';
import { BranchId } from '../../organisations/@records/Branch';

type User = {
  date_created: string;
  identifier: string;
  email: string;
  details: {
    first_name: string;
    last_name: string | null;
  };
  branches: Array<{
    identifier: BranchId;
    name: string;
  }>;
};
const defaultValues: User = {
  date_created: '',
  identifier: '',
  email: '',
  details: {
    first_name: '',
    last_name: '',
  },
  branches: [],
};

export type UserRecord = RecordOf<User>;
export const UserRecord: Record.Factory<User> = Record(defaultValues);
