import * as React from 'react';
import { interpret } from 'xstate';

import Topbar from '../Topbar';
import Main from './Main';
import machine from '../../../../../Machines/users/listUsers';

const service = interpret(machine, { devTools: true });
const serviceStart = () => service.start();
const serviceStop = () =>
  function () {
    service.stop();
  };

export default () => {
  React.useState(serviceStart);
  React.useEffect(serviceStop, []);

  return (
    <div className="font-sans antialiased flex flex-grow application-offset">
      <div className="flex-1 flex flex-col">
        <Topbar />
        <Main service={service} />
      </div>
    </div>
  );
};
