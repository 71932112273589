import * as React from 'react';
import { Link } from 'react-router-dom';
import Data from '../../../../../../Data';
import { UserRecord } from '../../../../../../Data/users/@records/User';

type Props = {
  user: UserRecord;
};

export default ({ user }: Props) => (
  <Link
    to={Data.paths.admin.users.DETAIL(user.identifier)}
    className="py-1 pl-4 mb-2 border text-gray-600 rounded-full block cursor-pointer hover:border-liven-base transition-250"
  >
    {user.details.first_name} {user.details.last_name}
  </Link>
);
