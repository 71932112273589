import React from 'react';
import Topbar from './Topbar';
import Setting from './Setting';
import machine, { AutomataContext, AutomataEvent, AutomataService } from '../../../../Machines/settings/list';
import { interpret, State } from 'xstate';

type Props_ = {};

type State_ = {
  service: AutomataService;
  current: State<AutomataContext, AutomataEvent>;
};

export default class extends React.Component<Props_, State_> {
  state: State_ = {
    service: interpret(machine, { devTools: true }).onTransition((current) => this.setState({ current })),
    current: machine.initialState,
  };

  componentDidMount = () => this.state.service.start();

  componentWillUnmount = () => this.state.service.stop();

  render = () => (
    <div className="font-sans antialiased flex flex-grow application-offset border-grey border-l">
      <div className="flex-1 flex flex-col">
        <Topbar />
        <div className="px-6">
          {this.state.current.context.settings.map((setting) => (
            <Setting key={setting.identifier} label={setting.name} value={setting.value} />
          ))}
        </div>
      </div>
    </div>
  );
}
