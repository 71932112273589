import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { UpdateSettingResponse } from './response_models';

export type UpdateSettingsPayload = {
  value: string;
};

export const updateSetting = (identifier: string, data: UpdateSettingsPayload): Promise<AxiosResponse<UpdateSettingResponse>> =>
  axios.put(Endpoints.admin.settings.DETAILS(identifier), data, Data.async.authorisationHeaders());

export default async (identifier: string, data: UpdateSettingsPayload) =>
  await updateSetting(identifier, data).then(getDataFromAxios.get).then(tp.decode(UpdateSettingResponse));
