import React from 'react';

import machine, { States } from '../../../../Machines/authentication/reset_password';

import FormSubmit from './FormSubmit';
import FormError from './FormError';
import FormDetails from './FormDetails';
import FormSucess from './FormSucess';

import classNames from 'classnames';
import { useMachine } from '@xstate/react/lib';

import { LivenIcon } from '../../../../Static/liven/Icons/LivenIcon';

export default () => {
  const [current, send] = useMachine(machine);

  const wrapContainer = classNames('w-full md:h-screen overflow-y-auto bg-white font-liven-primary');

  const headingClass = classNames('mt-8 pt-1 font-extrabold text-2xl');

  const subHeadingClass = classNames('text-sm text-black-500 pt-4 pb-6');

  return (
    <div className={wrapContainer}>
      <div className="p-12 flex flex-col h-full">
        {/* Liven Logo */}
        <LivenIcon />

        <div className="max-w-xs mx-auto w-full">
          <h2 className={headingClass}>Forgot your password?</h2>
          <p className={subHeadingClass}>We’ll email you instructions on how to reset your password</p>

          {/* Form */}
          <>
            {current.matches(States.RESET_PASSWORD_VIEW) && <FormDetails current={current} send={send} />}
            {current.matches(States.RESET_PASSWORD_SUBMIT) && <FormSubmit current={current} send={send} />}
            {current.matches(States.RESET_PASSWORD_ERROR) && <FormError current={current} send={send} />}
            {current.matches(States.RESET_PASSWORD_SUCCESS) && <FormSucess current={current} send={send} />}
          </>
        </div>
      </div>
    </div>
  );
};
