import { Record, RecordOf } from 'immutable';
import { LookUUID } from '../@types';

type Look = {
  identifier: LookUUID;
  name: string;
  description: string;
  link: string;
};
const defaultValues: Look = {
  identifier: '',
  name: '',
  description: '',
  link: '',
};

export type LookRecord = RecordOf<Look>;
export const LookRecord: Record.Factory<Look> = Record(defaultValues);
