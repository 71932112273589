import { AxiosResponse } from 'axios';
import { Lens } from 'monocle-ts';
import { Machine, MachineConfig, Interpreter, assign, DoneInvokeEvent, State } from 'xstate';

import Data from '../../Data';
import Async from '../../Async';
import History from '../../history';
import { AutomataStates } from '../organisations/update';

// States
export enum States {
  RESET_PASSWORD_VIEW = 'RESET_PASSWORD_VIEW',
  RESET_PASSWORD_SUBMIT = 'RESET_PASSWORD_SUBMIT',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',

  TERMINATE_MACHINE_AND_EXIT = 'TERMINATE_MACHINE_AND_EXIT',
}

export type AutomataStateSchema = {
  states: {
    [States.RESET_PASSWORD_VIEW]: {};
    [States.RESET_PASSWORD_SUBMIT]: {};
    [States.RESET_PASSWORD_ERROR]: {};
    [States.RESET_PASSWORD_SUCCESS]: {};
    [States.TERMINATE_MACHINE_AND_EXIT]: {};
  };
};

// Events
export enum Events {
  SUBMIT_RESET_PASSWORD = 'SUBMIT_RESET_PASSWORD_DATA',
  ENTER_DATA = 'ENTER_DATA',
  TO_LOGIN_PAGE = 'TO_LOGIN_PAGE',
}

export type SubmitLoginData = {
  type: Events.SUBMIT_RESET_PASSWORD;
};

export type ToLoginView = {
  type: Events.TO_LOGIN_PAGE;
};

export type EnterData = {
  type: Events.ENTER_DATA;
  value: string;
};

export type AutomataEvent = SubmitLoginData | ToLoginView | EnterData;

// Context
export type AutomataContext = {
  email: string;
};

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

// Services
export const submitPasswordReset = (c: AutomataContext, e: SubmitLoginData) => Async.authentication.reset_password(c.email);

// Actions

export const enterEmailData = assign((c: AutomataContext, e: EnterData) => Lens.fromProp<AutomataContext>()('email').set(e.value)(c));

export const toLoginView = (c: AutomataContext, e: ToLoginView) => History.push(Data.paths.authentication.LOGIN);

// Config

export const config: MachineConfig<AutomataContext, AutomataStateSchema, AutomataEvent> = {
  id: 'Authentication:PasswordReset:Machine',
  initial: States.RESET_PASSWORD_VIEW,
  context: {
    email: '',
  },
  states: {
    [States.RESET_PASSWORD_VIEW]: {
      on: {
        ENTER_DATA: {
          actions: ['enterEmailData'],
        },
        SUBMIT_RESET_PASSWORD_DATA: {
          target: States.RESET_PASSWORD_SUBMIT,
        },
      },
    },
    [States.RESET_PASSWORD_SUBMIT]: {
      invoke: {
        src: 'submitPasswordReset',
        onDone: {
          target: States.RESET_PASSWORD_SUCCESS,
        },
        onError: {
          target: States.RESET_PASSWORD_ERROR,
        },
      },
    },
    [States.RESET_PASSWORD_ERROR]: {
      on: {
        ENTER_DATA: {
          actions: ['enterEmailData'],
        },
        SUBMIT_RESET_PASSWORD_DATA: {
          target: States.RESET_PASSWORD_SUBMIT,
        },
      },
    },
    [States.RESET_PASSWORD_SUCCESS]: {
      on: {
        [Events.TO_LOGIN_PAGE]: {
          target: States.TERMINATE_MACHINE_AND_EXIT,
        },
      },
      after: {
        5000: {
          target: States.TERMINATE_MACHINE_AND_EXIT,
        },
      },
    },
    [States.TERMINATE_MACHINE_AND_EXIT]: {
      type: 'final',
      entry: ['toLoginView'],
    },
  },
};

const options: any = {
  services: {
    submitPasswordReset,
  },
  actions: {
    enterEmailData,
    toLoginView,
  },
};

export default Machine<AutomataContext, AutomataStateSchema, AutomataEvent>(config, options);
