import * as React from 'react';
import { interpret, State } from 'xstate';
import machine from '../../../../../Machines/looks/search';

import Search from './Search';
import Looks from './Looks';
import { useMachine } from '@xstate/react/lib';

export default () => {
  const [current, send, service] = useMachine(machine);

  return (
    <div className="border-l border-r border-grey flex-none w-64 pb-6 hidden md:block application-offset">
      <div className="mb-2 mt-3 px-3">
        <Search current={current} service={service} />
      </div>
      <div className="text-gray-600 my-3 px-4 flex justify-center">
        <h1 className="font-semibold text-2xl truncate">Looks</h1>
      </div>

      <div className="mb-8">
        <hr />
        <div className="m-2">
          <Looks current={current} />
        </div>
      </div>
    </div>
  );
};
