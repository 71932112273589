import { Record, RecordOf } from 'immutable';
import { UUID } from '../../../@types';
import { UserUUID } from '../../users/@types';
import { BranchRecord } from './Branch';

export type OrganisationUUID = UUID;

type User = {
  identifier: UserUUID;
  first_name: string;
  last_name: null | string;
};

type Organisation = {
  identifier: OrganisationUUID;
  name: string;
  branches: Array<BranchRecord>;
  users: Array<User>;
};
const defaultValues: Organisation = {
  identifier: '',
  name: '',
  branches: [],
  users: [],
};

export type OrganisationRecord = RecordOf<Organisation>;
export const OrganisationRecord: Record.Factory<Organisation> = Record(defaultValues);
