import * as React from 'react';

import { interpret, State } from 'xstate';
import machine, { AutomataContext, AutomataEvent, AutomataService, Events, States } from '../../../../../../Machines/users/listBranches';
import { UUID } from 'io-ts-types/lib/UUID';

import Branch from './Branch';
import Modal from './Modal';

type Props_ = {
  userId: UUID;
};
type State_ = {
  service: AutomataService;
  current: State<AutomataContext, AutomataEvent>;
};

// TODO: make functional
export default class extends React.Component<Props_, State_> {
  state = {
    service: interpret(machine).onTransition((current) => this.setState({ current })),
    current: machine.initialState,
  };

  componentDidMount(): void {
    this.state.service.start().send({ type: Events.SET_USER_ID, data: this.props.userId });
  }

  componentWillUnmount(): void {
    this.state.service.stop();
  }

  render = () => (
    <div>
      <div className="w-5/6 mx-auto">
        <div className="shadow-md rounded my-6">
          <table className="text-left w-full border-collapse">
            <thead>
              <tr>
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light w-3/5">Branch</th>
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light w-2/5">Actions</th>
              </tr>
            </thead>

            <tbody>
              {this.state.current.context.data.record_list.map((branch) => (
                <Branch branch={branch} service={this.state.service} />
              ))}
            </tbody>

            {this.state.current.matches(States.SEARCH_BRANCH_SCREEN) && <Modal service={this.state.service} />}

            {this.state.current.matches(States.SEARCH_BRANCH_REQUEST) && <Modal service={this.state.service} />}

            {this.state.current.matches(States.SEARCH_BRANCH_ERROR) && <Modal service={this.state.service} />}

            <button disabled={false} onClick={() => this.state.service.send(Events.ADD_NEW_BRANCH)} className="btn mb-4 mt-4 ml-6" type="button">
              Add Branch
            </button>
          </table>
        </div>
      </div>
    </div>
  );
}
