import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';

export const Request = t.type({
  email: t.string,
  first_name: t.string,
  last_name: t.union([t.null, t.string]),
});

export const Response = t.type({
  identifier: t.string,
  email: t.string,
  details: t.type({
    first_name: t.string,
    last_name: t.union([t.null, t.string]),
  }),
});

export type CreateUserRequest = t.TypeOf<typeof Request>;
export type CreateUserResponse = t.TypeOf<typeof Response>;

export const createUser = (request: CreateUserRequest): Promise<AxiosResponse<CreateUserResponse>> =>
  axios.post(Endpoints.users.CREATE(), request, Data.async.authorisationHeaders());

export default async (request: CreateUserRequest) => await createUser(request).then(getDataFromAxios.get).then(tp.decode(Response));
