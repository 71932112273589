import axios from 'axios';
import { Machine, MachineConfig, Interpreter, State } from 'xstate';
import { removeItem } from 'fp-ts-local-storage';

import Data from '../../Data';
import Async from '../../Async';
import History from '../../history';

import constants from './constants';
import { AutomataStates } from '../organisations/update';

// States

export enum States {
  LOGOUT_START = 'LOGOUT_START',
  LOGOUT_COMPLETE = 'LOGOUT_COMPLETE',
}

export type AutomataStateSchema = {
  states: {
    [States.LOGOUT_START]: {};
    [States.LOGOUT_COMPLETE]: {};
  };
};

// Events

export enum Events {
  SUBMIT_LOGOUT = 'SUBMIT_LOGOUT',
}

export type SubmitLoginData = {
  type: Events.SUBMIT_LOGOUT;
};

export type AutomataEvent = SubmitLoginData;

// Context

export type AutomataContext = {};

// Types

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

//Actions

export const invalidateServerTokens = (c: AutomataContext, e: AutomataEvent) =>
  axios.post(Async.endpoints.authentication.LOGOUT, null, Data.async.authorisationHeaders());

export const removeTokenFromLocalStorage = (c: AutomataContext, e: AutomataEvent) => removeItem(constants.TOKEN_LABEL)();

export const redirectToLoginPage = (c: AutomataContext, e: AutomataEvent) => History.push(Data.paths.authentication.LOGIN);

export const config: MachineConfig<AutomataContext, AutomataStateSchema, AutomataEvent> = {
  id: 'Authentication:Logout:Machine',
  initial: States.LOGOUT_START,
  context: {},
  states: {
    [States.LOGOUT_START]: {
      entry: ['invalidateServerTokens', 'removeTokenFromLocalStorage'],
      on: {
        '': {
          target: States.LOGOUT_COMPLETE,
        },
      },
    },
    [States.LOGOUT_COMPLETE]: {
      entry: ['redirectToLoginPage'],
      type: 'final',
    },
  },
};

const options: any = {
  actions: {
    removeTokenFromLocalStorage,
    invalidateServerTokens,
    redirectToLoginPage,
  },
};

export default Machine<AutomataContext, AutomataStateSchema, AutomataEvent>(config, options);
