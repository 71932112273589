import Section from './Section';
import Data from '../../../../Data';
import React from 'react';

export default () => (
  <div className="flex-none w-24 p-4 hidden sm:block md:block flex justify-center">
    <Section label={'Orgs'} path={Data.paths.admin.organisations.BASE} />
    {/*<Section label={'Branch'} path={Data.paths.admin.branches.BASE}/>*/}
    <Section label={'Looks'} path={Data.paths.admin.looks.BASE} />
    <Section label={'Users'} path={Data.paths.admin.users.BASE} />
    <Section label={'Settings'} path={Data.paths.admin.SETTINGS} />
    <Section label={'Profile'} path={Data.paths.user.PROFILE} />
  </div>
);
