import * as React from 'react';
import { AutomataContext, AutomataEvent } from '../../../../../../Machines/looks/search';
import { State } from 'xstate';

import All from './All';
import Filtered from './Filtered';

type Props = {
  current: State<AutomataContext, AutomataEvent>;
};
export default ({ current }: Props) => (
  <div className="mb-2">
    {current.context.term === '' && <All current={current} />}
    {current.context.term !== '' && <Filtered current={current} />}
  </div>
);
