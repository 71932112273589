import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';
import { UUID } from 'io-ts-types/lib/UUID';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { UserRecord } from '../../Data/users/@records/User';
import { ListResponse } from '../generics';
import { BranchBasicResponse } from '../Organisations/response_models';

export const GetUserBranchesListResponse = ListResponse(BranchBasicResponse);

export type BranchBasicResponse = t.TypeOf<typeof BranchBasicResponse>;
export type GetUserBranchesListResponse = t.TypeOf<typeof GetUserBranchesListResponse>;

export const getUserBranches = (user: UserRecord): Promise<AxiosResponse<GetUserBranchesListResponse>> =>
  axios.get(Endpoints.users.BRANCHES(user), Data.async.authorisationHeaders());

export default async (user: UserRecord) => await getUserBranches(user).then(getDataFromAxios.get).then(tp.decode(GetUserBranchesListResponse));
