import React from 'react';
import Data from '../../../../../Data';
import { NavLink } from 'react-router-dom';
import { Props } from './';

export default (props: Props): JSX.Element => {
  const styles = 'border-b-4 border-gray-400 pr-32 uppercase pb-4 inline-block text-gray-500 tracking-widest font-liven-primary font-bold';
  const activeStyles = 'border-red-500 text-black-300';

  return (
    <div className="mb-4">
      <NavLink exact className={styles} activeClassName={activeStyles} to={Data.paths.merchant.transact.TO_VIEW_TEAM(props.identifier)}>
        Users
      </NavLink>

      <NavLink exact className={styles} activeClassName={activeStyles} to={Data.paths.merchant.transact.TO_ADD_NEW_TEAM_MEMBER(props.identifier)}>
        Add
      </NavLink>
    </div>
  );
};
