import React from 'react';
import { Link } from 'react-router-dom';

import Data from '../../../Data';

export default () => {
  return (
    <div className="text-right text-liven-base px-20 py-10">
      <Link to={Data.paths.authentication.LOGOUT} className={'cursor-pointer hidden md:block'}>
        Logout
      </Link>
    </div>
  );
};
