import { Record, RecordOf, Map } from 'immutable';
import { OrganisationRecord, OrganisationUUID } from './Organisation';

export type State_ = {
  organisations: Map<OrganisationUUID, OrganisationRecord>;
};

const defaultValues: State_ = {
  organisations: Map<OrganisationUUID, OrganisationRecord>(),
};

export type State = RecordOf<State_>;
export const State: Record.Factory<State_> = Record(defaultValues);
