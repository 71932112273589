import * as E from 'fp-ts/lib/Either';
import * as F from 'fp-ts/lib/function';
import * as A from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/pipeable';

import React from 'react';
import { useMachine } from '@xstate/react';

import { Props } from '../';
import * as automata from '../../../../../../Machines/transact/sessions';
import UserSession from './UserSession';
import { useParams } from 'react-router';

export default (props: Props) => {
  const { identifier } = useParams<{ identifier: string }>();
  const [current, send] = useMachine(automata.machine(identifier));

  const resultText = pipe(
    current,
    E.fromPredicate((c) => c.value === automata.States.UPDATE_TRANSACT_SUCCESS, F.identity),
    E.map((c) => c.context.data.result),
    E.map((r) => `${r.count} sessions active`),
    E.fold(F.constant('Loading...'), F.identity),
  );

  const Sessions = () =>
    pipe(
      current,
      E.fromPredicate((c) => c.value === automata.States.UPDATE_TRANSACT_SUCCESS, F.identity),
      E.map((c) => c.context.data.result.records),
      E.map(A.map((session) => <UserSession record={session} />)),
      E.map((s) => <>{s}</>),
      E.fold(F.constant(<></>), F.identity),
    );

  const Loading = () =>
    pipe(
      current.value,
      E.fromPredicate((v) => v === automata.States.UPDATE_TRANSACT_REQUEST, F.identity),
      E.mapLeft(F.constant(<></>)),
      E.map(F.constant(<>Loading</>)),
      E.fold(F.identity, F.identity),
    );

  return (
    <div>
      <div className="border-4 border-blue-300 min-h-14">
        <div>
          <Loading />
          <Sessions />
        </div>
      </div>

      <div className="flex px-8 text-gray-600 mt-6 text-lg">{resultText}</div>
    </div>
  );
};
