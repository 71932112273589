import * as React from 'react';

import { Events, CurrentState, Send } from '../../../../../../Machines/organisations/update';

export const updateNewBranchName = (send: Send) => (e: React.ChangeEvent<HTMLInputElement>) =>
  send({
    type: Events.ENTER_NEW_BRANCH_NAME,
    branchName: e.target.value,
  });

export const submitCreateNewBranch = (send: Send) => (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  send(Events.SUBMIT_CREATE_NEW_BRANCH);
};

type Props = {
  isDisabled: boolean;
  current: CurrentState;
  send: Send;
};

export default ({ isDisabled, current, send }: Props) => (
  <form className="flex justify-between items-center" onSubmit={submitCreateNewBranch(send)}>
    <div className="w-3/4 px-2">
      <input
        value={current.context.newBranchName}
        onChange={updateNewBranchName(send)}
        className="text-input"
        type="text"
        placeholder="Enter New Branch Name"
        disabled={isDisabled}
      />
    </div>

    <div className="w-1/4 px-2">
      <button disabled={isDisabled} className="btn" type="submit">
        Create
      </button>
    </div>
  </form>
);
