import Async from '../../Async';
import Data from '../../Data';
import { Machine, MachineConfig, Interpreter, DoneInvokeEvent } from 'xstate';
import { ListOrganisationResponse } from '../../Async/Organisations/list';

export enum States {
  LOAD_ORGANISATIONS_REQUEST = 'LOAD_ORGANISATIONS_REQUEST',
  LOAD_ORGANISATIONS_SUCCESS = 'LOAD_ORGANISATIONS_SUCCESS',
  LOAD_ORGANISATIONS_ERROR = 'LOAD_ORGANISATIONS_ERROR',
}

export enum Events {
  LOAD_ORGANISATION_LIST = 'LOAD_ORGANISATION_LIST',
}

export type AutomataStates = {
  states: {
    [States.LOAD_ORGANISATIONS_REQUEST]: {};
    [States.LOAD_ORGANISATIONS_SUCCESS]: {};
    [States.LOAD_ORGANISATIONS_ERROR]: {};
  };
};

export type LoadUserData = { type: Events.LOAD_ORGANISATION_LIST };
export type AutomataEvent = LoadUserData;

export type AutomataContext = {};

export const loadOrganisationList = (c: AutomataContext, e: AutomataEvent) => Async.organisations.list(200, 0);

export const sendOrganisationToStore = (c: AutomataContext, e: DoneInvokeEvent<ListOrganisationResponse>) =>
  Data.store.dispatch(Data.creators.organisation.saveOrganisationsToStore(e.data.record_list));

export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'Organisation:List:Machine',
  initial: States.LOAD_ORGANISATIONS_REQUEST,
  context: {},
  states: {
    [States.LOAD_ORGANISATIONS_REQUEST]: {
      invoke: {
        src: 'loadOrganisationList',
        onDone: {
          target: States.LOAD_ORGANISATIONS_SUCCESS,
          actions: [
            'sendOrganisationToStore',
            (c, e) => console.log({ c, e }),
            (c, e) => console.log({ c, e }),
            (c, e) => console.log({ c, e }),
            (c, e) => console.log({ c, e }),
            (c, e) => console.log({ c, e }),
          ],
        },
        onError: {
          target: States.LOAD_ORGANISATIONS_ERROR,
          actions: [],
        },
      },
    },
    [States.LOAD_ORGANISATIONS_SUCCESS]: {
      type: 'final',
    },
    [States.LOAD_ORGANISATIONS_ERROR]: {
      on: {
        [Events.LOAD_ORGANISATION_LIST]: {
          target: States.LOAD_ORGANISATIONS_REQUEST,
        },
      },
    },
  },
};

const options: any = {
  services: {
    loadOrganisationList,
  },
  actions: {
    sendOrganisationToStore,
  },
};

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export default Machine<AutomataContext, AutomataStates, AutomataEvent>(config, options);
