import * as React from 'react';
import { SimpleNavItemProps, SimpleNavItem } from './SimpleNavItem';

export type SimpleNavBarProps = {
  navItems: Array<SimpleNavItemProps>;
};

// probably needs a more informative name
export const SimpleNavBar = ({ navItems }: SimpleNavBarProps) => (
  <div className="flex flex-shrink-0 w-full">
    {navItems.map((item) => (
      <SimpleNavItem label={item.label} isActive={item.isActive} onClick={item.onClick} />
    ))}
  </div>
);

export default SimpleNavBar;
