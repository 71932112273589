import Dashboard from './Dashboard';
import Transact from './Transact';

export default (path: 'dashboard' | 'transact'): Function => {
  switch (path) {
    case 'dashboard':
      return Dashboard;

    case 'transact':
      return Transact;

    default:
      return Dashboard;
  }
};
