import React, { useEffect } from 'react';

import machine, { States } from '../../../../Machines/authentication/login';

import FormSubmit from './FormSubmit';
import FormError from './FormError';
import { LivenIcon } from '../../../../Static/liven/Icons/LivenIcon';
import FormDetails from './FormDetails';
import { useMachine } from '@xstate/react/lib';
import classNames from 'classnames';

export default () => {
  const [current, send, service] = useMachine(machine, { devTools: true });

  const wrapContainer = classNames('w-full md:h-screen overflow-y-auto bg-white font-liven-primary');

  const headingClass = classNames('mt-8 pt-1 font-extrabold text-2xl');

  const subHeadingClass = classNames('text-sm text-black-500 pt-4 pb-6');

  useEffect((): (() => void) => {
    // @ts-ignore
    window.Intercom('boot', {
      // @ts-ignore
      app_id: INTERCOM_APP_ID,
    });
    // @ts-ignore
    return () => window.Intercom('shutdown');
  }, [window.location.pathname.startsWith('/auth/login')]);

  return (
    <div className={wrapContainer}>
      <div className="p-12 flex flex-col h-full">
        {/* Liven Logo */}
        <LivenIcon />

        <div className="max-w-xs mx-auto w-full">
          <h2 className={headingClass}>Sign In</h2>
          <p className={subHeadingClass}>Sign In to continue to LivenPay</p>

          {/* Form */}
          <>
            {current.matches(States.LOGIN_VIEW) && <FormDetails service={service} />}
            {current.matches(States.LOGIN_SUBMIT) && <FormSubmit service={service} />}
            {current.matches(States.LOGIN_ERROR) && <FormError service={service} />}
          </>
        </div>
      </div>
    </div>
  );
};
