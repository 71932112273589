import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import * as brands from '../../brands';

import { getDataFromAxios } from '../common';
import { TransactSessionListResponse } from './response_models';

export const sessions = (identifier: brands.TransactSessionIdentifier): Promise<AxiosResponse<unknown>> =>
  axios.get(Endpoints.transact.REVOKE_SESSION(identifier), Data.async.authorisationHeaders());

export default async (identifier: brands.TransactSessionIdentifier) =>
  await sessions(identifier).then(getDataFromAxios.get).then(tp.decode(TransactSessionListResponse));
