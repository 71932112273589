import Data from '../../Data';
import Async from '../../Async';

import { Machine, Interpreter, MachineConfig, assign, DoneInvokeEvent, State } from 'xstate';

import { Either, isLeft, isRight, fold } from 'fp-ts/lib/Either';
import { Errors } from 'io-ts';
import { Lens } from 'monocle-ts';

import extractIdentifierFromPath, { Match } from '../utilities/extractIdentifierFromPath';
import { LookUUID } from '../../Data/looks/@types';
import { DetailedLookResponse } from '../../Async/Looks/response_models';

// States

export enum States {
  GET_LOOK_ID_FROM_LOCATION_PROPS = 'GET_LOOK_ID_FROM_LOCATION_PROPS',
  LOOK_ID_PROP_INVALID = 'LOOK_ID_PROP_INVALID',
  GET_LOOK_FROM_STORE = 'GET_LOOK_FROM_STORE',
  GET_LOOK_FROM_STORE_ERROR = 'GET_LOOK_FROM_STORE_ERROR',

  UPDATE_LOOK_SCREEN = 'UPDATE_LOOK_SCREEN',
  UPDATE_LOOK_REQUEST = 'UPDATE_LOOK_REQUEST',
  UPDATE_LOOK_ERROR = 'UPDATE_LOOK_ERROR',

  TERMINATE_AND_RETURN_TO_LOOK_HOMEPAGE = 'TERMINATE_AND_RETURN_TO_LOOK_HOMEPAGE',
}

export type AutomataStates = {
  states: {
    [States.GET_LOOK_ID_FROM_LOCATION_PROPS]: {};
    [States.LOOK_ID_PROP_INVALID]: {};
    [States.GET_LOOK_FROM_STORE]: {};
    [States.GET_LOOK_FROM_STORE_ERROR]: {};
    [States.UPDATE_LOOK_SCREEN]: {};

    [States.UPDATE_LOOK_REQUEST]: {};
    [States.UPDATE_LOOK_ERROR]: {};

    [States.TERMINATE_AND_RETURN_TO_LOOK_HOMEPAGE]: {};
  };
};

// Context

export type AutomataContext = {
  lookIdE: Either<Errors, Match>;
  lookId: LookUUID;
  data: {
    name: string;
    description: string;
    link: string;
  };
};

// Events

export enum Events {
  SUBMIT_DATA = 'SUBMIT_DATA',
  ENTER_DATA = 'ENTER_DATA',
  INPUT_FOCUSED = 'INPUT_FOCUSED',
  REFRESH = 'REFRESH',
}

export type SubmitData = {
  type: Events.SUBMIT_DATA;
};

export type EnterData = {
  type: Events.ENTER_DATA;
  value: string;
  field: keyof AutomataContext['data'];
};

export type InputFocused = {
  type: Events.INPUT_FOCUSED;
};
export type Refresh = {
  type: Events.REFRESH;
};

export type AutomataEvent = SubmitData | EnterData | InputFocused | Refresh;

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

// Services

export const getLook = (c: AutomataContext, _: AutomataEvent) => Async.looks.detail(c.lookId);

export const updateLook = (c: AutomataContext, e: AutomataEvent) => Async.looks.update(c.lookId, c.data);

// Actions

export const getlookIdE = assign((c: AutomataContext, e: AutomataEvent) => {
  const valueE = extractIdentifierFromPath(Data.paths.admin.looks.DETAIL);
  return Lens.fromProp<AutomataContext>()('lookIdE').set(valueE)(c);
});

export const assignlookIdFromE = assign((c: AutomataContext, e: AutomataEvent) => {
  const lookId = fold(
    (e: Errors): any => null,
    (a: Match) => a.params.identifier,
  )(c.lookIdE);
  return Lens.fromProp<AutomataContext>()('lookId').set(lookId)(c);
});

export const savelookDataToContext = assign((c: AutomataContext, e: DoneInvokeEvent<DetailedLookResponse>) => {
  const lens = Lens.fromPath<AutomataContext>();
  return lens(['data']).set(e.data.record)(c);
});

export const SavelookToStore = (c: AutomataContext, e: DoneInvokeEvent<DetailedLookResponse>) =>
  Data.store.dispatch(Data.creators.looks.saveLookToStore(e.data.record));

export const EnterData = assign((c: AutomataContext, e: EnterData) => Lens.fromPath<AutomataContext>()(['data', e.field]).set(e.value)(c));

// Guards

export const orgIdIsLeft = (c: AutomataContext, e: AutomataEvent): boolean => isLeft(c.lookIdE);

export const orgIdIsRight = (c: AutomataContext, e: AutomataEvent): boolean => isRight(c.lookIdE);

// Config

export const config: MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'look:Detail:Update:Machine',
  initial: States.GET_LOOK_ID_FROM_LOCATION_PROPS,
  context: {
    lookIdE: null as any,
    lookId: '',
    data: {
      name: '',
      description: '',
      link: '',
    },
  },
  states: {
    [States.GET_LOOK_ID_FROM_LOCATION_PROPS]: {
      entry: ['getlookIdE'],
      on: {
        '': [
          {
            cond: 'orgIdIsLeft',
            target: States.LOOK_ID_PROP_INVALID,
          },
          {
            cond: 'orgIdIsRight',
            target: States.GET_LOOK_FROM_STORE,
            actions: ['assignlookIdFromE'],
          },
        ],
      },
    },
    [States.LOOK_ID_PROP_INVALID]: {
      after: {
        2000: {
          target: States.TERMINATE_AND_RETURN_TO_LOOK_HOMEPAGE,
        },
      },
    },
    [States.GET_LOOK_FROM_STORE]: {
      invoke: {
        src: 'getLook',
        onDone: {
          target: States.UPDATE_LOOK_SCREEN,
          actions: ['savelookDataToContext', 'SavelookToStore'],
        },
        onError: {
          target: States.GET_LOOK_FROM_STORE_ERROR,
        },
      },
    },
    [States.GET_LOOK_FROM_STORE_ERROR]: {},
    [States.UPDATE_LOOK_SCREEN]: {
      on: {
        [Events.ENTER_DATA]: {
          actions: ['EnterData'],
        },
        [Events.SUBMIT_DATA]: {
          target: States.UPDATE_LOOK_REQUEST,
        },
      },
    },
    [States.UPDATE_LOOK_REQUEST]: {
      invoke: {
        src: 'updateLook',
        onDone: {
          target: States.UPDATE_LOOK_SCREEN,
          actions: ['SavelookToStore'],
        },
        onError: {
          target: States.UPDATE_LOOK_ERROR,
        },
      },
    },

    [States.UPDATE_LOOK_ERROR]: {
      on: {
        [Events.INPUT_FOCUSED]: {
          target: States.UPDATE_LOOK_SCREEN,
        },
        [Events.SUBMIT_DATA]: {
          target: States.UPDATE_LOOK_REQUEST,
        },
      },
    },

    [States.TERMINATE_AND_RETURN_TO_LOOK_HOMEPAGE]: {
      type: 'final',
      entry: ['redirectToUserHomePage'],
    },
  },
  on: {
    [Events.REFRESH]: {
      target: States.GET_LOOK_ID_FROM_LOCATION_PROPS,
    },
  },
};

// Options
export const options: any = {
  services: {
    getLook,
    updateLook,
  },
  actions: {
    EnterData,
    getlookIdE,
    assignlookIdFromE,
    savelookDataToContext,
    SavelookToStore,
  },
  guards: {
    orgIdIsLeft,
    orgIdIsRight,
  },
};

export default Machine(config, options);
