import React from 'react';
import { useMachine } from '@xstate/react/lib';
import { Switch, Route } from 'react-router';

import machine from '../../../../Machines/organisations/list';

import Data from '../../../../Data';
import Sidebar from './Sidebar';
import List from './List';
import Create from './Create';
import Detail from './Detail';
import Topbar from './Topbar';

export default () => (
  <div className="font-sans antialiased flex flex-grow">
    <Sidebar />
    <div className="flex-1 flex flex-col">
      <Topbar />
      <Switch>
        <Route exact path={Data.paths.admin.organisations.BASE}>
          <List />
        </Route>

        <Route exact path={Data.paths.admin.organisations.CREATE}>
          <Create />
        </Route>

        <Route exact path={`${Data.paths.admin.organisations.DETAIL}`}>
          <Detail />
        </Route>
      </Switch>
    </div>
  </div>
);
