import actions from './actions';
import { UserDetails } from '../../Async/Users/response_models';
import { AnyAction } from 'redux';
import { CreateUserResponse } from '../../Async/Users/create';

export type SaveUsersToStore = AnyAction & { users: Array<UserDetails> };
export const saveUsersToStore = (users: Array<UserDetails>): SaveUsersToStore => ({
  type: actions.SAVE_USER_DATA_TO_STORE,
  users: users,
});

export type AddUserToStore = AnyAction & { user: CreateUserResponse };
export const addUserToStore = (user: CreateUserResponse): AddUserToStore => ({
  type: actions.ADD_USER_DATA_TO_STORE,
  user: user,
});

export default {
  saveUsersToStore,
  addUserToStore,
};
