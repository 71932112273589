import * as React from 'react';
import Topbar from '../Topbar';
import Main from './Main';

import { interpret } from 'xstate';

import createUser, { AutomataService } from '../../../../../Machines/users/createUser';

const service: AutomataService = interpret(createUser, { devTools: true });
const serviceStart = () => service.start();
const serviceStop = () =>
  function () {
    service.stop();
  };

export default () => {
  React.useState(serviceStart);
  React.useEffect(serviceStop, []);

  return (
    <div className="flex-1 flex flex-col">
      <Topbar />
      <Main service={service} />
    </div>
  );
};
