import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { UserRecord } from '../../Data/users/@records/User';
import { UserDetailResponse } from './response_models';

export const Request = t.exact(
  t.type({
    email: t.string,
    details: t.exact(
      t.type({
        first_name: t.string,
        last_name: t.union([t.null, t.string]),
      }),
    ),
    password: t.exact(
      t.type({
        password1: t.union([t.null, t.string]),
        password2: t.union([t.null, t.string]),
      }),
    ),
    is_admin: t.union([t.null, t.boolean]),
  }),
);

export type UpdateUserRequest = t.TypeOf<typeof Request>;

export const updateUser = (user: UserRecord, data: UpdateUserRequest): Promise<AxiosResponse<UserDetailResponse>> =>
  axios.put(Endpoints.users.UPDATE(user), data, Data.async.authorisationHeaders());

export default async (user: UserRecord, data: UpdateUserRequest) =>
  await updateUser(user, data).then(getDataFromAxios.get).then(tp.decode(UserDetailResponse));
