import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Data from '../../../Data';

import Login from './Login';
import Logout from './Logout';
import ResetPassword from './PasswordReset';

export default () => (
  <Switch>
    <Route exact path={Data.paths.authentication.LOGOUT}>
      <Logout />
    </Route>

    <Route exact path={Data.paths.authentication.LOGIN}>
      <Login />
    </Route>

    <Route exact path={Data.paths.authentication.RESET_PASSWORD}>
      <ResetPassword />
    </Route>

    <Route exact path={Data.paths.authentication.BASE}>
      <Redirect to={Data.paths.authentication.LOGIN} />
    </Route>
  </Switch>
);
