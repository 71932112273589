import * as React from 'react';
import { State } from 'xstate';
import { AutomataContext, AutomataEvent } from '../../../../../../Machines/users/search';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import { State as StoreState } from '../../../../../../Data/@records';
import { UserRecord } from '../../../../../../Data/users/@records/User';
import User from './User';

type Props = {
  current: State<AutomataContext, AutomataEvent>;
};
export default ({ current }: Props) => {
  const users = useSelector<StoreState, List<UserRecord>>((state) =>
    state.users.users.toList().sort((u1, u2) => (u1.details.first_name < u2.details.first_name ? -1 : 1)),
  );

  return (
    <div className="mb-2">
      {users.map((u) => (
        <User user={u} />
      ))}
    </div>
  );
};
