import React from 'react';
import * as automata from '../../../../../../Machines/transact/revoke_session';
import { TransactUser } from '../../../../../../Async/Transact/response_models';
import { useMachine } from '@xstate/react';

type Props = {
  record: TransactUser;
};

const revokeSession = (send: automata.Send) => () => send({ type: automata.Events.REVOKE_SESSION });

export default (props: Props) => {
  const [current, send] = useMachine(automata.machine(props.record.id));

  return (
    <div className="py-8 px-4 text-gray-600 border-b border-gray-400 flex justify-between">
      <div>{props.record.identifier}</div>
      <div onClick={revokeSession(send)}>REMOVE</div>
      {current.value === automata.States.REVOKE_TRANSACT_SESSION_ERROR}
    </div>
  );
};
