import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { DetailOrganisationResponse } from './response_models';

export const UpdateOrganisationRequest = t.type({
  identifier: t.string,
  name: t.string,
});

export type UpdateOrganisationRequest = t.TypeOf<typeof UpdateOrganisationRequest>;

export const updateOrganisationDetails = (request: UpdateOrganisationRequest): Promise<AxiosResponse<DetailOrganisationResponse>> =>
  axios.put(Endpoints.organisations.DETAIL(request.identifier), request, Data.async.authorisationHeaders());

export default async (request: UpdateOrganisationRequest) =>
  await updateOrganisationDetails(request).then(getDataFromAxios.get).then(tp.decode(DetailOrganisationResponse));
