import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { Transact } from '../Organisations/response_models';

export const updateLook = (identifier: string, request: Transact): Promise<AxiosResponse<Transact>> =>
  axios.put(Endpoints.transact.UPDATE(identifier), request, Data.async.authorisationHeaders());

export default async (identifier: string, request: Transact) =>
  await updateLook(identifier, request).then(getDataFromAxios.get).then(tp.decode(Transact));
