import * as React from 'react';
import Data from '../../../../Data';
import { Link } from 'react-router-dom';
import { AutomataService } from '../../../../Machines/users/listUsers';

type Props = {
  service: AutomataService;
};
export default () => (
  <div className="flex items-center justify-center text-gray-600 hover:text-liven-base py-5 px-8 border-gray-300 transition-250 border-b mb-4">
    Settings
  </div>
);
