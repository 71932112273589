import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as R from 'fp-ts/lib/Record';

import { Machine, Interpreter, MachineConfig, State, DoneInvokeEvent } from 'xstate';
import Async from '../../Async';
import Data from '../../Data';

import { BranchRecord } from '../../Data/organisations/@records/Branch';
import { LookRecord } from '../../Data/looks/@records/Look';
import { DetailOrganisationResponse } from '../../Async/Organisations/response_models';

// States

export enum States {
  ADD_LOOK_TO_BRANCH_SCREEN = 'ADD_LOOK_TO_BRANCH_SCREEN',
  ADD_LOOK_TO_BRANCH_REQUEST = 'ADD_LOOK_TO_BRANCH_REQUEST',
  ADD_LOOK_TO_BRANCH_SUCCESS = 'ADD_LOOK_TO_BRANCH_SUCCESS',
  ADD_LOOK_TO_BRANCH_ERROR = 'ADD_LOOK_TO_BRANCH_ERROR',
}

export type AutomataStates = {
  states: {
    [States.ADD_LOOK_TO_BRANCH_SCREEN]: {};
    [States.ADD_LOOK_TO_BRANCH_REQUEST]: {};
    [States.ADD_LOOK_TO_BRANCH_SUCCESS]: {};
    [States.ADD_LOOK_TO_BRANCH_ERROR]: {};
  };
};

// Context

export type AutomataContext = {
  branch: BranchRecord;
  look: LookRecord;
};

// Events

export enum Events {
  SUBMIT_DATA = 'SUBMIT_DATA',
}

export type SubmitData = {
  type: Events.SUBMIT_DATA;
};

export type AutomataEvent = SubmitData;

export type AutomataService = Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = State<AutomataContext, AutomataEvent>;
export type Send = AutomataService['send'];

// Services
export const add_look_to_branch = (c: AutomataContext, e: AutomataEvent) =>
  // @ts-ignore
  Async.organisations.add_look_to_branch({
    lookId: c.look.identifier,
    branchId: c.branch.identifier,
  });

// Actions

export const AddOrganisationToStore = (c: AutomataContext, e: DoneInvokeEvent<DetailOrganisationResponse>) =>
  Data.store.dispatch(Data.creators.organisation.saveOrganisationToStore(e.data.record));

// Config

export const config = (branch: BranchRecord, look: LookRecord): MachineConfig<AutomataContext, AutomataStates, AutomataEvent> => ({
  initial: States.ADD_LOOK_TO_BRANCH_SCREEN,
  context: {
    branch: branch,
    look: look,
  },
  states: {
    [States.ADD_LOOK_TO_BRANCH_SCREEN]: {
      on: {
        [Events.SUBMIT_DATA]: {
          target: States.ADD_LOOK_TO_BRANCH_REQUEST,
        },
      },
    },
    [States.ADD_LOOK_TO_BRANCH_REQUEST]: {
      invoke: {
        src: 'add_look_to_branch',
        onDone: {
          target: States.ADD_LOOK_TO_BRANCH_SUCCESS,
          actions: ['AddOrganisationToStore'],
        },
        onError: {
          target: States.ADD_LOOK_TO_BRANCH_ERROR,
          actions: [],
        },
      },
    },
    [States.ADD_LOOK_TO_BRANCH_SUCCESS]: {
      on: {
        [Events.SUBMIT_DATA]: {
          target: States.ADD_LOOK_TO_BRANCH_REQUEST,
        },
      },
    },
    [States.ADD_LOOK_TO_BRANCH_ERROR]: {
      on: {
        [Events.SUBMIT_DATA]: {
          target: States.ADD_LOOK_TO_BRANCH_REQUEST,
        },
      },
    },
  },
});

export const options: any = {
  services: {
    add_look_to_branch,
  },
  actions: {
    AddOrganisationToStore,
  },
};

export default (branch: BranchRecord, look: LookRecord) => Machine(config(branch, look), options);
