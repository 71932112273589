import React, { useEffect } from 'react';

import Form from './Form';
import Loading from './Loading';

import machine, { States, Events } from '../../../../../Machines/looks/update';
import { withRouter } from 'react-router';
import { useMachine } from '@xstate/react';

export const Main = () => {
  const [current, send] = useMachine(machine);

  useEffect(() => {
    send(Events.REFRESH);
  }, [window.location.pathname]);

  return (
    <div className="flex-1 flex flex-col">
      {current.matches(States.GET_LOOK_ID_FROM_LOCATION_PROPS) && (
        <div>
          <Loading />
        </div>
      )}

      {current.matches(States.GET_LOOK_FROM_STORE) && (
        <div>
          <Loading />
        </div>
      )}

      {current.matches(States.UPDATE_LOOK_SCREEN) && (
        <div className="mx-3">
          <Form isDisabled={false} current={current} send={send} />
        </div>
      )}

      {current.matches(States.UPDATE_LOOK_REQUEST) && (
        <div>
          <Form isDisabled={true} current={current} send={send} />
        </div>
      )}

      {current.matches(States.UPDATE_LOOK_ERROR) && (
        <div>
          <Form isDisabled={false} current={current} send={send} />
        </div>
      )}
    </div>
  );
};

export default withRouter(Main);
