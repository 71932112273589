import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { DetailOrganisationResponse } from './response_models';

export const RemoveUserFromBranchRequest = t.exact(
  t.type({
    branchId: t.string,
    userId: t.string,
  }),
);

export type RemoveUserFromBranchRequest = t.TypeOf<typeof RemoveUserFromBranchRequest>;

export const addUserToBranch = (request: RemoveUserFromBranchRequest): Promise<AxiosResponse<DetailOrganisationResponse>> =>
  axios.put(Endpoints.organisations.USER_BRANCH(), request, Data.async.authorisationHeaders());

export default async (request: RemoveUserFromBranchRequest) =>
  await addUserToBranch(request).then(getDataFromAxios.get).then(tp.decode(DetailOrganisationResponse));
