import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';

import { getDataFromAxios } from '../common';
import { LoginResponse } from './response_models';

export const login = (email: string, password: string): Promise<AxiosResponse<LoginResponse>> =>
  axios.post(Endpoints.authentication.LOGIN(), { email, password });

export default async (email: string, password: string) => await login(email, password).then(getDataFromAxios.get).then(tp.decode(LoginResponse));
