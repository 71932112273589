import * as React from 'react';

import { Events, CurrentState, Send } from '../../../../../../Machines/organisations/create';

export const assignNewBranch = (send: Send, name: string) =>
  send({
    type: Events.ADD_BRANCH,
    name: name,
  });

type Props = {
  isDisabled: boolean;
  current: CurrentState;
  send: Send;
};

type BranchProps = {
  name: string;
  index: number;
  send: Send;
};

export const Branch = ({ name, index, send }: BranchProps) => (
  <div>
    {name}
    <button className="btn-inline" onClick={(e) => send({ type: Events.REMOVE_BRANCH, index: index })}>
      REMOVE
    </button>
  </div>
);

export default ({ isDisabled, current, send }: Props) => {
  const [name, updateName] = React.useState('');
  const submit = (_: React.MouseEvent<HTMLButtonElement>) => {
    assignNewBranch(send, name);
    updateName('');
  };

  const keyPressed = (send: Send, name: string) => (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && [updateName(''), assignNewBranch(send, name)];

  return (
    <div>
      {current.context.formData.branches.map((name, index) => (
        <Branch name={name} index={index} send={send} />
      ))}

      <hr />
      <input
        value={name}
        onChange={(e) => updateName(e.target.value)}
        className="text-input"
        type="text"
        placeholder="Enter Branch name"
        disabled={isDisabled}
        onKeyPress={keyPressed(send, name)}
      />

      <hr />

      <button disabled={isDisabled} onClick={submit} className="btn" type="button">
        Create Branch
      </button>
    </div>
  );
};
