import * as React from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import { State as StoreState } from '../../../../../../../Data/@records';
import { LookRecord } from '../../../../../../../Data/looks/@records/Look';
import { BranchRecord } from '../../../../../../../Data/organisations/@records/Branch';

import AddLook from './AddLook';

type Props = {
  branch: BranchRecord;
};

export default ({ branch }: Props) => {
  const looks = useSelector<StoreState, List<LookRecord>>((state) => state.looks.looks.toList().sort((u1, u2) => (u1.name < u2.name ? -1 : 1)));

  return (
    <div className="mb-2">
      {looks.map((look) => (
        <AddLook branch={branch} look={look} />
      ))}
    </div>
  );
};
