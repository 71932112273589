import * as React from 'react';
import Data from '../../../../../../Data';
import { Link } from 'react-router-dom';
import { LookRecord } from '../../../../../../Data/looks/@records/Look';

type Props = {
  record: LookRecord;
};

export default ({ record }: Props) => (
  <Link
    to={Data.paths.admin.looks.TO_DETAIL(record.identifier)}
    className="py-1 pl-4 mb-2 border text-gray-600 rounded-full block cursor-pointer hover:border-liven-base transition-250"
  >
    {record.name}
  </Link>
);
