import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { LooksSearchResponse } from './response_models';

export const searchLooks = (term: string): Promise<AxiosResponse<LooksSearchResponse>> =>
  axios.post(Endpoints.looks.SEARCH(20), { term }, Data.async.authorisationHeaders());

export default async (term: string) => await searchLooks(term).then(getDataFromAxios.get).then(tp.decode(LooksSearchResponse));
