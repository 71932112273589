import React from 'react';
import { Switch, Route, useParams, Link } from 'react-router-dom';
import { useMachine } from '@xstate/react';

import machine from '../../../../../Machines/organisations/list';

import paths from '../../../../../Data/paths';

import Options from './Options';
import Team from './Team';
import AddTeamMember from './AddTeamMember';

export type Props = {
  identifier: string;
};
export default (): JSX.Element => {
  const [current, send, service] = useMachine(machine, { devTools: true });
  const { identifier } = useParams<{ identifier: string }>();

  return (
    <div className="">
      <div className={'md:mb-10 md:pb-4'}>
        <Link to={paths.merchant.transact.TO_VIEW_TEAM(identifier)} className={'text-2xl font-bold mb-8 md:mb-0 md:mt-14'}>
          Team Settings
        </Link>
      </div>

      <Options identifier={identifier} />

      <Switch>
        <Route exact path={paths.merchant.transact.VIEW_TEAM}>
          <Team identifier={identifier} />
        </Route>

        <Route exact path={paths.merchant.transact.ADD_NEW_TEAM_MEMBER}>
          <AddTeamMember identifier={identifier} />
        </Route>
      </Switch>
    </div>
  );
};
