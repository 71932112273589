import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { ListLooksResponse } from './response_models';

export const list = (pageSize: number, currentPage: number): Promise<AxiosResponse<ListLooksResponse>> =>
  axios.get(Endpoints.looks.LIST(pageSize, currentPage), Data.async.authorisationHeaders());

export default async (pageSize: number, currentPage: number) =>
  await list(pageSize, currentPage).then(getDataFromAxios.get).then(tp.decode(ListLooksResponse));
