import list from './list';
import create from './create';
import update from './update';
import details from './details';
import profile from './profile';
import search from './search';
import list_branches from './list_branches';
import assign_branch from './assign_branch';
import remove_branch from './remove_branch';

export default {
  list,
  create,
  update,
  details,
  profile,
  search,
  list_branches,
  assign_branch,
  remove_branch,
};
