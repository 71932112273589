import * as t from 'io-ts';
import * as tp from 'io-ts-promise';
import axios, { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { DetailOrganisationResponse } from './response_models';

export const createBranchRequest = t.type({
  orgIdentifier: t.string,
  branchName: t.string,
});

export type CreateBranchRequest = t.TypeOf<typeof createBranchRequest>;

export const createBranch = (request: CreateBranchRequest): Promise<AxiosResponse<DetailOrganisationResponse>> =>
  axios.post(
    Endpoints.organisations.branches.CREATE(request.orgIdentifier),
    {
      name: request.branchName,
    },
    Data.async.authorisationHeaders(),
  );

export default async (request: CreateBranchRequest) =>
  await createBranch(request).then(getDataFromAxios.get).then(tp.decode(DetailOrganisationResponse));
