import * as React from 'react';
import { AutomataContext, AutomataEvent, AutomataService, States } from '../../../../../../Machines/looks/search';
import { State } from 'xstate';

import InputError from './Error';
import Input from './Input';
import Loading from './Loading';

type Props = {
  service: AutomataService;
  current: State<AutomataContext, AutomataEvent>;
};

export default ({ service, current }: Props) => (
  <div className="flex justify-center">
    {current.matches(States.SEARCH_LOOKS_SCREEN) && <Input service={service} current={current} />}

    {current.matches(States.SEARCH_LOOKS_REQUEST) && <Loading service={service} current={current} />}

    {current.matches(States.SEARCH_LOOKS_ERROR) && <InputError service={service} current={current} />}
  </div>
);
