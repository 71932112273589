import * as tp from 'io-ts-promise';
import axios from 'axios';
import { AxiosResponse } from 'axios';

import Data from '../../Data';
import Endpoints from '../endpoints';
import { getDataFromAxios } from '../common';
import { CreateNewSessionRequest } from './request_models';
import { CreateNewSessionRespone } from './response_models';

export const create = (identifier: string, request: CreateNewSessionRequest): Promise<AxiosResponse<unknown>> =>
  axios.post(Endpoints.transact.CREATE_NEW_SESSION(identifier), request, Data.async.authorisationHeaders());

export default async (identifier: string, request: CreateNewSessionRequest) =>
  await create(identifier, request).then(getDataFromAxios.get).then(tp.decode(CreateNewSessionRespone));
