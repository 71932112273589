import * as x from 'xstate';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { Lens } from 'monocle-ts';
import Async from '../../Async';
import { TransactActivatedBranchResponse } from '../../Async/Transact/response_models';

// States

export enum States {
  GET_TRANSACT_BRANCHES_REQUEST = 'GET_TRANSACT_BRANCHES_REQUEST',
  GET_TRANSACT_BRANCHES_SUCCESS = 'GET_TRANSACT_BRANCHES_SUCCESS',
  GET_TRANSACT_BRANCHES_ERROR = 'GET_TRANSACT_BRANCHES_ERROR',
}

export type AutomataStates = {
  states: {
    [States.GET_TRANSACT_BRANCHES_REQUEST]: {};
    [States.GET_TRANSACT_BRANCHES_SUCCESS]: {};
    [States.GET_TRANSACT_BRANCHES_ERROR]: {};
  };
};

// Context

export type AutomataContext = {
  data: {
    result: O.Option<TransactActivatedBranchResponse>;
  };
};

// Events

export enum Events {
  LOAD_USERS = 'LOAD_BRANCHES',
}

export type LoadSessions = {
  type: Events.LOAD_USERS;
};

export type AutomataEvent = LoadSessions;

// Services

export const getActivatedBranches = (c: AutomataContext, e: AutomataEvent) => Async.transact.transact_activated_branches(10, 0);

// Actions

export const assignDataToContext = x.assign((c: AutomataContext, e: x.DoneInvokeEvent<TransactActivatedBranchResponse>) =>
  pipe(c, Lens.fromPath<AutomataContext>()(['data', 'result']).set(O.some(e.data))),
);

// Config

export const config: x.MachineConfig<AutomataContext, AutomataStates, AutomataEvent> = {
  id: 'Machine:Transact:GetBranches',
  initial: States.GET_TRANSACT_BRANCHES_REQUEST,
  context: {
    data: {
      result: O.none,
    },
  },
  states: {
    [States.GET_TRANSACT_BRANCHES_REQUEST]: {
      invoke: {
        src: 'getActivatedBranches',
        onDone: {
          target: States.GET_TRANSACT_BRANCHES_SUCCESS,
          actions: ['assignDataToContext'],
        },
        onError: {
          target: States.GET_TRANSACT_BRANCHES_ERROR,
        },
      },
    },
    [States.GET_TRANSACT_BRANCHES_SUCCESS]: {},
    [States.GET_TRANSACT_BRANCHES_ERROR]: {},
  },
};

export const options: any = {
  services: {
    getActivatedBranches,
  },
  actions: {
    assignDataToContext,
  },
};

export type Service = x.Interpreter<AutomataContext, AutomataStates, AutomataEvent>;
export type CurrentState = x.State<AutomataContext, AutomataEvent>;
export type Machine = x.MachineConfig<AutomataContext, AutomataStates, AutomataEvent>;
export type Send = Service['send'];
export const machine = x.Machine(config, options);

export default machine;
