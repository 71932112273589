import React from 'react';
import { Switch, Route } from 'react-router';

import Data from '../../../../Data';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import Create from './Create';
import Detail from './Detail';

export default () => (
  <div className="font-sans antialiased flex flex-grow">
    <Sidebar />
    <div className="flex-1 flex flex-col">
      <Topbar />
      <Switch>
        <Route exact path={Data.paths.admin.looks.CREATE}>
          <Create />
        </Route>

        <Route exact path={Data.paths.admin.looks.DETAIL}>
          <Detail />
        </Route>

        <Route exact path={Data.paths.admin.looks.BASE}>
          <h1>LOOKS BASE</h1>
        </Route>
      </Switch>
    </div>
  </div>
);
